export interface IEnergySeries {
    calculatedPowerInW: number;
    confidenceLevel: number;
    intervalLengthInSeconds: number;

    maximumPowerInW: number;
    measuredEnergyInWh: number;
    measuredPowerInW: number;

    savingsbyAutomaticTuningInW?: number;
    savingsbyDaylightHarvestingInW?: number;
    savingsbyLoadSheddingInW?: number;
    savingsbyManualControlInW?: number;
    savingsbyOccupancyControlInW?: number;
    savingsbyTimeScheduleInW?: number;

    startTime: string;
    totalSavingsInW: number;
}

export interface IEnergySummary {
    averageCalculatedPowerInW: number;
    averageMaximumPowerInW: number;
    averageMeasuredEnergyInWh: number;
    averageMeasuredPowerInW: number;

    averageSavingsbyAutomaticTuningInW?: number;
    averageSavingsbyDaylightHarvestingInW?: number;
    averageSavingsbyLoadSheddingInW?: number;
    averageSavingsbyManualControlInW?: number;
    averageSavingsbyOccupancyControlInW?: number;
    averageSavingsbyTimeScheduleInW?: number;
    averageTotalSavingsInW?: number;

    confidenceLevel: number;
    totalSeconds: number;
}

export interface IEnergyData {
    seriesData: IEnergySeries[];
    summary: IEnergySummary;
}

export interface IOccupancySeries {
    startTime: string;
    confidenceLevel: number;
    intervalLengthInSeconds: number;
    percentOccupied: number;
}
export interface IOccupancyData {
    seriesData: IOccupancySeries[];
}

export abstract class IDataHistoryApi {
    abstract getEnergyData(orgId: string, systemId: string, nodeId: string, from: Date, to: Date, maxPoints?: number): Promise<IEnergyData>;
    abstract getOccupancyData(orgId: string, systemId: string, nodeId: string, from: Date, to: Date, maxPoints?: number): Promise<IOccupancyData>;
}

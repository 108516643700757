import { IBaseNode, IBimBuilding, IBimFloor, IBimObject, IRelation } from "@/contracts";
import { Dictionary } from "lodash";
import { BimObject } from "./BimObject";

export default class BimBuilding extends BimObject implements IBimBuilding {

    floors: IBimFloor[] = [];
    address?: string;

    constructor(node: IBaseNode & any) {
        super(node);
        this.address = node.siteAddress;
    }

    init(outRelations: Dictionary<IRelation[]>, inRelations: Dictionary<IRelation[]>, nodes: Map<string, IBimObject>) {
        this.floors = this.findRelatedNodes(outRelations[this.id], nodes, "Floor");
        this.floors.sort((a, b) => a.floorLevel - b.floorLevel);
    }
}

import { FieldValidator } from "./EditableField";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const required: FieldValidator<string> = (value) => value && value.length > 0 ? undefined : "Required";

export const email: FieldValidator<string> = (value) => {
    if (!value || !value.match(emailRegex)) { return "Required"; }
    return undefined;
};








import React from "react";
import { observer } from "mobx-react";
import { container } from "@/ioc";
import { IAuthenticationService } from "@/contracts";
import { Toolbar, Typography, AppBar } from "@mui/material";

@observer
export class LogoutPage extends React.Component {
    private readonly authService = container.get(IAuthenticationService);

    async componentDidMount() {
        await this.authService.logout();
        location.replace(FM_PUBLIC_PATH);
    }

    render() {

        return (
            <div>
                <AppBar>
                    <Toolbar variant="dense">
                        <Typography variant="h6">
                            Logging out...
                            </Typography>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

import { IPoint, IBimPartitionWall } from "@/contracts";
import { BimObject } from "./BimObject";
import { computed, observable, makeObservable } from "mobx";
import { IBaseNode } from "@/contracts";

export class BimPartitionWall extends BimObject implements IBimPartitionWall {
    readonly location: IPoint;
    readonly dimensions: IPoint;
    readonly rotation: number;

    @observable
    private _isClosed: boolean | null = null;

    @computed
    get isClosed() {
        return this.ifOnline(this._isClosed);
    }

    constructor(node: IBaseNode & any) {
        super(node);
        makeObservable(this);
        this.location = node.position;
        this.dimensions = node.size;
        this.rotation = Math.abs(node.rotation);

        this.dimensions.x *= 100;
        this.dimensions.y *= 100;
        this.location.x = this.location.x * 100 - this.dimensions.x / 2;
        this.location.y = this.location.y * 100 - this.dimensions.y / 2;
    }
}

import { IBimFeatureColorTemp } from "@/contracts";
import { computed, observable, makeObservable } from "mobx";
import { IBaseNode } from "@/contracts";
import { BimLuminare } from "./BimLuminare";

export class BimTunableWhiteLuminare extends BimLuminare implements IBimFeatureColorTemp {

    @observable
    private _colorTemp: number | null = null;

    @computed
    get colorTemp() {
        return this.ifOnline(this._colorTemp);
    }


    constructor(node: IBaseNode & any) {
        super(node);
        makeObservable(this);
    }

    actionColorTemp(value: number): Promise<void> {
        return this.sendAction("recallColorTemperature", [{ colorTemperature: value }]);
    }
}

import { IBaseBimEventItem } from "@/contracts/IBimEventsService";
import { injectable } from "inversify";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { GetLogOptionsDirections } from "./Bim/EventsApi";

class ResultBlock<ITEMS extends IBaseBimEventItem> {
    public get first() { return this.items[0]; }
    public get last() { return this.items[this.items.length - 1]; }

    public readonly isComplete: boolean;

    constructor(
        blockSize: number,
        public readonly items: ITEMS[] = []
    ) {
        this.isComplete = this.items.length === blockSize;
    }
}

@injectable()
export abstract class BimBaseEventsService<ITEMS extends IBaseBimEventItem, OPTIONS extends Partial<Record<string, any>>> {

    protected orgId!: string;
    protected systemId!: string;
    protected dateIso!: string;
    protected options: OPTIONS = {} as OPTIONS;

    protected readonly blockSize: number = 0;

    @observable
    isBusy = 0;

    @computed get items() {
        let items: ITEMS[] = [];
        for (const block of this.data) {
            items = items.concat(block.items);
        }
        return items;
    }

    private data: ResultBlock<ITEMS>[] = observable([]);

    constructor() {
        makeObservable(this);
    }


    async start(orgId: string, systemId: string, dateIso: string, sequence?: number, options?: OPTIONS): Promise<void> {
        this.stop();

        this.orgId = orgId;
        this.systemId = systemId;
        this.dateIso = dateIso;
        this.options = options ?? {} as OPTIONS;

        let firstBlock: ResultBlock<ITEMS> | undefined;
        let secondBlock: ResultBlock<ITEMS> | undefined;
        const response = await this.fetchData(dateIso, sequence, GetLogOptionsDirections.BackwardExclusive);
        if (response.length !== 0) {
            firstBlock = new ResultBlock(this.blockSize, response);
        }

        const secondBlockStart = firstBlock ? firstBlock.last.timestamp : dateIso;
        const secondBlockSequence = firstBlock ? firstBlock.last.sequence : sequence;

        // Get some forward data too
        const response2 = await this.fetchData(secondBlockStart, secondBlockSequence, GetLogOptionsDirections.ForwardExclusive);
        if (response2.length !== 0) {
            secondBlock = new ResultBlock(this.blockSize, response2);
        }

        runInAction(() => {
            if (firstBlock) {
                this.data.push(firstBlock);
            }
            if (secondBlock) {
                this.data.push(secondBlock)
            }
        });
    }

    async startSingleEvent(orgId: string, systemId: string, eventId: string): Promise<boolean> {
        this.stop();

        this.orgId = orgId;
        this.systemId = systemId;
        this.dateIso = "";
        this.options = {} as OPTIONS;

        let firstBlock: ResultBlock<ITEMS> | undefined;

        try
        {
            const response = await this.getEvent(eventId);

            if (response) {
                firstBlock = new ResultBlock(this.blockSize, [response]);
            }
        }
        catch
        {
            return false;
        }
        
        runInAction(() => {
            if (firstBlock) {
                this.data.push(firstBlock);
            }
        });

        return true;
    }

    stop(): void {
        this.data.length = 0;
    }

    async getMoreLater(): Promise<void> {

        let timestamp;
        let sequence;

        const lastBlock = this.data[this.data.length - 1];

        let direction: GetLogOptionsDirections = GetLogOptionsDirections.ForwardExclusive;

        if (this.data.length === 1 && !lastBlock.isComplete) {
            // Re-fetch the incomplete first block
            timestamp = lastBlock.first ? lastBlock.first.timestamp : this.dateIso;
            sequence = undefined;
            direction = GetLogOptionsDirections.ForwardInclusive;
        } else {
            // Re-fetch the last block if incomplete otherwise fetch the next block
            const lastCompleteBlock = lastBlock.isComplete ? lastBlock : this.data[this.data.length - 2];
            timestamp = lastCompleteBlock.last.timestamp;
            sequence = lastCompleteBlock.last.sequence;
        }

        const response = await this.fetchData(timestamp, sequence, direction);

        this.addBlockToData(!lastBlock.isComplete, response);
    }

    async getMoreEarlier(): Promise<void> {

        const firstBlock = this.data[0];

        // If block not full, we're at the beginning of the log
        if (firstBlock.isComplete) {
            const response = await this.fetchData(firstBlock.first.timestamp, firstBlock.first.sequence, GetLogOptionsDirections.BackwardExclusive);
            this.data.unshift(new ResultBlock(this.blockSize, response));
        }
    }

    @action
    private addBlockToData(replaceLastBlock: boolean, response: ITEMS[]) {
        if (replaceLastBlock) {
            this.data.length = this.data.length - 1;
        }
        this.data.push(new ResultBlock(this.blockSize, response));
    }

    @action updateEvent(event: ITEMS) {
        // Find the containing block and it's index
        const blockIndex = this.data.findIndex(block => block.items.find(x => x.id === event.id) !== undefined);
        const containingBlock = this.data[blockIndex];

        // Clone the block
        const newBlock = new ResultBlock(this.blockSize, containingBlock.items);

        // Replace the event
        const eventIndex = newBlock.items.findIndex(x => x.id === event.id);
        newBlock.items[eventIndex] = event;

        // Replace the containing block with the updated clone
        this.data[blockIndex] = newBlock;

    }

    private fetchData(timestamp: string, sequence: number | undefined, direction: GetLogOptionsDirections): Promise<ITEMS[]> {

        let timeAndSequence;
        if (direction === GetLogOptionsDirections.ForwardInclusive || direction === GetLogOptionsDirections.ForwardExclusive) {
            timeAndSequence = {
                startDateTime: timestamp,
                startSequence: sequence
            }
        } else {
            timeAndSequence = {
                endDateTime: timestamp,
                endSequence: sequence
            }
        }

        return this.getEvents(direction, timeAndSequence);
    }

    protected abstract getEvents(direction: GetLogOptionsDirections, timeAndSequence: Record<string, any>): Promise<ITEMS[]>;
    protected abstract getEvent(eventId: string): Promise<ITEMS>;
}

import { Ii18n, IBimObject, isBimFeatureLiveState, IBimFeatureLiveState, isBimFeatureStatus, IBimFeatureStatus, IBimFeatureBrightness, isBimFeatureBrightness, isBimFeatureSecondaryBrightness, isBimFeatureOccupancyState, IBimFeatureOccupancyState, isBimFeatureLightReading, IBimFeatureLightReading, isBimFeatureColorTemp, IBimFeatureColorTemp, isBimFeatureInputLock, IBimFeatureInputLock, isBimZone, IBimZone, isBimPartitionWall, IBimPartitionWall, isBimFeatureEmergencyMode, IBimFeatureEmergencyMode, isBimFeatureBatteryChargeLevel, IBimFeatureBatteryChargeLevel, isBimFeatureBatteryVoltage_mV, IBimFeatureBatteryVoltage_mV } from "@/contracts";
import { inject, injectable } from "inversify";
import moment from "moment";
import { formatAddress } from "./utils/FormatAddress";

interface IVerbalizerItem {
    key: string;
    value: string;
}

@injectable()
export default class BimObjectVerbalizer {
    constructor(@inject(Ii18n) private i18n: Ii18n) {
    }

    *verbalizeErrors(item: IBimObject): Iterable<IVerbalizerItem> {
        if (isBimFeatureLiveState(item)) {
            const data = this.getConnectionState(item) || this.getErrorState(item);
            if (data) { yield { key: "error", value: data }; }
        }
    }

    *verbalizeState(item: IBimObject): Iterable<IVerbalizerItem> {
        if (isBimFeatureStatus(item)) {
            const data = this.getState(item);
            if (data) { yield { key: "status", value: data }; }
        }
        if (isBimFeatureBrightness(item)) {
            const data = this.getBrightness(item);
            if (data) { yield { key: "brightness", value: data }; }
        }
        if (isBimFeatureEmergencyMode(item)) {
            const data = this.getEmergencyMode(item);
            if (data) { yield { key: "emergencyMode", value: data }; }
        }
        if (isBimFeatureOccupancyState(item)) {
            const data = this.getOccupancyState(item);
            if (data) { yield { key: "occupancy", value: data }; }
        }
        if (isBimFeatureLightReading(item)) {
            const data = this.getLightReading(item);
            if (data) { yield { key: "lightReading", value: data }; }
        }
        if (isBimFeatureInputLock(item)) {
            const data = this.getInputLock(item);
            if (data) { yield { key: "inputLock", value: data }; }
        }
        if (isBimFeatureColorTemp(item)) {
            const data = this.getColorTemp(item);
            if (data) { yield { key: "colorTemp", value: data }; }
        }
        if (isBimPartitionWall(item)) {
            const data = this.getPartitionWallState(item);
            if (data) { yield { key: "pwState", value: data }; }
        }
        if (isBimFeatureBatteryChargeLevel(item)) {
            const data = this.getBatteryChargeLevel(item);
            if (data) { yield { key: "battery", value: data }; }
        }
        if (isBimFeatureBatteryVoltage_mV(item)) {
            const data = this.getBatteryVoltagePercent(item);
            if (data) { yield { key: "battery_mv", value: data }; }
        }
    }

    *verbalizeOther(item: IBimObject): Iterable<IVerbalizerItem> {
        if (isBimZone(item)) {
            const data = this.getEventInfo(item);
            if (data) { yield { key: "event", value: data }; }
            const data2 = this.getEventSource(item);
            if (data2) { yield { key: "eventSource", value: data2 }; }
            const data3 = this.getTimeTillOff(item);
            if (data3) { yield { key: "timeTillOff", value: data3 }; }
        }
    }

    getConnectionState(item: IBimFeatureLiveState) {
        if (item.connectionStatus === null) { return null; }  // TODO: What to do with no data?
        if (item.connectionStatus === "online") { return null; }
        return this.i18n.t("state-" + item.connectionStatus);
    }

    getErrorState(item: IBimFeatureLiveState) {
        if (item.errorState === null || item.connectionStatus === null) { return null; }  // TODO: What to do with no data?

        if (item.errorState === "operational") { return null; }
        return this.i18n.t("state-" + item.errorState);
    }

    getState(item: IBimFeatureStatus) {
        if (item.detailedStatus === null) { return null; }  // TODO: What to do with no data?
        return this.i18n.t("state-" + item.detailedStatus);
    }

    getPartitionWallState(item: IBimPartitionWall) {
        if (item.isClosed === null) { return null; }  // TODO: What to do with no data?
        return item.isClosed ? this.i18n.t("state-closed") : this.i18n.t("state-open");
    }

    getBatteryChargeLevel(item: IBimFeatureBatteryChargeLevel) {
        if (item.batteryChargeLevel === null) { return null; }  // TODO: What to do with no data?
        return this.i18n.t("Battery Level:") + " " + Math.floor(item.batteryChargeLevel) + "%";
    }

    getBatteryVoltagePercent(item: IBimFeatureBatteryVoltage_mV) {
        if(item.batteryVoltagePercent === null || item.batteryVoltagePercent > 100) { return null; }
        return this.i18n.t("Battery Voltage:") + " " + item.batteryVoltagePercent + "%";
    }

    getEmergencyMode(item: IBimFeatureEmergencyMode) {
        if (item.emergencyMode === null) { return null; }  // TODO: What to do with no data?
        return this.i18n.t("EmergencyMode" + item.emergencyMode);
    }

    getBrightness(item: IBimFeatureBrightness) {
        if (item.brightness === null) { return null; }  // TODO: What to do with no data?
        const brightness = Math.round(item.brightness / 10);
        if (isBimFeatureSecondaryBrightness(item)) {
            const secondaryBrightess = item.secondaryBrightness !== null ? Math.round(item.secondaryBrightness / 10) : "--";
            return `${brightness}% / ${secondaryBrightess}%`;
        }
        return brightness + "%";
    }

    getOccupancyState(item: IBimFeatureOccupancyState) {
        if (item.occupancyState === null) { return null; }  // TODO: What to do with no data?
        return this.i18n.t(item.occupancyState);
    }

    getLightReading(item: IBimFeatureLightReading) {
        if (item.lightReading === null) { return null; }  // TODO: What to do with no data?
        return `${item.lightReading} ${this.i18n.t("lux")}`;
    }

    getColorTemp(item: IBimFeatureColorTemp) {
        if (item.colorTemp === null) { return null; }  // TODO: What to do with no data?
        if (item.colorTemp === -1) { return null; } // in this case we just don't want to output anything
        return item.colorTemp + this.i18n.t("KelvinAbbr");
    }

    getInputLock(item: IBimFeatureInputLock) {
        if (item.isLocked === null) { return null; }  // TODO: What to do with no data?
        return item.isLocked ? this.i18n.t("Locked") : null; // in this case we just don't want to output anything
    }

    getEventInfo(item: IBimZone) {
        if (item.zoneOnSource === null) { return null; }
        if (item.zoneOnSource.eventType === 1) { return null; }
        return this.i18n.t("zoneOnEvent" + item.zoneOnSource.eventType);
    }

    getEventSource(item: IBimZone) {
        if (item.zoneOnSource === null) { return null; }
        if (item.zoneOnSource.source === 0) { return null; }
        const source = this.i18n.t("zoneOnSource" + item.zoneOnSource.source);
        return `${source} (${formatAddress(item.zoneOnSource.address)})`;
    }

    getTimeTillOff(item: IBimZone) {
        if (item.endOfZoneOn === null) { return null; }

        const endOfZoneOn = moment.unix(item.endOfZoneOn);
        if (endOfZoneOn.isBefore(moment())) { return null; }

        const offStr = this.i18n.t("timetilloff", { when: endOfZoneOn.fromNow() });
        return `${offStr} (${endOfZoneOn.format("LT")})`;
    }
}
import { IBimScheduleEvent, TimeFlags, IBimScheduleDate, IBimScheduleRecurrenceRule, RecurrenceType } from "@/contracts";
import { IBaseNode } from "@/contracts";
import { BimObject } from "./BimObject";
import moment from "moment";
import { EmergencyLightingEventType, ActivityTypes } from "@/contracts";
import { BimScheduleRecurrenceRule } from "./BimScheduleRecurrenceRule";


export class BimScheduleDate implements IBimScheduleDate {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;

    constructor(
        year: number,
        month: number,
        day: number,
        hour: number,
        minute: number
    ) {
        this.year = year;
        this.month = month;
        this.day = day;
        this.hour = hour;
        this.minute = minute;
    }
}

export default class BimScheduleEvent extends BimObject implements IBimScheduleEvent {


    get encSystemId(): string { return this.node.encSystemId; }
    get organizationId(): string { return this.node.organizationId; }
    get start(): IBimScheduleDate { return this.node.start; }
    get end(): IBimScheduleDate { return this.node.end; }

    get activityType(): ActivityTypes { return this.node.activityType; }
    set activityType(value) { this.node.activityType = value; }

    get name(): string { return this.node.name; }
    set name(value) { this.node.name = value; }

    get description(): string { return this.node.description; }
    set description(value) { this.node.description = value; }

    get subject(): string { return this.node.subject; }
    set subject(value) { this.node.subject = value; }

    get emergencyLightingEventType(): EmergencyLightingEventType { return this.node.emergencyLightingEventType; }
    set emergencyLightingEventType(value) { this.node.emergencyLightingEventType = value; }

    get emergencyLightingGroupId(): string { return this.node.emergencyLightingGroupId; }
    set emergencyLightingGroupId(value) { this.node.emergencyLightingGroupId = value; }

    get durationTestTime(): number { return this.node.durationTestTime; }
    set durationTestTime(value) { this.node.durationTestTime = value; }

    get exceptions(): IBimScheduleEvent[] | undefined { 
        if(this.node.recurrenceRule) return this.node.recurrenceRule.exceptions; 
        return undefined;
    }
    set exceptions(value) { 
        if(this.node.recurrenceRule) {
            if(value !== null) this.node.recurrenceRule.exceptions = value;
            else delete this.node.recurrenceRule.exceptions;
        }
     }

    get endMoment(): moment.Moment { return BimScheduleEvent.scheduleDateTimeToMoment(this.node.end) as moment.Moment; }
    set endMoment(value: moment.Moment) { this.node.end = BimScheduleEvent.momentToScheduleDateTime(value); }

    get startMoment(): moment.Moment { return BimScheduleEvent.scheduleDateTimeToMoment(this.node.start) as moment.Moment; }
    set startMoment(value: moment.Moment) { this.node.start = BimScheduleEvent.momentToScheduleDateTime(value); }

    get endPlusMinusTime(): number { return this.node.endPlusMinusTime; }
    set endPlusMinusTime(value) { this.node.endPlusMinusTime = value; }

    get endTimeFlags(): TimeFlags { return this.asTimeFlags(this.node.endTimeFlags); }
    set endTimeFlags(value) { this.node.endTimeFlags = this.asNodeTimeFlagValues(value); }

    get startPlusMinusTime(): number { return this.node.startPlusMinusTime; }
    set startPlusMinusTime(value) { this.node.startPlusMinusTime = value; }

    get startTimeFlags(): TimeFlags { return this.asTimeFlags(this.node.startTimeFlags); }
    set startTimeFlags(value) { this.node.startTimeFlags = this.asNodeTimeFlagValues(value); }

    get isAllDayEvent(): boolean { return this.node.isAllDayEvent; }
    set isAllDayEvent(value) { this.node.isAllDayEvent = value; }

    get recallScene(): number { return this.node.recallScene; }
    set recallScene(value) { this.node.recallScene = value; }

    get recurrenceType(): RecurrenceType {
        return this.node.recurrenceRule?.recurrenceType;
    }

    get recurrenceRule(): IBimScheduleRecurrenceRule | null {
        if (this.node.recurrenceRule != null) {
            return new BimScheduleRecurrenceRule(this.node.recurrenceRule);
        } else {
            return null;
        }
    }

    set recurrenceRule(value) {
        const recurrenceRule = value as BimScheduleRecurrenceRule | null;
        if (recurrenceRule == null) {
            delete this.node.recurrenceRule;
        } else {
            this.node.recurrenceRule = recurrenceRule.node;
        }

    }

    public static scheduleDateTimeToMoment(scheduleDateTime: IBimScheduleDate): moment.Moment | null {
        if (scheduleDateTime != null && scheduleDateTime.year !== 0) {
            const momentInTime: moment.Moment = moment();
            momentInTime.set("year", scheduleDateTime.year);
            momentInTime.set("month", scheduleDateTime.month - 1);
            momentInTime.set("date", scheduleDateTime.day);
            momentInTime.set("hour", scheduleDateTime.hour);
            momentInTime.set("minute", scheduleDateTime.minute);
            return momentInTime;
        }
        return null;
    }

    public static momentToScheduleDateTime(momentInTime: moment.Moment | null): IBimScheduleDate {
        let dateAndTime: IBimScheduleDate;
        if (momentInTime != null) {
            dateAndTime = new BimScheduleDate(momentInTime.year(), momentInTime.month() + 1, momentInTime.date(), momentInTime.hour(), momentInTime.minute());
        } else {
            dateAndTime = new BimScheduleDate(0, 0, 0, 0, 0);
        }
        return dateAndTime;
    }


    public node: IBaseNode & any;

    constructor(node: IBaseNode & any) {
        super(node);
        this.node = node;
    }

    private asNodeTimeFlagValues(flag: TimeFlags): any {
        let nodeTimeFlags: string = "";
        if (flag === TimeFlags.Default) {
            nodeTimeFlags = "Default";
            return nodeTimeFlags;
        } else {
            if ((TimeFlags.After & flag) === TimeFlags.After) {
                nodeTimeFlags += "After" + "|";
            }
            if ((TimeFlags.Before & flag) === TimeFlags.Before) {
                nodeTimeFlags += "Before" + "|";
            }
            if ((TimeFlags.NextDay & flag) === TimeFlags.NextDay) {
                nodeTimeFlags += "NextDay" + "|";
            }
            if ((TimeFlags.Sunrise & flag) === TimeFlags.Sunrise) {
                nodeTimeFlags += "Sunrise" + "|";
            }
            if ((TimeFlags.Sunset & flag) === TimeFlags.Sunset) {
                nodeTimeFlags += "Sunset" + "|";
            }
        }

        if (nodeTimeFlags.length > 0 && nodeTimeFlags[nodeTimeFlags.length - 1] === "|") {
            nodeTimeFlags = nodeTimeFlags.substr(0, nodeTimeFlags.length - 1);
        }

        return nodeTimeFlags;
    }

    private asTimeFlags(timeFlagsNode: string): TimeFlags {
        let timeFlags: TimeFlags = TimeFlags.Default;
        if (timeFlagsNode != null) {
            const individualTimeFlags: string[] = timeFlagsNode.split("|");
            individualTimeFlags.forEach(flag => {
                if (flag.toLowerCase() === "after") {
                    timeFlags = timeFlags | TimeFlags.After;
                } else if (flag.toLowerCase() === "before") {
                    timeFlags = timeFlags | TimeFlags.Before;
                } else if (flag.toLowerCase() === "nextday") {
                    timeFlags = timeFlags | TimeFlags.NextDay;
                } else if (flag.toLowerCase() === "sunrise") {
                    timeFlags = timeFlags | TimeFlags.Sunrise;
                } else if (flag.toLowerCase() === "sunset") {
                    timeFlags = timeFlags | TimeFlags.Sunset;
                }
            });
        }
        return timeFlags;
    }

}

import { floorplanModes, IApplicationContext, IApplicationState, IBimFeatureBatteryVoltage_mV, IBimObject, isBimFeatureBatteryVoltage_mV } from "@/contracts";
import { BatteryLevel } from "@/services/Bim/BimBatteryUtils";
import { Box, Typography } from "@mui/material";
import { inject, injectable } from "inversify";
import React from "react";
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';

@injectable()
export abstract class ITooltipGenerator {
    constructor(
        @inject(IApplicationState) protected appState: IApplicationState,
        @inject(IApplicationContext) protected appContext: IApplicationContext
    ) { }

    getTip(item: IBimObject): React.ReactNode {
        return null;
    }
}

function getBatteryIcon(item: IBimFeatureBatteryVoltage_mV): React.ReactNode {
    switch (item.batteryVoltageLevel) {
        case BatteryLevel.high:
            return <BatteryFullIcon color="success" />
        case BatteryLevel.medium:
            return <Battery3BarIcon color="warning" />
        case BatteryLevel.low:
            return <Battery1BarIcon color="error" />
        default:
            return null;
    }
}

@injectable()
export class ToolTipGenerator extends ITooltipGenerator {
    getTip(item: IBimObject): React.ReactNode {
        if (this.appState.floorplanMode === floorplanModes.battery) {
            if (isBimFeatureBatteryVoltage_mV(item)) {
                if (item.connectionStatus && item.connectionStatus === "online" && item.batteryVoltageLevel) {
                    return (<Box textAlign="center"><Typography textAlign="center" variant="body2">{item.batteryVoltagePercent} %</Typography>{getBatteryIcon(item)}</Box>);
                }
            }
        }

        return null;
    }
}

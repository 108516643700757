import { IBimObject, NodeTypes } from ".";

export enum EmergencyModes {
    Invalid = 0,
    Normal,
    Emergency,
    ExtEmergency,
    Rest,
    FunctionTest,
    DurationTest
}

export interface ILiveUpdate {
    state: any;
    timestamp: number;
    version: number;
    nodeId: string;
    nodeType: string;
}

export abstract class ILiveApi {
    abstract unsubscribe(): void;
    abstract unsubscribeFromAlarms(): void;
    abstract subscribeToFloor(orgId: string, systemId: string, floorId: string, otherItems: IBimObject[], onUpdate: (data?: ILiveUpdate) => void): void;
    abstract subscribeTo(orgId: string, systemId: string, nodeTypes: NodeTypes[], onUpdate: (data?: ILiveUpdate) => void): void;
    abstract subscribeToAlarms(orgId: string, systemId: string, onUpdate: (data?: ILiveUpdate) => void): void;
}


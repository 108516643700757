
export class VersionMap {
    private versionMap: Map<string, number> = new Map();

    reset() {
        this.versionMap.clear();
    }

    isLatest(id: string, attribute: string, version: number): boolean {

        const key = id + attribute;
        const lastVersion = this.versionMap.get(key);
        if (lastVersion === undefined || lastVersion <= version) {
            this.versionMap.set(key, version);
            return true;
        }

        return false;
    }
}

import { injectable } from "inversify";
import i18next, { TFunction } from "i18next";
import XHR from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { Ii18n } from "@/contracts";
import { observable, makeObservable } from "mobx";

@injectable()
export class I18n implements Ii18n {
    language: string = "";
    languages: string[] = [];
    ready: Promise<boolean>;

    //private i18next!: i18next.i18n;

    constructor() {
        makeObservable(this);

        this.onLanguageChange = this.onLanguageChange.bind(this);
        this.init = this.init.bind(this);

        this.ready = new Promise(this.init);
    }

    @observable
    // t: TFunction = (key: string): string => key
    t = (key: string) => key;

    exists(key: string) {
        return i18next.exists(key);
    }

    changeLanguage(language: string): void {
        i18next.changeLanguage(language, this.onLanguageChange);
    }

    private onLanguageChange(err: any, t: TFunction) {
        if (t) {
            this.t = t;
            this.language = i18next.language;
            this.languages = [...i18next.languages];
        }
    }

    private init(resolve: any, reject: any) {

        i18next
            .use(XHR)
            .use(LanguageDetector)
            .init({
                backend: {
                    loadPath: "{{lng}}",
                    parse: (data: any) => data,
                    request: this.loadLocales
                },
                fallbackLng: "en",
                supportedLngs: ["en", "en-CA", "en-US"],
                nonExplicitSupportedLngs: true,
                debug: __DEBUG__,
                interpolation: {
                    escapeValue: false, // not needed for react!!
                }
            }, (err, t) => {
                this.onLanguageChange(err, t);
                if (err) {
                    reject(err);
                } else {
                    resolve(true);
                }
            });
    }

    private async loadLocales(options: any, url: string, payload: any, callback: any) {
        try {

            const locale = await import("@/locales/" + url + "/translation.json");
            callback(null, { status: "200", data: locale });
        } catch (e) {
            callback("Translation File not found", { status: "404" });
        }
    }
}

import { IBimScheduleOccurrence, EventTypes } from "@/contracts";
import { TimeFlags, ActivityTypes, EmergencyLightingEventType } from "@/contracts/IBimManagerConfig";
import { IBaseNode } from "@/contracts";
import { BimObject } from "./BimObject";
import moment from "moment";

const flagMap = {
    "Default": TimeFlags.Default,
    "Before": TimeFlags.Before,
    "After": TimeFlags.After,
    "Sunrise": TimeFlags.Sunrise,
    "Sunset": TimeFlags.Sunset,
    "NextDay": TimeFlags.NextDay,
};


export default class BimScheduleOccurrence extends BimObject implements IBimScheduleOccurrence {
    exceptionEventId: string;
    eventType: EventTypes;
    emergencyLightingEventType: EmergencyLightingEventType;
    emergencyLightingGroupId: string;
    durationTestTime?: number;
    activityType: ActivityTypes;
    endTimeFlags: TimeFlags;
    startTimeFlags: TimeFlags;
    isAllDayEvent: boolean;
    isRescheduledApp: boolean;
    recallScene: number;
    subject: string;
    start: moment.Moment;
    end: moment.Moment;

    constructor(node: IBaseNode & any) {
        super(node);
        this.isAllDayEvent = node.isAllDayEvent;
        this.eventType = node.eventType;
        this.emergencyLightingEventType = node.emergencyLightingEventType;
        this.emergencyLightingGroupId = node.emergencyLightingGroupId;
        this.durationTestTime = node.durationTestTime;
        this.activityType = node.activityType;
        this.exceptionEventId = node.exceptionEventId;
        this.isRescheduledApp = node.isRescheduledApp;
        this.recallScene = node.recallScene;
        this.subject = node.subject;
        this.endTimeFlags = node.endTimeFlags;
        this.startTimeFlags = this.getTimeFlags(node.startTimeFlags);
        this.start = moment({ y: node.start.year, M: node.start.month - 1, d: node.start.day, h: node.start.hour, m: node.start.minute, s: 0, ms: 0 });
        this.end = moment({ y: node.end.year, M: node.end.month - 1, d: node.end.day, h: node.end.hour, m: node.end.minute, s: 0, ms: 0 });
        this.endTimeFlags = this.getTimeFlags(node.endTimeFlags);
    }

    private getTimeFlags(flagString: string) {
        const flagArray = flagString.split("|");
        let flags = 0;
        for (const flag of flagArray) {
            flags |= flagMap[flag as keyof typeof flagMap];
        }
        return flags;
    }

}

import { ecuStates, IBaseNode, IBimBallast, IBimEmergencyInverter, IBimFloorObject, IBimLuminare, IBimObject, IMultiItem, IMultiItemChild, IPoint, IRelation } from "@/contracts";
import { Dictionary } from "lodash";
import { computed, makeObservable, observable, override } from "mobx";
import { BimObject } from "./BimObject";

export class BimBallast extends BimObject implements IBimBallast {
    @observable private _status: "on" | "off" | null = null;
    @observable private _detailedStatus: ecuStates | null = null;
    @observable private _brightness: number | null = null;
    @observable private _secondaryBrightness: number | null = null;

    @computed get status() { return this.ifOnline(this._status); }
    @computed get detailedStatus() { return this.ifOnline(this._detailedStatus); }
    @computed get brightness() { return this.ifOnline(this._brightness); }
    @computed get secondaryBrightness() { return this.ifOnline(this._secondaryBrightness); }

    constructor(node: IBaseNode & any) {
        super(node);
        makeObservable(this);
    }

    actionOn(): Promise<any> {
        return this.sendAction("on");
    }

    actionOff(): Promise<any> {
        return this.sendAction("off");
    }

    actionBrightness(value: number): Promise<void> {
        return this.sendAction("dimAbsolute", [{ brightness: value }]);
    }
}

export class GroupedFixture extends BimBallast implements IBimBallast, IMultiItem{
    private childItems: BimGroupedLuminareChild[] = [];

    init(outRelations: Dictionary<IRelation[]>, inRelations: Dictionary<IRelation[]>, nodes: Map<string, IBimObject>) {
        super.init(outRelations, inRelations, nodes);
        this.childItems = this.findRelatedNodes(outRelations[this.id], nodes, ["GroupedBallast"], true);
    }

    get children() { return this.childItems; }
    
    getAllItems(): IBimFloorObject[] { return this.childItems }
}

export class BimLuminare extends BimBallast implements IBimLuminare {
    readonly location: IPoint;
    readonly dimensions: IPoint;
    readonly rotation: number;

    constructor(node: IBaseNode & any) {
        super(node);
        this.location = node.position;
        this.dimensions = node.size;
        this.rotation = -node.rotation;
        this.dimensions.x *= 100;
        this.dimensions.y *= 100;
        this.location.x = this.location.x * 100 - this.dimensions.x / 2;
        this.location.y = this.location.y * 100 - this.dimensions.y / 2;
    }
}

export class BimGroupedLuminareChild extends BimLuminare implements IBimLuminare, IMultiItemChild {
    private parent?: GroupedFixture;

    @override get connectionStatus() { 
        return this.parent?.connectionStatus ?? null;
    }
    @override get errorState() { return this.parent?.errorState ?? null; }
    @override get status() { return this.parent?.status ?? null; }
    @override get detailedStatus() { return this.parent?.detailedStatus ?? null; }
    @override get brightness() { return this.parent?.brightness ?? null; }
    @override get secondaryBrightness() { return this.parent?.secondaryBrightness ?? null; }

    init(outRelations: Dictionary<IRelation[]>, inRelations: Dictionary<IRelation[]>, nodes: Map<string, IBimObject>) {
        super.init(outRelations, inRelations, nodes);
        this.parent = this.findRelatedNode(inRelations[this.id], nodes, ["Luminaire", "WslcLuminaire", "WalcLuminaire", "TunableWhiteLuminaire"], false);
    }

    @override
    get name() { return this.parent?.name ?? ""; }
    set name(value) { this.parent!.name = value; }

    getParent(): IBimFloorObject | undefined { return this.parent; }
    getAllItems(): IBimFloorObject[] { return this.parent?.getAllItems() ?? [] }

    updateState(state?: any): void {
        // grouped fixture doesn't directly have live state
        // State is proxied from the parent
    }

    actionBrightness(value: number): Promise<void> {
        if (!this.parent) return Promise.resolve();
        return this.parent.actionBrightness(value);
    }
    actionOn(): Promise<void> {
        if (!this.parent) return Promise.resolve();
        return this.parent.actionOn();
    }
    actionOff(): Promise<void> {
        if (!this.parent) return Promise.resolve();
        return this.parent.actionOff();
    }
}

// This is a non-maintained emergency fixure
// Will NOT have a ballast. Must have an inverter

export class BimNonMaintainedEmergencyLuminare extends BimObject implements IBimLuminare, IBimEmergencyInverter {
    readonly location: IPoint;
    readonly dimensions: IPoint;
    readonly rotation: number;

    private inverter?: IBimEmergencyInverter;

 //   @observable private _status: "on" | "off" | null = null;
 //   @observable private _detailedStatus: ecuStates | null = null;

    @override get connectionStatus() { return this.inverter?.connectionStatus ?? null; }

    @computed get emergencyMode() { return this.ifOnline(this.inverter?.emergencyMode ?? null); }
    @computed get batteryChargeLevel() { return this.ifOnline(this.inverter?.batteryChargeLevel ?? null); }
   // @computed get status() { return this.ifOnline(this._status); }
   // @computed get detailedStatus() { return this.ifOnline(this._detailedStatus); }

    constructor(node: IBaseNode & any) {
        super(node);
        makeObservable(this);
        this.location = node.position;
        this.dimensions = node.size;
        this.rotation = -node.rotation;
        this.dimensions.x *= 100;
        this.dimensions.y *= 100;
        this.location.x = this.location.x * 100 - this.dimensions.x / 2;
        this.location.y = this.location.y * 100 - this.dimensions.y / 2;
    }

    init(outRelations: Dictionary<IRelation[]>, inRelations: Dictionary<IRelation[]>, nodes: Map<string, IBimObject>) {
        this.inverter = this.findRelatedNode(outRelations[this.id], nodes, "EmergencyInverter");
    }

    updateState(state?: any): void {
        // Emerg fixture doesn't directly have live state
        // State is proxied from the inverter
    }
}

// This is a central or maintained emergency fixure
// Will have a ballast. May have an inverter
export class BimEmergencyLuminare extends BimNonMaintainedEmergencyLuminare implements IBimLuminare, IBimBallast, IBimEmergencyInverter {
    private ballast?: IBimBallast;

    @override get connectionStatus() { 
        const ballastStatus = this.ballast?.connectionStatus ?? null;
        if(ballastStatus !== null && ballastStatus != "online")
            return ballastStatus;

        const inverterStatus = super.connectionStatus;
        if(inverterStatus !== null && inverterStatus != "online")
            return inverterStatus;

        return ballastStatus ? ballastStatus : inverterStatus;
    }
    @override get errorState() { return this.ballast?.errorState ?? null; }
    @computed get status() { return this.ballast?.status ?? null; }
    @computed get detailedStatus() { return this.ballast?.detailedStatus ?? null; }
    @computed get brightness() { return this.ballast?.brightness ?? null; }
    @computed get secondaryBrightness() { return this.ballast?.secondaryBrightness ?? null; }

    init(outRelations: Dictionary<IRelation[]>, inRelations: Dictionary<IRelation[]>, nodes: Map<string, IBimObject>) {
        super.init(outRelations, inRelations, nodes);
        this.ballast = this.findRelatedNode(outRelations[this.id], nodes, "Ballast");
    }

    updateState(state?: any): void {
        // Emerg fixture doesn't directly have live state
        // State is proxied from the ballast and/or inverter
    }

    actionBrightness(value: number): Promise<void> {
        if (!this.ballast) return Promise.resolve();
        return this.ballast.actionBrightness(value);
    }
    actionOn(): Promise<void> {
        if (!this.ballast) return Promise.resolve();
        return this.ballast.actionOn();
    }
    actionOff(): Promise<void> {
        if (!this.ballast) return Promise.resolve();
        return this.ballast.actionOff();
    }
}

export class BimEmergencyInverter extends BimObject implements IBimEmergencyInverter {
    @observable private _emergencyMode: number | null = null;
    @observable private _batteryChargeLevel: number | null = null;

    @computed get emergencyMode() { return this.ifOnline(this._emergencyMode); }
    @computed get batteryChargeLevel() { return this.ifOnline(this._batteryChargeLevel); }
    
    constructor(node: IBaseNode & any) {
        super(node);
        makeObservable(this);
    }
}

export function BimLuminareFactory(node: IBaseNode & any) {
    if (node.groupedFixture) {
        return new GroupedFixture(node);
    }
    else if(node.nodeType == "GroupedBallast"){
        return new BimGroupedLuminareChild(node);    
    }

    return new BimLuminare(node);
}

export function BimEmergencyLuminareFactory(node: IBaseNode & any) {
    if (node.emergencyFixtureType === "NonMaintained") {
        return new BimNonMaintainedEmergencyLuminare(node);
    }
    return new BimEmergencyLuminare(node);
}


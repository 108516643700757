import React from "react";
import { CircularProgress, Modal } from "@mui/material";
import { observer } from "mobx-react";

interface IBlockingSpintWaitProps {
    open: boolean;
    delay?: number;
}

@observer
export default class BlockingSpinWait extends React.Component<IBlockingSpintWaitProps> {

    state = {
        isVisible: false
    };

    private timer = 0;

    componentDidMount() {
        this.updateState();
    }
    componentDidUpdate() {
        this.updateState();
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {

        return (
            <Modal open={this.props.open} hideBackdrop={!this.state.isVisible}>
                <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {this.state.isVisible &&
                        <CircularProgress />
                    }
                </div>
            </Modal>
        );
    }

    private updateState() {
        if (this.props.open && this.timer === 0) {
            this.timer = window.setTimeout(() => {
                this.setState({ isVisible: true });
            }, this.props.delay || 800);
        } else if (!this.props.open) {
            if (this.timer !== 0) {
                clearTimeout(this.timer);
                this.timer = 0;
                this.setState({ isVisible: false });
            }
        }

    }
}

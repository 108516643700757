import { DaysOfWeek, DayOrdinal, RecurrenceType, IBimScheduleRecurrenceRule } from "@/contracts";
import defaultScheduleRecurrenceRule from "./defaultScheduleRecurrenceRule.json";
import BimScheduleEvent from "./BimScheduleEvent";
import moment from "moment";
import { BimObject } from "./BimObject";
import { IBaseNode } from "@/contracts";
import { Guid } from "guid-typescript";


export class BimScheduleRecurrenceRule extends BimObject implements IBimScheduleRecurrenceRule {


    public static getNewRecurrenceRule(): IBimScheduleRecurrenceRule {
        const newRecurrenceRuleNode: any = Object.assign({}, defaultScheduleRecurrenceRule);
        newRecurrenceRuleNode.organizationId = BimObject.appContext.currentOrg!.id;
        newRecurrenceRuleNode.encSystemId = BimObject.appContext.currentSystem!.id;
        newRecurrenceRuleNode.id = Guid.create().toString();
        const newRecurrenceRule: IBimScheduleRecurrenceRule = new BimScheduleRecurrenceRule(newRecurrenceRuleNode);
        return newRecurrenceRule;
    }

    node: any & IBaseNode;

    constructor(node: IBaseNode & any) {
        super(node);
        this.node = node;
        if (this.node.recursUntil !== undefined && this.node.recursUntil !== null && this.node.recursUntil.year === 0) {
            delete this.node.recursUntil;
        }
    }



    get calendarId(): string { return this.node.calendarId; }
    set calendarId(value) { this.node.calendarId = value; }

    get dayOfMonth(): number { return this.node.dayOfMonth; }
    set dayOfMonth(value) { this.node.dayOfMonth = value; }

    get dayOrdinal(): DayOrdinal { return this.node.dayOrdinal; }
    set dayOrdinal(value) { this.node.dayOrdinal = value; }

    get daysOfWeek(): DaysOfWeek { return this.asDaysOfWeek(this.node.daysOfWeek); }
    set daysOfWeek(value) { this.node.daysOfWeek = this.asDaysOfWeekNodeValues(value); }

    get maxOccurrences(): number { return this.node.maxOccurences; }
    set maxOccurrences(value) { this.node.maxOccurences = value; }

    get monthOfYear(): number { return this.node.monthOfYear; }
    set monthOfYear(value) { this.node.monthOfYear = value; }

    get recurrenceType(): RecurrenceType { return this.node.recurrenceType; }
    set recurrenceType(value) { this.node.recurrenceType = value; }

    get recursUntil(): moment.Moment | null { return BimScheduleEvent.scheduleDateTimeToMoment(this.node.recursUntil); }
    set recursUntil(value: moment.Moment | null) { this.node.recursUntil = BimScheduleEvent.momentToScheduleDateTime(value); }


    private asDaysOfWeekNodeValues(flag: DaysOfWeek): any {
        let nodeDaysOfWeek: string = "";
        if ((DaysOfWeek.Monday & flag) === DaysOfWeek.Monday) {
            nodeDaysOfWeek += "Monday" + "|";
        }
        if ((DaysOfWeek.Tuesday & flag) === DaysOfWeek.Tuesday) {
            nodeDaysOfWeek += "Tuesday" + "|";
        }
        if ((DaysOfWeek.Wednesday & flag) === DaysOfWeek.Wednesday) {
            nodeDaysOfWeek += "Wednesday" + "|";
        }
        if ((DaysOfWeek.Thursday & flag) === DaysOfWeek.Thursday) {
            nodeDaysOfWeek += "Thursday" + "|";
        }
        if ((DaysOfWeek.Friday & flag) === DaysOfWeek.Friday) {
            nodeDaysOfWeek += "Friday" + "|";
        }
        if ((DaysOfWeek.Saturday & flag) === DaysOfWeek.Saturday) {
            nodeDaysOfWeek += "Saturday" + "|";
        }
        if ((DaysOfWeek.Sunday & flag) === DaysOfWeek.Sunday) {
            nodeDaysOfWeek += "Sunday" + "|";
        }
        if ((DaysOfWeek.WeekDays & flag) === DaysOfWeek.WeekDays) {
            nodeDaysOfWeek += "WeekDays" + "|";
        }
        if ((DaysOfWeek.WeekEnds & flag) === DaysOfWeek.WeekEnds) {
            nodeDaysOfWeek += "WeekEnds" + "|";
        }
        if ((DaysOfWeek.EveryDay & flag) === DaysOfWeek.EveryDay) {
            nodeDaysOfWeek += "EveryDay" + "|";
        }
        if (nodeDaysOfWeek.length > 0 && nodeDaysOfWeek[nodeDaysOfWeek.length - 1] === "|") {
            nodeDaysOfWeek = nodeDaysOfWeek.substr(0, nodeDaysOfWeek.length - 1);
        }
        return nodeDaysOfWeek;
    }

    private asDaysOfWeek(daysOfWeekNodeValues: string): DaysOfWeek {
        let daysOfWeek: DaysOfWeek = 0 as DaysOfWeek;
        if (daysOfWeekNodeValues != null) {
            const individualTimeFlags: string[] = daysOfWeekNodeValues.split("|");
            individualTimeFlags.forEach(flag => {
                if (flag.toLowerCase() === "monday") {
                    daysOfWeek = daysOfWeek | DaysOfWeek.Monday;
                } else if (flag.toLowerCase() === "tuesday") {
                    daysOfWeek = daysOfWeek | DaysOfWeek.Tuesday;
                } else if (flag.toLowerCase() === "wednesday") {
                    daysOfWeek = daysOfWeek | DaysOfWeek.Wednesday;
                } else if (flag.toLowerCase() === "thursday") {
                    daysOfWeek = daysOfWeek | DaysOfWeek.Thursday;
                } else if (flag.toLowerCase() === "friday") {
                    daysOfWeek = daysOfWeek | DaysOfWeek.Friday;
                } else if (flag.toLowerCase() === "saturday") {
                    daysOfWeek = daysOfWeek | DaysOfWeek.Saturday;
                } else if (flag.toLowerCase() === "sunday") {
                    daysOfWeek = daysOfWeek | DaysOfWeek.Sunday;
                }
            });
        }
        return daysOfWeek;
    }


}

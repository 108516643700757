import { floorplanModes, IApplicationContext, IApplicationState, IBimFeatureBatteryVoltage_mV, IBimFeatureBrightness, IBimFeatureStatus, IBimLiveAlarms, IBimObject, IBimPartitionWall, IBimZone, isBimFeatureBatteryVoltage_mV, isBimFeatureLiveState, isBimFeatureOccupancyState } from "@/contracts";
import { inject, injectable } from "inversify";
import { reaction } from "mobx";
import { toBatteryVoltageColor, toBrightnessColor, toPartitionWallColor } from "./StateBrightnessUtils";

@injectable()
export abstract class IColorizer {
    constructor(
        @inject(IApplicationState) protected appState: IApplicationState,
        @inject(IApplicationContext) protected appContext: IApplicationContext
    ) { }

    toFillColor(item: IBimObject): string {
        return "transparent";
    }
    toStrokeColor(item: IBimObject): string {
        return "transparent";
    }
}

@injectable()
export class ScheduleColorizer extends IColorizer {

    private static colors = [
        "#FA26A0",
        "#F8D210",
        "#2CEEF0",
        "#2FF3E0",
        "#0476D0",
        "#ED7890",
        "#A1A6B4",
        "#FACBD0",
        "#FFD700",
        "#F34C50",
        "#F7C9C0",
        "#C6D4FF",
        "#FF9F1C",
        "#70EE9C",
        "#46B1C9",
        "#FFDE0A",
        "#E26D5C",
        "#4F6D7A",
        "#94C5CC",
        "#C7F2A7",
        "#B4656F",
        "#4E937A",
        "#6A8EAE",
        "#FE6A0F",
        "#94ECBE"
    ];
    private colorTable = this.initColorTable();

    constructor(
        @inject(IApplicationState) protected appState: IApplicationState,
        @inject(IApplicationContext) protected appContext: IApplicationContext) {
        super(appState, appContext);

        // update color table when the current floor changes
        reaction(() => this.appContext.currentZones, () => this.colorTable = this.initColorTable());
    }

    toFillColor(item: IBimObject) {
        return this.idToFillColor(item.id);
    }

    idToFillColor(id: string) {
        if (this.colorTable.size === 0) { this.colorTable = this.initColorTable(); }
        const color = this.colorTable.get(id);
        return color || "transparent";
    }

    private initColorTable(): Map<string, string> {
        const inContextScheduleIds = new Set(this.appContext.currentZones.filter(x => x.schedule).map(x => x.schedule!));
        return new Map([...inContextScheduleIds].map((x, i) => [x, ScheduleColorizer.colors[i % ScheduleColorizer.colors.length]]));
    }
}

@injectable()
export class ZoneColorizer extends IColorizer {
    constructor(
        @inject(IApplicationState) protected appState: IApplicationState,
        @inject(IApplicationContext) protected appContext: IApplicationContext,
        @inject(ScheduleColorizer) protected scheduleColorizer: ScheduleColorizer) {
        super(appState, appContext);
    }

    toFillColor(item: IBimObject): string {
        const zoneItem = item as IBimZone;
        if (this.appState.floorplanMode === floorplanModes.control) {
            return toBrightnessColor(zoneItem);
        } else if (this.appState.floorplanMode === floorplanModes.alerts) {
            // TODO
        } else if (this.appState.floorplanMode === floorplanModes.schedule) {
            if (zoneItem.schedule) {
                return this.scheduleColorizer.idToFillColor(zoneItem.schedule);
            }
        }
        return "transparent";
    }
}

@injectable()
export class FixtureColorizer extends IColorizer {
    toFillColor(item: IBimObject): string {
        if (this.appState.floorplanMode === floorplanModes.control) {
            return toBrightnessColor(item as unknown as IBimFeatureBrightness & IBimFeatureStatus);
        } else if (this.appState.floorplanMode === floorplanModes.alerts) {
            // TODO
        }
        return "transparent";
    }
}

@injectable()
export class PartitionWallColorizer extends IColorizer {
    toFillColor(item: IBimObject): string {
        if (this.appState.floorplanMode === floorplanModes.control) {
            return toPartitionWallColor(item as IBimPartitionWall);
        } else if (this.appState.floorplanMode === floorplanModes.alerts) {
            // TODO
        }
        return "transparent";
    }
}

@injectable()
export class IconColorizer extends IColorizer {
    toFillColor(item: IBimObject): string {
        if (this.appState.floorplanMode === floorplanModes.control) {
            if (isBimFeatureLiveState(item)) {
                if (item.errorState && item.errorState !== "operational") { return "red"; }
                if (item.connectionStatus && item.connectionStatus !== "online") { return "red"; }
                if ((item as unknown as IBimLiveAlarms).alarmCount > 0) { return "red"; }
                if (isBimFeatureOccupancyState(item)) {
                    return item.occupancyState === "occupied" ? "#00be00" : "transparent";
                }
            }
        }
        else if (this.appState.floorplanMode === floorplanModes.battery) {
            if (isBimFeatureBatteryVoltage_mV(item)) {
                if (item.connectionStatus && item.connectionStatus === "online") {
                    return toBatteryVoltageColor(item as unknown as IBimFeatureBatteryVoltage_mV);
                }
            }
        }
        return "transparent";
    }
}

import { ILiveUpdate } from "@/contracts";

export class LiveSocket {

    private socket?: WebSocket;
    private onUpdate?: (data: ILiveUpdate | null) => void;

    connect(url: string, onUpdate: (data: ILiveUpdate | null) => void): Promise<void> {
        this.socket = new WebSocket(url);
        this.socket.onclose = this.onClose;
        this.socket.onerror = this.onError;
        this.socket.onmessage = this.onMessage;
        this.onUpdate = onUpdate;

        return new Promise<void>((resolve, reject) => {
            const completePromise = () => {
                this.socket?.removeEventListener("open", completePromise);
                this.socket?.removeEventListener("close", completePromise);
                this.socket?.removeEventListener("error", completePromise);
                if (this.socket?.readyState === WebSocket.OPEN) {
                    resolve();
                } else {
                    reject();
                }
            };
            this.socket?.addEventListener("open", completePromise);
            this.socket?.addEventListener("close", completePromise);
            this.socket?.addEventListener("error", completePromise);
        });
    }

    disconnect() {
        if (this.socket?.readyState !== WebSocket.CLOSED) {
            this.socket?.close();
        }
        this.socket = undefined;
        this.onUpdate = undefined;
    }


    private onClose = (e: CloseEvent) => {
        if (e.code !== 1000) {
            this.onUpdate?.(null);
        }
    };

    private onError = (e: Event) => {
        this.onUpdate?.(null);
    };

    private onMessage = (e: MessageEvent) => {
        const data = JSON.parse(e.data) as ILiveUpdate;
        this.onUpdate?.(data);
    };
}

import { IConfigApi, Ii18n } from "@/contracts";
import { Button, Dialog, DialogActions, DialogContent, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import enceliumLogo from "../Encelium_Logo.svg";
import polarisLogo from "../polaris.svg";
import { useInjection } from "../UseInjection";
interface IAboutDialogProps {
    open: boolean;
    onClose(): void;
}

export const AboutDialog = (props: IAboutDialogProps) => {
    const i18n = useInjection(Ii18n);
    const configApi = useInjection(IConfigApi);
    const [ssuVersion, setSsuVersion] = useState<string>();

    useEffect(() => {
        (async () => {
            const v = await configApi.getVersion();
            setSsuVersion(v);
        })();
    }, []);

    return (
        <Dialog maxWidth={false} fullWidth open={props.open} onClose={props.onClose} style={{ maxWidth: 800, margin: "auto" }}>
            <DialogContent>
                <img src={polarisLogo} style={{ width: "30%", marginBottom: 30 }} />

                <Typography variant="h5" style={{ fontWeight: 500, borderBottom: "1px solid black", marginBottom: 30 }}>{i18n.t("Facman")} {i18n.t("version")} {BUILD_VER}
                    &emsp;{ssuVersion && <Typography variant="caption">(SSU&nbsp;{i18n.t("version")}&nbsp;{ssuVersion})</Typography>}
                </Typography>

                <Typography variant="h6" style={{ marginBottom: 30 }}>
                    <Link target="_blank" href={"https://www.encelium.com"} style={{ color: "inherit" }}>www.encelium.com</Link>
                </Typography>

                <Typography style={{ marginBottom: 30, overflowWrap: "anywhere" }}>
                    {i18n.t("license-text")}
                    <Link target="_blank" href={i18n.t("license-link")} style={{ color: "inherit" }}>{i18n.t("license-link")}</Link>
                </Typography>

                <Typography style={{ fontSize: "0.6rem" }}>{i18n.t("copyright-text")}</Typography>
                <Typography style={{ fontSize: "0.6rem" }}>{i18n.t("legal-text")}</Typography>

            </DialogContent>
            <DialogActions>
                <img src={enceliumLogo} style={{ width: "50%", marginLeft: 16, marginRight: "auto" }} />
                <Button onClick={props.onClose}>{i18n.t("close")}</Button>
            </DialogActions>
        </Dialog >
    )
}

import { IAccountUpdateInfo, IAuthenticationService, IServiceResult, IBimEventsService, Ii18n, EventOptions } from "@/contracts";
import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { computed } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { EditableField, FieldValidator } from "../EditableField";
import { required } from "../FieldValidators";
import { PasswordBox } from "../PasswordBox";
import { useInjection } from "../UseInjection";

interface IManageAccountDialogProps {
    open: boolean;
    forcePasswordChange?: boolean;
    onClose(cancelled: boolean): void;
}

export const ManageAccountDialog = observer((props: IManageAccountDialogProps) => {
    const t = useInjection(Ii18n).t;
    const authSvc = useInjection(IAuthenticationService);
    const eventSvc = useInjection(IBimEventsService);

    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [serverErrors, setServerErrors] = useState<string[]>([]);

    const [open, setOpen] = useState<boolean>(false);
    const [changePassword, setChangePassword] = useState<boolean>(false);
    const [forcePasswordChange, setForcePasswordChange] = useState<boolean>(false);
    const [eventOptions, setEventOptions] = useState<EventOptions | undefined>(undefined);

    const canSave = computed(() => {
        return isChanged &&
            (!changePassword || (dataFields.userName.isValid &&
            dataFields.password.isValid &&
            dataFields.confirmPassword.isValid));
    });

    const passwordVal: FieldValidator<string> = (value) => {
        const minLength = (forcePasswordChange) ? 8 : 0;
        return (value.length === minLength || value.length >= 8) ? undefined : t("Must be at least 8 characters long");
    }

    const confirmVal: FieldValidator<string> = (value) => value === dataFields.password.value ? undefined : t("Passwords must match");

    const dataFields = useMemo(() => {
        return ({
            userName: new EditableField(authSvc.authenticatedUserInfo!.name, required),
            oldPassword: new EditableField("", required),
            password: new EditableField("", passwordVal),
            confirmPassword: new EditableField("", confirmVal)
        });
    }, []);

    useEffect(() => {
        setOpen(props.open);
        setForcePasswordChange(props.forcePasswordChange ? true : false);
        if(props.forcePasswordChange)
            setChangePassword(true);
    }, []);

    useEffect(() => {
        (async () => {
            const evt = await eventSvc.getUserEmailEventTags(authSvc.authenticatedUserInfo!.id);
            setEventOptions(evt);
        })();

    }, [dataFields.userName])

    const title = forcePasswordChange ? t("Change password") : t("Manage account");
    const newPwdHelperText = getNewPasswordHelperText();

    return (
        <Dialog maxWidth="xs" fullWidth open={open} onClose={handleCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <form style={{ display: "flex", flexDirection: "column" }}>

                    {serverErrors.length > 0 && <>
                        {serverErrors.map(x => <Typography key={x} color="error" variant="body2" display="block">{x}</Typography>)}
                        <br />
                    </>}

                    {!forcePasswordChange &&
                        <TextField autoFocus id="userName" label={t("Name")} required autoComplete="name" error={!dataFields.userName.isValid} helperText={dataFields.userName.validationMessage}
                            value={dataFields.userName.value} sx={{ mb: 1 }} onChange={onChange} />
                    }

                    <PasswordBox name="oldPassword" autoFocus={forcePasswordChange} autoComplete="current-password" required
                            label={t("Current password")} password={dataFields.oldPassword.value} onChange={onChange} />

                    {!changePassword &&
                        <Button variant="outlined" style={{ marginRight: 'auto', marginBottom: "20px" }} onClick={() => { setChangePassword(true) }}>
                            {t("change password")}
                        </Button>
                    }

                    {changePassword && <>
                        <PasswordBox name="password" autoComplete="new-password" required={forcePasswordChange}
                            label={t("New password")} isValid={dataFields.password.isValid} helperText={newPwdHelperText}
                            password={dataFields.password.value} onChange={onChange} />

                        <Collapse in={dataFields.password.value !== ""} style={{ minHeight: "inherit" }}>
                            <PasswordBox name="confirmPassword" autoComplete="new-password"
                                label={t("Confirm password")}
                                isValid={dataFields.confirmPassword.isValid} helperText={dataFields.confirmPassword.validationMessage}
                                password={dataFields.confirmPassword.value} onChange={onChange} />
                        </Collapse>
                        </>
                    }

                    {/* {!forcePasswordChange && 
                        <Options userEmail={authSvc.authenticatedUserInfo!.email} alarmsEnabled={authSvc.authenticatedUserInfo!.alarmsEnabled} 
                            onOptionsChange={handleOptionsChange} eventOptions={eventOptions!} />
                        } */}
                </form>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>{t("cancel")}</Button>
                <Button color="primary" disabled={!canSave.get()} onClick={handleSave}>{t("Ok")}</Button>
            </DialogActions>
        </Dialog>
    );

    function getNewPasswordHelperText() {
        let text = dataFields.password.validationMessage;
        if (!text && dataFields.password.value.length === 0 && !forcePasswordChange) {
            text = "Leave blank to keep current password";
        }
        return text;
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value: any = (e.target.type === "checkbox") ? e.target.checked : e.target.value;

        const val = dataFields[(e.target.id as keyof typeof dataFields)] as EditableField<typeof value>;
        val.value = value;

        setIsChanged(true);
    }

    function handleCancel(e: React.MouseEvent) {
        props.onClose(true);
    }

    async function handleSave(e: React.MouseEvent) {
        const data: IAccountUpdateInfo = {
            name: dataFields.userName.value,
            currentPassword: dataFields.oldPassword.value,
            newPassword: dataFields.password.value,
            newPasswordConfirm: dataFields.confirmPassword.value
        };

        const result = await authSvc.updateAccount(data);

        if (result.status >= 200 && result.status < 300 && eventOptions) {
            if (!await eventSvc.setUserEmailEventTags(authSvc.authenticatedUserInfo!.id, eventOptions)) {
                result.status = 400;
                result.errors.errors = { "Error": ["Failed to save events"] }
            }
        }

        const errors = updateServerErrors(result);

        if (errors.length === 0) {
            props.onClose(false);
        }
    }

    function updateServerErrors(result: IServiceResult) : string[] {
        const errors = result.errors.errors ? Object.keys(result.errors.errors)
            .flatMap(k => result.errors.errors[k])
            .map(err => t(err)) : [""];

        if (result.status >= 500) { errors.push(t("Network error")); }
        if(result.status === 401 || result.status === 403)  { errors.push(t("Unauthorized access")); }

        setServerErrors(errors);

        return errors;
    }

    // async function handleOptionsChange(isAlarmChecked: boolean, options: EventOptions) {
    //     setEventOptions(options);
    //     setIsChanged(true);
    // }
});

export default ManageAccountDialog;

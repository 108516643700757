import { BimException } from "@/contracts";
import { EventCategories, IBimEventItem, IBimEventConfiguration, IBimEventConfigurationUserConfigurationTags, EventOptions, IBimEventConfigurationUserConfigurationEvents, EventCadence, defaultCadenceValues, IBimEventConfigurationUserConfigurationCadence } from "@/contracts/IBimEventsService";
import { BimLogLevels, IBimLogEntry, LogSecurityLevels, LogSources } from "@/contracts/IBimLogsService";
import { authenticatedFetch, fetchJson } from "../AuthenticatedFetch";
import ServicesLocations from "./ServicesLocations";

export enum GetLogOptionsDirections { None, ForwardInclusive, ForwardExclusive, BackwardInclusive, BackwardExclusive }

export interface IGetLogsOptions {
    startDateTime?: string;
    startSequence?: number;
    endDateTime?: string;
    endSequence?: number;

    sourceList?: LogSources[];
    securityLevelList?: LogSecurityLevels[];
    logLevelList?: BimLogLevels[];
    messageList?: string[];
    direction?: GetLogOptionsDirections;

    maxResultCount?: number;
}

export interface IGetEventsOptions {
    startDateTime?: string;
    startSequence?: number;
    endDateTime?: string;
    endSequence?: number;
    direction?: GetLogOptionsDirections;

    nodeIdList?: string[];
    eventCategoryList?: EventCategories[];
    messageList?: string[];
    includeComments?: boolean;

    maxResultCount?: number;
}

export async function sendTestEmail(orgId: string, address: string): Promise<boolean> {
    const payload = {
        toAddress: address,
        toName: address,
        subject: "Encelium X Test Email",
        body: "Verifying email setup"
    };

    const query = new URL(`organization/${orgId}/email/Configuration/test`, ServicesLocations.events);
    try {
        const response = await authenticatedFetch(query.href, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(payload)
        });

        if (response.ok) {
            return true;
        }
    } catch { }

    return false;
}

export async function sendOathCode(orgId: string, code: string): Promise<boolean> {
    const payload = {
        AuthorizationCode: code
    };

    const query = new URL(`organization/${orgId}/email/Configuration`, ServicesLocations.events);
    try {
        const response = await authenticatedFetch(query.href, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(payload)
        });

        if (response.ok) {
            return true;
        }
    } catch { }

    return false;
}

export async function getOathConfiguration(orgId: string): Promise<boolean> {
    const query = new URL(`organization/${orgId}/email/Configuration`, ServicesLocations.events);
    try {
        const response = await fetchJson(query);

        return response.value.isConfigured;
    } catch { }

    return false;
}

export async function clearOathConfiguration(orgId: string): Promise<boolean> {
    const query = new URL(`organization/${orgId}/email/Configuration`, ServicesLocations.events);
    try {
        const response = await authenticatedFetch(query.href, {
            method: "DELETE"
        });

        if (response.ok) {
            return true;
        }
    } catch { }

    return false;
}

export async function getLogs(orgId: string, options: IGetLogsOptions): Promise<IBimLogEntry[]> {
    const query = new URL(`organization/${orgId}/logs`, ServicesLocations.events);

    for (const [key, value] of Object.entries(options)) {
        if (value !== undefined) {
            query.searchParams.append(key, value);
        }
    }

    const response = await fetchJson(query);

    return response.values;
}

export async function getEvents(orgId: string, systemId: string, options: IGetEventsOptions): Promise<IBimEventItem[]> {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/events`, ServicesLocations.events);

    for (const [key, value] of Object.entries(options)) {
        if (value !== undefined) {
            query.searchParams.append(key, value);
        }
    }

    const response = await fetchJson(query);

    return response.values;
}

export async function getEvent(orgId: string, systemId: string, eventId: string): Promise<IBimEventItem> {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/events/${eventId}`, ServicesLocations.events);

    const response = await fetchJson(query);

    return response.value;
}

export async function addComment(orgId: string, systemId: string, eventId: string, userId: string, comment: string): Promise<IBimEventItem> {

    let ex: BimException | undefined;

    try {
        const query = new URL(`organization/${orgId}/encsystem/${systemId}/events/${eventId}`, ServicesLocations.events);

        const response = await authenticatedFetch(query.href, {
            method: "PATCH",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                comments: [{
                    text: comment,
                    type: "UserComment",
                    userId: userId,
                    timestamp: (new Date()).toISOString()
                }]
            })
        });

        if (response.ok) {
            const updatedEvent = await response.json();
            return updatedEvent.value;
        }
        ex = new BimException(response.status, response.statusText);

    } catch (err) {
        ex = new BimException(0, err.message)
    }

    throw ex;
}

export async function getEventsConfiguration(orgId: string, systemId: string): Promise<IBimEventConfiguration | undefined > {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/events/configuration`, ServicesLocations.events);
    try {
    const response = await fetchJson(query);

    return response.value;
    } catch {}

    return undefined;
}

export async function getEventTags(userId: string, orgId: string, systemId:string): Promise<EventOptions | undefined > {
    const options: EventOptions = { Tags: [], Events: [], Cadence: {...defaultCadenceValues} };
    try{
      const eventConfig =  await getEventsConfiguration(orgId , systemId);

      eventConfig!.users.map( user => {
        if(user.userId === userId) {
            user.configuration.tags.map(tagName => { options.Tags.push(tagName.tag); })
            user.configuration.events.map(event => { options.Events.push(event.eventType); })
            if(user.configuration.cadence)
                options.Cadence = user.configuration.cadence as EventCadence;
        }
      });

      return options;
    } catch {}

    return undefined;

}

export async function setEventTags(userId: string, options: EventOptions, type: string, enabled: boolean, orgId: string, systemId:string): Promise<IBimEventConfiguration | undefined > {

    try {
        const query = new URL(`organization/${orgId}/encsystem/${systemId}/events/configuration`, ServicesLocations.events);
        
        const tags:IBimEventConfigurationUserConfigurationTags[] = [];

        options.Tags.map(tag => {
            const ttag:IBimEventConfigurationUserConfigurationTags = {
                tag: tag,
                notifications: [
                    {
                        type: type,
                        enabled: enabled
                    }
                ]
            };
            tags.push(ttag);
        });

        const events:IBimEventConfigurationUserConfigurationEvents[] = [];

        options.Events.map(event => {
            const ttag:IBimEventConfigurationUserConfigurationEvents = {
                eventType: event,
                notifications: [
                    {
                        type: type,
                        enabled: enabled
                    }
                ]
            };
            events.push(ttag);
        });

        const cadence: IBimEventConfigurationUserConfigurationCadence = {
            ...options.Cadence
        }

        const response = await authenticatedFetch(query.href, {
            method: "PATCH",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                    "users": [
                        {
                            "configuration": {
                                "cadence": cadence,
                                "tags": tags,
                                "events": events
                            },
                            "userId": userId
                        }
                    ]
                })
        });

        if (response.ok) {
            const updatedEvent = await response.json();
            return updatedEvent.value;
        }

    } catch {}

    return undefined;
}


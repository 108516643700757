import ServicesLocations from "../ServicesLocations";
import { BimException, ILiveUpdate } from "@/contracts";
import { StateMachine } from "../../StateMachine";
import { LiveSocket } from "./LiveSocket";
import { VersionMap } from "./VersionMap";

type States = "Start" | "Connecting" | "Connected" | "Failed" | "End";
type Transitions = "Connect" | "ConnectOk" | "Error" | "End";

const pingGracePeriod = 2000;

interface ISubInfo {
    orgId: string;
    systemId: string;
    alarms?: boolean;
    onUpdate: (data?: ILiveUpdate) => void;
    data: any[];
}

interface ISubData {
    query: string;
    payload: string;
    onUpdate: (data?: ILiveUpdate) => void;
}

export class Subscription {
    private socket = new LiveSocket();
    private versionMap: VersionMap = new VersionMap();
    private lastMessageTime = 0;
    private pingIntervalMs = 20000;
    private pingTimer: any = 0;

    private subData?: ISubData;

    private machine = new StateMachine<States, Transitions>("Start", {
        Start: {
            Connect: "Connecting"
        },
        Connecting: {
            onEnter: () => this.onConnecting(),
            ConnectOk: "Connected",
            End: "End",
            Error: "Failed"
        },
        Connected: {
            onEnter: () => this.onConnected(),
            Error: "Failed",
            End: "End"
        },
        Failed: {
            onEnter: () => this.onSocketFail(),
            Connect: "Connecting",
            End: "End"
        },
        End: {
            onEnter: () => this.onEnd()
        }
    });

    constructor(subInfo: ISubInfo) {
        let endPoint = "devices";
        let propertyName = "nodes";
        
        if(subInfo.alarms) {
            endPoint = "services";
            propertyName = "services";
        }

        const url = new URL(`organization/${subInfo.orgId}/encsystem/${subInfo.systemId}/${endPoint}`, ServicesLocations.live);

        this.subData = {
            query: url.toString(), 
            payload: JSON.stringify({ [propertyName]: subInfo.data }),
            onUpdate: subInfo.onUpdate
        };
        
        this.subscribe();
    }

    unsubscribe() {
        this.machine.transition("End");
    }

    private subscribe() {
        this.machine.transition("Connect");
    }

    private onConnecting = async () => {
        try {
            const response = await fetch(this.subData!.query, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: this.subData!.payload
            });

            if (!response.ok) {
                const error = await response.text();
                throw new BimException(response.status, error);
            }
            const responseJson = await response.json();
            if (!responseJson.wssLink) { throw new BimException(0, "Invalid response"); }

            await this.socket.connect(responseJson.wssLink, this.onLiveSocketUpdate);
            this.machine.transition("ConnectOk");
        } catch {
            this.machine.transition("Error");
        }
    };

    private onConnected = async () => {
        this.pingTimer = setInterval(this.onPingCheck, this.pingIntervalMs / 2);
    };

    private onSocketFail = async () => {
        this.closeWssConnection();
        this.subData?.onUpdate(undefined);
        await new Promise(resolve => setTimeout(resolve, this.pingIntervalMs / 2));

        this.subscribe();
    };

    private onEnd = async () => {
        this.closeWssConnection();
        this.subData = undefined;
    };

    private closeWssConnection() {
        this.socket.disconnect();
        this.versionMap.reset();
        this.lastMessageTime = 0;
        clearInterval(this.pingTimer);
    }

    private onLiveSocketUpdate = (data: ILiveUpdate | null) => {

        if (!data) {
            this.machine.transition("Error");
            return;
        }
        const attribute = this.getStateAttribute(data.state);
        if (attribute) {
            this.setLastMessageTime(data.state["pingIntervalMs"]);

            if (this.versionMap.isLatest(data.nodeId, attribute, data.version)) {
                this.subData?.onUpdate(data);
            }
        }
    };

    private setLastMessageTime(pingIntervalMs?: number) {
        this.lastMessageTime = Date.now();
        if (pingIntervalMs && pingIntervalMs !== this.pingIntervalMs) {
            this.pingIntervalMs = pingIntervalMs;
            clearInterval(this.pingTimer);
            this.pingTimer = setInterval(this.onPingCheck, this.pingIntervalMs / 2);
        }
    }

    private getStateAttribute(state: object | undefined): string | undefined {
        let attribute;
        if (state) {
            for (const a in state) {
                if (Object.prototype.hasOwnProperty.call(state, a)) {
                    attribute = a;
                    break;
                }
            }
        }
        return attribute;
    }

    private onPingCheck = () => {
        const timeSinceLastMessage = Date.now() - this.lastMessageTime;
        const pingOK = timeSinceLastMessage <= this.pingIntervalMs + pingGracePeriod;

        if (!pingOK) {
            this.machine.transition("Error");
        }
    };
}

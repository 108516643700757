
import { BimException, floorItemNodeTypes, IAuthenticationService, IBaseBimObject as IBaseBimObject, IBaseNode, IBimFloorObject, IBimObject, IBimOrganization, IBimSystem, IBimZone, IConfigApi, IOrganizationNode, IRelation, isBimZone, ITreeNode, NodeTypes, renamableItemNodeTypes, diffFloorItemNodeTypes, IBimManager } from "@/contracts";
import { inject, injectable } from "inversify";
import { groupBy } from "lodash-es";
import { authenticatedFetch, fetchJson } from "..";
import { BimOrganization, BimSystem } from "./BimOther";
import { itemFactory } from "./ItemFactory";
import { naturalCollator } from "./NaturalCollator";
import ServicesLocations from "./ServicesLocations";

interface IOrgRelations {
    relations: IRelation[];
    nodes: IBaseNode[];
}

interface INodeResult {
    pagination: { pageSize: number, pageIndex: number },
    values: IBaseNode[];
}

@injectable()
export class ConfigApi implements IConfigApi {
    constructor(@inject(IAuthenticationService) private authSvc: IAuthenticationService) {
    }

    async getVersion(): Promise<string | undefined> {
        try {
            const query = new URL(`organization/locator`, ServicesLocations.config);
            const result = await fetchJson(query);
            return result.version;
        } catch { }
        return undefined;
    }

    async getOrganization(orgId: string): Promise<IBimOrganization | undefined> {

        try {
            const [org, systems] = await Promise.all([this.getOrganizationData(orgId), this.getOrganizationStructure(orgId)]);
            org.systems = systems;
            return org;
        } catch (err) {
            if (err instanceof BimException) {
                throw err;
            } else {
                throw new BimException(0, err.message);
            }
        }
    }

    async getOrganizationNode(orgId: string): Promise<IOrganizationNode> {
        const getOrganizationQuery = new URL(`organization/${orgId}`, ServicesLocations.config);

        return await fetchJson(getOrganizationQuery);
    }

    async updateOrganizationNode(org: IOrganizationNode): Promise<void> {
        const query = new URL(`organization/${org.id}`, ServicesLocations.config);
        const result = await fetchJson(query) as IBaseNode;

        const newProps = { ...result, ...org };

        await authenticatedFetch(query.href, {
            method: "PUT",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(newProps)
        });
    }

    async getNodes(orgId: string, systemId: string, nodeTypes: NodeTypes[]) {
        const query = new URL(`organization/${orgId}/encsystem/${systemId}/nodes`, ServicesLocations.config);
        query.searchParams.append("pageIndex", "0");
        query.searchParams.append("pageSize", "65535");
        query.searchParams.append("targetNodeTypes", nodeTypes.join(","));
        const result = await fetchJson(query) as INodeResult;

        return result.values.map(x => ({ id: x.id, type: x.nodeType, name: x.caption || x.name, nodeSystemType: x.nodeSystemType || null})) as IBimObject[];
    }

    async getNodeById(orgId: string, systemId: string, nodeTypes: NodeTypes, nodeId: string) {
        const query = new URL(`organization/${orgId}/encsystem/${systemId}/nodes/${nodeTypes}/${nodeId}`, ServicesLocations.config);
        return await fetchJson(query) as IBaseNode;
    }

    async updateNode<T extends IBimObject>(orgId: string, systemId: string, node: T, updates: Partial<T>): Promise<void> {
        if (!renamableItemNodeTypes.has(node.type)) {
            throw new BimException(0, "Unsupported node type");
        }

        const query = new URL(`organization/${orgId}/encsystem/${systemId}/nodes/${node.type}/${node.id}`, ServicesLocations.config);
        const result = await fetchJson(query) as IBaseNode;

        // Move "name" to "caption" because I didn't think of this 2 years ago
        // DON'T mutate updates param because the caller may still need it
        const fixedUpdates = { ...updates, caption: updates.name };
        delete fixedUpdates.name;
        const newProps = { ...result, ...fixedUpdates };

        await authenticatedFetch(query.href, {
            method: "PUT",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(newProps)
        });
    }

    async searchNodes(orgId: string, systemId: string, searchText: string) {
        const query = new URL(`organization/${orgId}/encsystem/${systemId}/nodes`, ServicesLocations.config);
        query.searchParams.append("pageIndex", "0");
        query.searchParams.append("pageSize", "100");
        query.searchParams.append("targetNodeTypes", ["Floor", "OrganizationalArea", "Manager", "Luminaire", "TunableWhiteLuminaire", "WalcLuminaire", "WslcLuminaire", "PlugLoad", "EmergencyLuminaire", "Keypad", "OccupancySensor", "PhotoSensor", "Schedule", "Calendar", "PartitionWall", "Ballast"].join(","));
        query.searchParams.append("searchStrings", searchText);
        query.searchParams.append("searchPropertyTypes", "name, caption, refAddress, nodeType");
        const result = await fetchJson(query) as INodeResult;

        return result.values.map(x => ({ id: x.id, type: x.nodeType, name: x.caption || x.name })) as IBimObject[];
    }

    async getNodeLocation(orgId: string, systemId: string, node: IBimObject): Promise<{ buildingId?: string, floorId?: string }> {
        const query = new URL(`organization/${orgId}/encsystem/${systemId}/nodes/${node.type}/${node.id}/relations`, ServicesLocations.config);
        query.searchParams.append("direction", "in");
        query.searchParams.append("relationTypes", "Contains");
        query.searchParams.append("targetNodeTypes", "Floor,Building");
        const result = await fetchJson(query) as IOrgRelations;
        return {
            buildingId: result.nodes.find(x => x.nodeType === "Building")?.id,
            floorId: result.nodes.find(x => x.nodeType === "Floor")?.id
        }
    }

    async getRelatedNodes(orgId: string, systemId: string, nodeType: string, nodeId: string, targetNodeTypes?: string, relationTypes?:string): Promise<IBaseNode[]> {
        const result = await this.queryRelations(orgId, systemId, nodeType, nodeId, targetNodeTypes, relationTypes);
        return result.nodes as IBaseNode[];
    }

    async getNodeManager(orgId: string, systemId: string, nodeType: string, nodeId: string): Promise<IBimManager | undefined> {
        const query = new URL(`organization/${orgId}/encsystem/${systemId}/nodes/${nodeType}/${nodeId}/relations`, ServicesLocations.config);
        query.searchParams.append("targetNodeTypes", `<Manager>`);
        query.searchParams.append("relationTypes", `Manages`);
        query.searchParams.append("direction", `in`);
        query.searchParams.append("depth", `1`);

        const result = await fetchJson(query) as IOrgRelations;
        if(result.nodes.length > 0)
            return result.nodes[0] as unknown as IBimManager;
        
            return undefined;
    }
    
    async getNodeRelationOnly(orgId: string, systemId: string, nodeType: string, nodeId: string, targetNodeTypes?: string, relationTypes?:string): Promise<IRelation[]> {
        const query = new URL(`organization/${orgId}/encsystem/${systemId}/nodes/${nodeType}/${nodeId}/relations`, ServicesLocations.config);
        query.searchParams.append("targetNodeTypes", `${targetNodeTypes}`);
        query.searchParams.append("relationTypes", `${relationTypes}`);
        const result = await fetchJson(query) as IOrgRelations;
        return result.relations as IRelation[];
    }

    async getRelations(orgId: string, systemId: string, nodeType: string, nodeId: string, targetNodeTypes?: string, relationTypes?:string): Promise<IRelation[]> {
        const result = await this.queryRelations(orgId, systemId, nodeType, nodeId, targetNodeTypes, relationTypes);
        return result.relations as IRelation[];
    }

    async getDirectRelationsAndNodes(orgId: string, systemId: string, nodeType: string, nodeId: string, targetNodeTypes?: string, relationTypes?:string): Promise<{relations: IRelation[],nodes: IBaseNode[]}> {
        const result = await this.getRelationsAndNodes(orgId, systemId, nodeType, nodeId, targetNodeTypes, relationTypes, 1);
        return result;
    }

    async getRelationsAndNodes(orgId: string, systemId: string, nodeType: string, nodeId: string, targetNodeTypes?: string, relationTypes?:string, depth?: number): Promise<IOrgRelations> {
        const result = await this.queryRelations(orgId, systemId, nodeType, nodeId, targetNodeTypes, relationTypes, depth);
        return result;
    }

    private async queryRelations(orgId: string, systemId: string, nodeType: string, nodeId: string, targetNodeTypes?: string, relationTypes?:string, depth?: number): Promise<IOrgRelations> {
        const query = new URL(`organization/${orgId}/encsystem/${systemId}/nodes/${nodeType}/${nodeId}/relations`, ServicesLocations.config);
        if(targetNodeTypes)
            query.searchParams.append("targetNodeTypes", `${targetNodeTypes}`);
        if(relationTypes)
            query.searchParams.append("relationTypes", `${relationTypes}`);
        if(depth)
            query.searchParams.append("depth", `${depth}`);

        return await fetchJson(query) as IOrgRelations;
    }

    async getExceptionParentEventId(orgId: string, systemId: string, occurrenceId: string){
        const scheduleExceptionOccurenceId = await this.getRelations(orgId, systemId, "Schedule", occurrenceId, "ScheduleExceptionOccurence");
        if(scheduleExceptionOccurenceId[0]?.outNodeId) {
            const exceptionParentRelation = await this.getRelations(orgId, systemId, "Schedule", scheduleExceptionOccurenceId[0].outNodeId, "ScheduleEvent", "Contains");
            if(exceptionParentRelation[0].outNodeType === "ScheduleEvent") return exceptionParentRelation[0].outNodeId;
        }
        return undefined;
    }

    async getZones(orgId: string, systemId: string) {
        return this.getNodes(orgId, systemId, ["OrganizationalArea", "Building", "Floor"]);
    }

    async getFloor(orgId: string, systemId: string, floorId: string) {

        const additionalTypes: NodeTypes[] = this.authSvc.isAuthenticatedFor("orgScheduleCRUD") ? ["Schedule"] : [];
        const targetTypes: NodeTypes[] = [
            "Ballast",
            "EmergencyInverter",
            "GroupedBallast",
            ...additionalTypes,
            ...floorItemNodeTypes.keys(),
        ];

        const query = new URL(`organization/${orgId}/encsystem/${systemId}/nodes/floor/${floorId}/relations`, ServicesLocations.config);
        query.searchParams.append("direction", "out");
        query.searchParams.append("relationTypes", "Contains,Controls");
        // Search for floor items AND anything else they can be associated with (Currently just Schedule)
        // createFloorItems will NOT create BIM objects for these extras, but we need the ids in the relations list for initialization
        query.searchParams.append("targetNodeTypes", targetTypes.join(","));
        const result = await fetchJson(query) as IOrgRelations;
        const updatedResult = await this.getOffFloorRelatedItems(orgId, systemId, result);

        const items = this.createFloorItems(floorId, updatedResult);

        return { id: floorId, ...items };
    }

    // find items that are not on this floor but are needed as part of this floors items
    async getOffFloorRelatedItems(orgId: string, systemId: string, relations: IOrgRelations) : Promise<IOrgRelations> {
        // Grouped Ballasts may have parent on different floor
        const groupedBallasts = relations.nodes.filter(n => n.nodeType == "GroupedBallast");
        // Find GroupedBallasts that don't have relationships (no parent on this floor)
        const missingRelations = groupedBallasts.filter(b => !relations.relations.some(r => r.relationType == "Controls" && r.inNodeType == "GroupedBallast" && r.inNodeId == b.id));
        // Get the realtions we are missing
        const newItems = await Promise.all(missingRelations.map(r => this.getDirectRelationsAndNodes(orgId, systemId, "GroupedBallast", r.id, undefined, "Controls")));
        const newNodes = groupBy(newItems.flatMap(x => x.nodes), x => x.id);
        const newRelations = groupBy(newItems.flatMap(x => x.relations), x => x.id);
        // filter down to only single instances of the parents

        for (const node in newNodes) {
            relations.nodes.push(newNodes[node][0]);
        }

        for (const relation in newRelations) {
            relations.relations.push(newRelations[relation][0]);
        }

        return relations;
    }

    createFloorItems(floorId: string, data: IOrgRelations) {
        const allItems = this.createItems(data.nodes, data.relations);
        const idsOnFloor = new Set(data.relations.filter(x => x.relationType === "Contains" && (x.outNodeType === "Floor" || x.outNodeType === "OrganizationalArea")).map(x => x.inNodeId));

        const items: IBimFloorObject[] = [];
        const areas: IBimZone[] = [];
        const diffFloorItems: IBimFloorObject[] = [];

        for (const item of allItems.values()) {
            if (idsOnFloor.has(item.id) && floorItemNodeTypes.has(item.type)) {
                if (isBimZone(item)) {
                    areas.push(item);
                } else {
                    items.push(item);
                }
            }
            else if (!idsOnFloor.has(item.id) && diffFloorItemNodeTypes.has(item.type)) {
                diffFloorItems.push(item);
            }
        }

        return {
            areas, diffFloorItems, items, allItems
        }
    }

    async getZoneItemIds(orgId: string, systemId: string, zoneId: string, types?: NodeTypes[]): Promise<string[]> {
        const itemQuery = new URL(`organization/${orgId}/encsystem/${systemId}/nodes/OrganizationalArea/${zoneId}/relations`, ServicesLocations.config);
        const subZoneQuery = new URL("", itemQuery);

        const targetNodeTypes = types ? types.join(",") : "Schedule,Luminaire,TunableWhiteLuminaire,WalcLuminaire,WslcLuminaire,EmergencyLuminaire,PlugLoad,Keypad,OccupancySensor,PhotoSensor,Manager,PartitionWall";

        itemQuery.searchParams.set("relationTypes", "Controls,Manages,Contains");
        itemQuery.searchParams.set("targetNodeTypes", targetNodeTypes);

        subZoneQuery.searchParams.set("relationTypes", "Controls");
        subZoneQuery.searchParams.set("direction", "out");
        subZoneQuery.searchParams.set("targetNodeTypes", "OrganizationalArea");

        const [items, subZones] = await Promise.all([fetchJson(itemQuery), fetchJson(subZoneQuery)]);

        return items.nodes.concat(subZones.nodes).map((x: IBaseNode) => x.id);
    }

    async getZoneNodesForSchedule(orgId: string, systemId: string, scheduleId: string): Promise<IBaseNode[]> {
        const query = new URL(`organization/${orgId}/encsystem/${systemId}/nodes/Schedule/${scheduleId}/relations`, ServicesLocations.config);
        query.searchParams.append("relationTypes", "Controls");

        const result = await fetchJson(query);
        return result.nodes.filter((x: IBaseNode) => x.nodeType === "OrganizationalArea");
    }

    async getZoneTree(orgId: string): Promise<ITreeNode> {
        const org: ITreeNode = { id: orgId, name: "Organization", type: "Organization", children: [] }
        const systems = await this.getSystemNodes(orgId);
        const tree = await Promise.all(systems.map(x => new BimSystem(x)).map(x => this.getSystemTree(org, x.id, x)));

        org.children = tree.sort((a: ITreeNode, b: ITreeNode) => naturalCollator.compare(a.name, b.name));
        return org;
    }

    async deleteSystem(orgId: string, systemId: string): Promise<void> {
        const configQuery = new URL(`organization/${orgId}/encsystem/${systemId}`, ServicesLocations.config);
        const commQuery = new URL(`organization/${orgId}/encsystem/${systemId}`, ServicesLocations.commAddress);
        const resourceQuery = new URL(`resources/${orgId}/${systemId}`, ServicesLocations.resource);
        try {
            await authenticatedFetch(resourceQuery.href, { method: "DELETE" });
            await authenticatedFetch(configQuery.href, { method: "DELETE" });
            await authenticatedFetch(commQuery.href, { method: "DELETE" });
            // There is mno error handling because there is nothing we can do if it fails :(
        } catch { }
    }

    private async getSystemTree(org: ITreeNode, systemId: string, node: IBaseBimObject): Promise<ITreeNode> {
        const query = new URL(`organization/${org.id}/encsystem/${systemId}/nodes/${node.type}/${node.id}/relations`, ServicesLocations.config);
        query.searchParams.append("direction", "out");
        query.searchParams.append("targetNodeTypes", "Building,Floor,OrganizationalArea");
        query.searchParams.append("relationTypes", "Contains");
        const result = await fetchJson(query) as IOrgRelations;

        const parent = this.getChildren(node, result.relations, result.nodes);
        parent.parent = org;
        return parent;
    }

    private getChildren(node: IBaseBimObject, allRelations: IRelation[], nodes: IBaseNode[]) {
        const relations = allRelations.filter(x => x.outNodeId === node.id);
        const childNodes = nodes
            .filter(x => relations.find(r => r.inNodeId === x.id))
            .map(x => ({ id: x.id, type: x.nodeType, name: x.caption || x.name, parent: node }))
            .sort((a, b) => naturalCollator.compare(a.name, b.name));

        const childTree = childNodes.map(x => this.getChildren(x, allRelations, nodes));

        const parent = node as unknown as ITreeNode;
        parent.children = childTree;
        return parent;
    }

    private async getOrganizationData(orgId: string): Promise<IBimOrganization> {
        const orgNode = await this.getOrganizationNode(orgId);
        const org = new BimOrganization(orgNode);

        return org;
    }

    getSystemNodes(orgId: string): Promise<IBaseNode[]> {
        const getSystemsQuery = new URL(`organization/${orgId}/encsystem`, ServicesLocations.config);
        return fetchJson(getSystemsQuery);
    }

    private async getOrganizationStructure(orgId: string): Promise<IBimSystem[]> {
        const orgSystems = await this.getSystemNodes(orgId);
        const requests = orgSystems.map(system => this.getSystem(orgId, system));
        const systems = await Promise.all(requests);

        return systems.filter((x: IBimSystem | null) => x !== null && x.buildings.length > 0)
            .sort((a: IBimSystem, b: IBimSystem) => naturalCollator.compare(a.name, b.name)) as IBimSystem[];
    }


    private async getSystem(orgId: string, system: IBaseNode) {
        try {

            const additionalTypes = this.authSvc.isAuthenticatedFor("orgScheduleCRUD")
                ? ",Schedule,Calendar"
                : "";

            const query = new URL(`organization/${orgId}/encsystem/${system.id}/nodes/encsystem/${system.id}/relations`, ServicesLocations.config);
            query.searchParams.append("direction", "out");
            query.searchParams.append("relationTypes", "Contains");
            query.searchParams.append("depth", "3");
            query.searchParams.append("targetNodeTypes", "Building,Floor,Manager" + additionalTypes);
            const data = await fetchJson(query);

            const allItems = this.createItems([system, ...data.nodes], data.relations);
            return allItems.get(system.id) as unknown as IBimSystem;
        } catch { }
        return null
    }

    private createItems(nodes: IBaseNode[], relations: IRelation[]) {
        const outRelations = groupBy(relations, x => x.outNodeId);
        const inRelations = groupBy(relations, x => x.inNodeId);

        const allItems = new Map(nodes.map(x => itemFactory(x)).filter(x => x !== undefined).map(x => [x!.id, x!]));
        allItems.forEach(item => item.init(outRelations, inRelations, allItems));
        return allItems;
    }

}



import { IDataHistoryApi, IEnergyData, IOccupancyData } from "@/contracts";
import { injectable } from "inversify";
import { fetchJson } from "..";
import ServicesLocations from "./ServicesLocations";

@injectable()
export class DataHistoryApi implements IDataHistoryApi {
    getEnergyData = async (orgId: string, systemId: string, nodeId: string, from: Date, to: Date, maxPoints = 1000): Promise<IEnergyData> => {
        const query = this.getQuery("energy", orgId, systemId, nodeId, from, to, maxPoints);
        query.searchParams.append("IncludeSavingsByStrategy", "true");
        query.searchParams.append("IncludeSummary", "true");

        const result = await fetchJson(query);
        return result;
    }

    getOccupancyData = async (orgId: string, systemId: string, nodeId: string, from: Date, to: Date, maxPoints = 1000): Promise<IOccupancyData> => {
        const query = this.getQuery("occupancy", orgId, systemId, nodeId, from, to, maxPoints);
        const result = await fetchJson(query);
        return result;
    }

    private getQuery(type: "energy" | "occupancy", orgId: string, systemId: string, nodeId: string, from: Date, to: Date, maxPoints: number) {
        const fromUnix = Math.floor(from.getTime() / 1000);
        const toUnix = Math.floor(to.getTime() / 1000);

        const query = new URL(`organization/${orgId}/encsystem/${systemId}/${type}/${nodeId}`, ServicesLocations.dataHistory);
        query.searchParams.append("From", fromUnix.toString());
        query.searchParams.append("To", toUnix.toString());
        query.searchParams.append("MaxPoints", maxPoints.toString());

        return query;
    }
}
import { IBimScene, IBimZone } from "@/contracts";
import { observable, makeObservable } from "mobx";

export class BimScene implements IBimScene {
    id: string;
    alias: number;
    name: string;
    fadeTime: number;
    isDefault: boolean;
    @observable isCurrent: boolean;
    @observable isModified: boolean;

    constructor(scene: any, private zone: IBimZone) {
        makeObservable(this);
        this.fadeTime = scene.fadeTime;
        this.name = scene.name;
        this.id = scene.sceneId;
        this.isCurrent = false;
        this.isDefault = scene.sceneAlias === 0;
        this.isModified = false;
        this.alias = scene.sceneAlias;
    }

    update(currentSceneId: string, isCurrentSceneModified: boolean) {
        this.isCurrent = this.id === currentSceneId;
        this.isModified = this.isCurrent && isCurrentSceneModified;
    }

    setCurrent = () => {
        this.zone.setScene(this.id);
    }
}

import { ICollapsiableEvents } from "@/components/Events/Events";

export type LogSources = "None" | "ActionApi" | "AvInterface" | "BackupApi" | "CertificateAuthorityApi" | "CommAddrApi" | "ConfigApi" | "DataHistoryApi" | "Ecu" |
    "EventApi" | "FacilityManager" | "IdentityApi" | "Installer" | "LiveUpdate" | "ManagerStatus" | "Polaris" | "ResourceApi" | "ScheduleApi" | "System" | "Watchdog"

export enum LogSecurityLevels {
    None, Standard, Security
}

export type BimLogLevels = "Trace" | "Debug" | "Information" | "Warning" | "Error" | "Critical" | "None";

export interface IBimLogEntry extends ICollapsiableEvents {
    timestamp: string;
    sequence: number;
    id: string,
    logLevel: BimLogLevels;
    message: string;
    source: string;
}

export interface IBimLogsOptions {
    logLevels?: BimLogLevels[];
    filterText?: string;
}

export abstract class IBimLogsService {
    abstract isBusy: number;
    abstract items: IBimLogEntry[];

    abstract start(orgId: string, systemId: string, dateIso: string, sequence: number | undefined, options?: IBimLogsOptions): Promise<void>;
    abstract stop(): void;

    abstract getMoreLater(): Promise<void>;
    abstract getMoreEarlier(): Promise<void>;
}
import { AuthenticationStates, IAuthenticationService, Ii18n } from "@/contracts";
import { Alert, Box, Button, LinearProgress, TextField } from "@mui/material";
import { computed } from "mobx";
import { observer } from "mobx-react";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ManageAccountDialog from "./AccountMenu/ManageAccountDialog";
import BlockingSpinWait from "./BlockingSpinWait";
import { ConfirmDialog } from "./ConfirmDialog";
import { EditableField } from "./EditableField";
import { email, required } from "./FieldValidators";
import { PasswordBox } from "./PasswordBox";
import { useInjection } from "./UseInjection";

enum DialogStates {
    Unknown,
    NotAuthenticated,
    Authenticated,
    AuthenticatedPartial,
    PasswordChangeOK,
    PasswordChangeFail,
}

const PATH_AFTER_LOGIN = "/web";

const Login = observer((props: any) => {
    const i18n = useInjection(Ii18n);
    const authService = useInjection(IAuthenticationService);
    const navigate = useNavigate();
    const location = useLocation();
    const [dialogState, setDialogState] = useState(DialogStates.Unknown);

    useEffect(() => {
        authService.checkCurrentAuthenticatedState();
    }, []);

    useEffect(() => {
        switch (authService.authenticationState) {
            case AuthenticationStates.Unknown:
                setDialogState(DialogStates.Unknown);
                break;
            case AuthenticationStates.NotAuthenticated:
                setDialogState(DialogStates.NotAuthenticated);
                break;
            case AuthenticationStates.Authenticated:
                if (dialogState !== DialogStates.AuthenticatedPartial) {
                    setDialogState(DialogStates.Authenticated);
                }
                break;
            case AuthenticationStates.AuthenticatedPartial:
                setDialogState(DialogStates.AuthenticatedPartial);
                break;
        }

    }, [authService.authenticationState]);

    switch (dialogState) {
        case DialogStates.PasswordChangeOK:
            return <ConfirmDialog open message={i18n.t("Password changed")} onConfirm={handleConfirm} />
        case DialogStates.PasswordChangeFail:
            return <ConfirmDialog open message={i18n.t("Password change failed")} onConfirm={handleConfirm} />
        case DialogStates.Authenticated:
            return <Navigate to={location?.state?.from ?? PATH_AFTER_LOGIN} replace />;
        case DialogStates.AuthenticatedPartial:
            return <ManageAccountDialog open onClose={handleClose} forcePasswordChange />;
        default:
            return <LoginDialog {...props} />;
    }

    function handleClose() {

        const newState = (authService.authenticationState === AuthenticationStates.Authenticated) ? DialogStates.PasswordChangeOK : DialogStates.PasswordChangeFail;
        setDialogState(newState);
    }

    function handleConfirm() {
        if (authService.authenticationState !== AuthenticationStates.Authenticated) {
            navigate(FM_PUBLIC_PATH + "logout", { replace: true });
        }
        navigate({ pathname: location?.state?.from || PATH_AFTER_LOGIN }, { replace: true });
    }
});

const LoginDialog = observer(() => {
    const authService = useInjection(IAuthenticationService);
    const i18n = useInjection(Ii18n);

    const [isFirstTry, setIsFirstTry] = useState<boolean>(true);
    const dataFields = useMemo(() => ({
        email: new EditableField<string>("", email),
        password: new EditableField<string>("", required)
    }), []);

    const isFormValid = computed(() => {
        return dataFields.email.isValid && dataFields.password.isValid;
    });

    const errorMsg = computed(() => {
        if (isFirstTry || authService.authenticationState !== AuthenticationStates.NotAuthenticated) {
            return undefined;
        }

        let message: string;

        if (authService.errorInfo && i18n.exists(authService.errorInfo)) {
            message = i18n.t(authService.errorInfo)
        } else if (authService.errorInfo) {
            message = i18n.t("Unknown Error") + authService.errorInfo.substring(0, 100);
        } else {
            message = i18n.t("Unknown Error") + authService.errorCode
        }

        return message;
    });

    useEffect(() => {
        authService.checkCurrentAuthenticatedState();
    }, []);

    if (isFirstTry && authService.authenticationState === AuthenticationStates.Authenticating) {
        return <BlockingSpinWait open delay={250} />;
    }

    const t = i18n.t;

    return (<>
        <Box maxWidth="xs" sx={{ maxWidth: { xs: 'calc(100vw - 5px)', sm: 'calc(100% - 55px)' } }}>
            <form id="loginForm" onSubmit={handleLogin}>
                <fieldset style={{ border: "none" }} disabled={authService.authenticationState === AuthenticationStates.Authenticating}>
                    <TextField margin="normal" required fullWidth autoFocus type="email" autoComplete="username" spellCheck={false}
                        name="email" label={t("Email")}
                        InputLabelProps={{ shrink: true }}
                        value={dataFields.email.value}
                        onChange={handleChange} />

                    <PasswordBox name="password" autoComplete="current-password" label={t("Password")}
                        required shrinkLabel
                        password={dataFields.password.value}
                        onChange={handleChange} />
                    
                        {errorMsg.get() && <Alert severity="error">{errorMsg.get()}</Alert>}

                </fieldset>
            </form>

            <div style={{ position: "relative", height: 4 }}>
                {authService.authenticationState === AuthenticationStates.Authenticating &&
                    <LinearProgress style={{ position: "absolute", width: "100%", bottom: "5px" }} />
                }
            </div>
            <Button type="submit" form="loginForm" variant="contained" color="primary" disabled={!isFormValid.get() || authService.authenticationState === AuthenticationStates.Authenticating}>
                {t("Log in")}
            </Button>
        </Box>
    </>
    );

    function handleChange(e: ChangeEvent<any>) {
        const value: any = (e.target.type === "checkbox") ? e.target.checked : e.target.value;
        (dataFields[e.target.name as keyof typeof dataFields] as EditableField<typeof value>).value = value;
    }

    function handleLogin(e: React.FormEvent) {
        e.preventDefault();
        authService.login(dataFields.email.value, dataFields.password.value);
        setIsFirstTry(false);
    }
});

export default Login;

import { EventOptions, IAuthenticationService, IBimEventConfiguration, IBimEventItem, IBimEventsService, IBimEventsServiceOptions } from "@/contracts";
import { IBimLogEntry, IBimLogsOptions, IBimLogsService } from "@/contracts/IBimLogsService";
import { container } from "@/ioc";
import { injectable } from "inversify";
import { BimBaseEventsService } from "./BaseBimEventssService";
import { setEventTags, getEventsConfiguration, addComment, getEvents, getEvent, GetLogOptionsDirections, getLogs, sendOathCode, clearOathConfiguration as clearOathConfiguration, getOathConfiguration, sendTestEmail, getEventTags } from "./Bim/EventsApi";


@injectable()
export class BimLogsService extends BimBaseEventsService<IBimLogEntry, IBimLogsOptions> implements IBimLogsService {

    blockSize = 1000;

    protected getEvents(direction: GetLogOptionsDirections, timeAndSequence: Record<string, any>): Promise<IBimLogEntry[]> {

        let messageList: string[] | undefined;

        if (this.options?.filterText && this.options.filterText !== "") {
            messageList = this.options.filterText.split(",").map((x: string) => x.trim());
        }

        return getLogs(this.orgId, {
            direction: direction,
            maxResultCount: this.blockSize,
            logLevelList: this.options.logLevels,
            messageList: messageList,
            ...timeAndSequence
        });
    }

    protected getEvent(eventId: string): Promise<IBimLogEntry> {

        return Promise.resolve({} as IBimLogEntry);
    }
}


@injectable()
export class BimEventsService extends BimBaseEventsService<IBimEventItem, IBimEventsServiceOptions> implements IBimEventsService {

    blockSize = 1000;

    public async sendTestEmail(orgId: string, address: string): Promise<boolean> {
        return await sendTestEmail(orgId, address);
    }

    public async sendOathCode(orgId: string, code: string): Promise<boolean> {
        return await sendOathCode(orgId, code);
    }

    public async clearOathConfiguration(orgId: string): Promise<boolean> {
        return await clearOathConfiguration(orgId);
    }

    public async getOathConfiguration(orgId: string): Promise<boolean> {
        return await getOathConfiguration(orgId);
    }

    public async getEventsConfiguration(): Promise<IBimEventConfiguration | undefined > {
        return await getEventsConfiguration("00000000-0000-0000-0000-000000000001", "00000000-0000-0000-0000-000000000000");
    }
    
    public async getUserEmailEventTags(userId: string): Promise<EventOptions | undefined> {
        return await getEventTags(userId, "00000000-0000-0000-0000-000000000001", "00000000-0000-0000-0000-000000000000");
    }

    public async setUserEmailEventTags(userId: string, options: EventOptions): Promise<IBimEventConfiguration | undefined> {
        return await setEventTags(userId, options, "Email", true, "00000000-0000-0000-0000-000000000001", "00000000-0000-0000-0000-000000000000");
    }

    async addComment(orgId: string, systemId: string, eventId: string, comment: string): Promise<boolean> {

        const authSvc = container.get(IAuthenticationService);

        try {
            const updatedEvent = await addComment(orgId, systemId, eventId, authSvc.authenticatedUserInfo!.id, comment);

            // Fix bug in API
            updatedEvent.commentsCount = updatedEvent.comments.length;

            this.updateEvent(updatedEvent);

            return true;
        } catch { }
        return false;
    }

    protected getEvents(direction: GetLogOptionsDirections, timeAndSequence: Record<string, any>): Promise<IBimEventItem[]> {

        return getEvents(this.orgId, this.systemId, {
            direction: direction,
            maxResultCount: this.blockSize,
            ...timeAndSequence,
            ...this.options
        })

    }

    protected getEvent(eventId: string): Promise<IBimEventItem> {

        return getEvent(this.orgId, this.systemId, eventId);

    }
}

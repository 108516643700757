import "reflect-metadata"; // Required for inversify. Must be imported early
import React from "react";
import { createRoot } from 'react-dom/client';
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto-mono/400.css";
import "./defaults.css";
import { configure } from "mobx";
import { App } from "./components/App";

configure({ enforceActions: "never" });

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
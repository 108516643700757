import { CancelledException, ICancellationToken } from "@/contracts";

export class CancellationTokenSource {
    private _isCancellationRequested = false;
    get isCancellationRequested() { return this._isCancellationRequested; }

    throwIfCancelled() {
        if (this.isCancellationRequested) {
            throw new CancelledException();
        }
    }

    token = this as ICancellationToken;

    cancel(): void {
        this._isCancellationRequested = true;
    }
}


export abstract class Ii18n {

    abstract ready: Promise<boolean>;
    abstract language: string;
    abstract languages: string[];
    abstract t(key: string, options?: unknown | string): string;
    abstract exists: (key: string) => boolean
    abstract changeLanguage(language: string): void;

}

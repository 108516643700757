import { IEditableUserInfo } from ".";

// interface Record<string, string[] {
//     code: string;
//     description: string;
// }
export interface IServiceResult {
    id: string;
    status: number;
    errors: {
        errors: Record<string, string[]>;
    }
}

export abstract class IUsersService {
    abstract isBusy: number;
    abstract users: IEditableUserInfo[];

    abstract start(): Promise<void>;
    abstract stop(): void;
    abstract getNewUser(): IEditableUserInfo;
    abstract save(user: IEditableUserInfo): Promise<IServiceResult>;
    abstract delete(id: string): Promise<IServiceResult>;
}

export interface ICancellationToken {
    isCancellationRequested: boolean;
    throwIfCancelled(): void;
}

export class CancelledException extends Error {
    constructor() {
        super("Cancelled");
    }
}

import * as contracts from "@/contracts";
import * as svc from "@/services";
import { Container } from "inversify";
import log from "loglevel";
import BimObjectVerbalizer from "./components/BimObjectVerbalizer";
import * as Col from "./components/Floorplan/layers/Colorizers";
import { ToolTipGenerator } from "./components/Floorplan/layers/TooltipGenerators";

if (__DEBUG__) {
    log.enableAll();
} else {
    log.setLevel("warn");
}

// Configure IoC container
const container = new Container();
ConfigureContainer();


export { container };



function ConfigureContainer() {

    container.bind(contracts.ILog).toConstantValue(log);
    container.bind(contracts.Ii18n).to(svc.I18n).inSingletonScope();
    container.bind(contracts.IApplicationState).to(svc.ApplicationState).inSingletonScope();
    container.bind(contracts.IApplicationContext).to(svc.ApplicationContext).inSingletonScope();
    container.bind(contracts.ILiveMonitor).to(svc.LiveMonitor).inSingletonScope();
    container.bind(contracts.IAlarmMonitor).to(svc.AlarmMonitor).inSingletonScope();
    container.bind(contracts.IAuthenticationService).to(svc.AuthenticationService).inSingletonScope();
    container.bind(contracts.IConfigApi).to(svc.ConfigApi).inSingletonScope();
    container.bind(contracts.IActionApi).to(svc.ActionApi).inSingletonScope();
    container.bind(contracts.ILiveApi).to(svc.LiveApi).inSingletonScope();
    container.bind(contracts.IDataHistoryApi).to(svc.DataHistoryApi).inSingletonScope();
    container.bind(contracts.IUsersService).to(svc.UsersService).inSingletonScope();
    container.bind(contracts.IBimLogsService).to(svc.BimLogsService).inSingletonScope();
    container.bind(contracts.IBimEventsService).to(svc.BimEventsService).inSingletonScope();
    container.bind(contracts.IBackupService).to(svc.BackupService).inSingletonScope();
    container.bind(Col.ZoneColorizer).to(Col.ZoneColorizer).inSingletonScope();
    container.bind(Col.FixtureColorizer).to(Col.FixtureColorizer).inSingletonScope();
    container.bind(Col.PartitionWallColorizer).to(Col.PartitionWallColorizer).inSingletonScope();
    container.bind(Col.ScheduleColorizer).to(Col.ScheduleColorizer).inSingletonScope();
    container.bind(Col.IconColorizer).to(Col.IconColorizer).inSingletonScope();
    container.bind(ToolTipGenerator).to(ToolTipGenerator).inSingletonScope();
    container.bind(BimObjectVerbalizer).to(BimObjectVerbalizer).inSingletonScope();
}


import { ApiClaimType, IClaim, IEditableUserInfo, IRole, NodeTypes, PermissionClaimType } from "@/contracts";
import { EventOptions, defaultCadenceValues } from "@/contracts/IBimEventsService";

export class Role implements IRole {
    readonly role: string;
    readonly organizationId = "00000000-0000-0000-0000-000000000001";

    constructor(roleStr: string) {
        const parts = roleStr.split(":");
        if (parts.length === 1) {
            this.role = parts[0];
        } else {
            this.role = parts[2];
        }
    }

    toString() {
        return `Organization:${this.organizationId}:${this.role}`;
    }
}

interface IBimClaimParam {
    type: string;
    value: string;
}

interface IClaimParam {
    nodeId: string;
    nodeType: NodeTypes;
    api: ApiClaimType;
    permission: PermissionClaimType;
}
export class Claim implements IClaim {
    readonly nodeId!: string;
    readonly nodeType!: NodeTypes;
    readonly api!: ApiClaimType;
    readonly permission!: PermissionClaimType;

    constructor(param: IBimClaimParam | IClaimParam) {

        if (this.isBimClaimParam(param)) {

            [this.api, this.nodeType, this.permission] = param.type.split(":") as [ApiClaimType, NodeTypes, PermissionClaimType];
            this.nodeId = param.value;
        } else {
            Object.assign(this, param);
        }
    }

    toJson() {
        return ({
            type: `${this.api}:${this.nodeType}:${this.permission}`,
            value: this.nodeId
        });
    }

    isBimClaimParam(param: IBimClaimParam | IClaimParam): param is IBimClaimParam {
        return (param as IBimClaimParam).type !== undefined;
    }
}

export class UserInfo implements IEditableUserInfo {

    static defaultId = "new-user";

    id = UserInfo.defaultId;
    email = "";
    name = "";
    isEnabled = true;
    passwordChangeOnLogin = false;
    roles: IRole[] = [];
    claims: IClaim[] = [];
    alarmsEnabled = true;
    password = "";
    confirmPassword = "";
    eventOptions: EventOptions = { Tags: [], Events: [], Cadence: {...defaultCadenceValues} };

    constructor(info?: any) {
        if (!info) { return; }

        // Copy everything over...
        Object.assign(this, info);

        // ...then fix string-based roles & claims to be useful objects
        if (Array.isArray(info.roles)) {
            const roles: IRole[] = info.roles.map((x: any) => new Role(x));
            this.roles = roles;
        }

        if (Array.isArray(info.claims)) {
            this.claims = info.claims.map((x: any) => new Claim({ type: x.type, value: x.value }));
        }
    }

    toJson() {
        const json = JSON.stringify(this, this.replacer);
        return json;
    }

    private replacer(key: string, value: any) {
        if ((key === "id" || key === "password" || key === "confirmPassword") && value === "") { return undefined; }
        if (key === "roles") { return this.roles.map(x => x.toString()); }
        if (key === "claims") { return this.claims.map(x => x.toJson()) }
        return value;
    }
}
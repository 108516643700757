import { IBimScheduleOccurrence, IBimScheduleEvent, IBimScheduleRecurrenceRule, IBimCalendar, IBaseNode } from "@/contracts";
import { BimObject } from "./BimObject";
import { getCalendarEvent } from "./BimManagerConfigApi";
import moment from "moment";
import defaultScheduleEvent from "./defaultScheduleEvent.json";
import defaultScheduleRecurrenceRule from "./defaultScheduleRecurrenceRule.json";
import { Guid } from "guid-typescript";
import BimScheduleEvent from "./BimScheduleEvent";
import { BimScheduleRecurrenceRule } from "./BimScheduleRecurrenceRule";
import { postCalendarEvents, putCalendarEvents, deleteCalendarEvent, getCalendarEvents, getCalendarOccurrences } from "./BimManagerConfigApi";

export class BimCalendar extends BimObject implements IBimCalendar {

    bacNetIndex: number;
    constructor(node: IBaseNode & any) {
        super(node);
        this.bacNetIndex = node.bacNetIndex;
    }


    async getOccurrences(start: moment.Moment, end: moment.Moment, max?: number): Promise<IBimScheduleOccurrence[]> {
        return getCalendarOccurrences(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this.id, start, end, max);
    }

    async getEvents(): Promise<IBimScheduleEvent[]> {
        return getCalendarEvents(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this.id);
    }

    async getEvent(eventId: string): Promise<IBimScheduleEvent> {
        return getCalendarEvent(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this.id, eventId);
    }

    async deleteEvent(event: IBimScheduleEvent): Promise<void> {
        return deleteCalendarEvent(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this.id, event.id);
    }


    async updateEvent(event: IBimScheduleEvent): Promise<IBimScheduleEvent[]> {
        return putCalendarEvents(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this.id, [event.node]);
    }

    async createEvent(event: IBimScheduleEvent): Promise<IBimScheduleEvent[]> {
        return postCalendarEvents(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this.id, [event.node]);
    }

    public getNewRecurrenceRule(): IBimScheduleRecurrenceRule {
        const newRecurrenceRuleNode: any = Object.assign({}, defaultScheduleRecurrenceRule);
        newRecurrenceRuleNode.organizationId = BimObject.appContext.currentOrg!.id;
        newRecurrenceRuleNode.encSystemId = BimObject.appContext.currentSystem!.id;
        newRecurrenceRuleNode.id = Guid.create().toString();
        const newRecurrenceRule: IBimScheduleRecurrenceRule = new BimScheduleRecurrenceRule(newRecurrenceRuleNode);
        return newRecurrenceRule;
    }

    public getNewEvent(start: moment.Moment, end: moment.Moment): IBimScheduleEvent {
        const newEventNode: any = Object.assign({}, defaultScheduleEvent);
        newEventNode.organizationId = BimObject.appContext.currentOrg!.id;
        newEventNode.encSystemId = BimObject.appContext.currentSystem!.id;
        newEventNode.id = Guid.create().toString();
        const newScheduleEvent = new BimScheduleEvent(newEventNode);
        newScheduleEvent.startMoment = start;
        newScheduleEvent.endMoment = end;
        return newScheduleEvent;
    }
}

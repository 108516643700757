import { IUserInfo, IAccountUpdateInfo, IClaim, IServiceResult } from ".";

export enum AuthenticationStates {
    Unknown,
    NotAuthenticated,
    Authenticating,
    Authenticated,
    AuthenticatedPartial,
}

export type ClaimSet = "admin" | "facman" | "orgScheduleCRUD" | "orgHistoryR" | "orgConfigControlR";
export const claimSets: Record<ClaimSet, Partial<IClaim>[]> = {
    admin: [
        { nodeType: "Organization", api: "Config", permission: "Read" },
        { nodeType: "Organization", api: "Admin", permission: "Create" },
        { nodeType: "Organization", api: "Admin", permission: "Read" },
        { nodeType: "Organization", api: "Admin", permission: "Update" },
        { nodeType: "Organization", api: "Admin", permission: "Delete" }
    ],
    facman: [
        { api: "Config", permission: "Read" },
        { api: "Control", permission: "Read" },
    ],
    orgScheduleCRUD: [
        { nodeType: "Organization", api: "Schedule", permission: "Create" },
        { nodeType: "Organization", api: "Schedule", permission: "Read" },
        { nodeType: "Organization", api: "Schedule", permission: "Update" },
        { nodeType: "Organization", api: "Schedule", permission: "Delete" },
    ],
    orgHistoryR: [
        { nodeType: "Organization", api: "History", permission: "Read" }
    ],
    orgConfigControlR: [
        { nodeType: "Organization", api: "Config", permission: "Read" },
        { nodeType: "Organization", api: "Control", permission: "Read" },
    ]
}


export abstract class IAuthenticationService {
    abstract authenticationState: AuthenticationStates;
    abstract errorCode?: number;
    public errorInfo?: string;

    abstract authenticatedUserInfo?: IUserInfo;

    abstract login(userName: string, password: string): Promise<void>;
    abstract logout(): Promise<void>;
    abstract checkCurrentAuthenticatedState(): void;

    abstract isAuthenticatedFor(claims: ClaimSet): boolean;
    abstract getAuthenticatedOrganizationIds(): string[];

    abstract updateAccount(accountInfo?: IAccountUpdateInfo): Promise<IServiceResult>;
}


import { ClaimSet, IAuthenticationService } from "@/contracts";
import { observer } from "mobx-react";
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useInjection } from "./UseInjection";

interface IProtectedRouteProps {
    claims: ClaimSet;
}

export const ProtectedRoute = observer((c: IProtectedRouteProps) => {
    const authService = useInjection(IAuthenticationService);
    const isAuthenticated = authService.authenticatedUserInfo !== undefined
    const isAuthorized = authService.isAuthenticatedFor(c.claims as ClaimSet);
    const location = useLocation();

    if(isAuthorized)
         return <Outlet />;

    return <Navigate to={{ pathname: isAuthenticated ? "/nope" : "/login" }} state={{ from: location }} replace />;
  });

import { ILiveUpdate, ILiveApi, IAlarmMonitor, IBimEventItem } from "@/contracts";

import { debounce } from "lodash-es";

import { action, makeObservable, observable } from "mobx";

import { injectable, inject } from "inversify";

@injectable()
export class AlarmMonitor implements IAlarmMonitor {
    private pendingLiveUpdates: ILiveUpdate[] = [];
    private debouncedFlushLiveUpdates = debounce(this.flushLiveUpdates, 50);

    @observable
    alarmCounts = new Map<string, number>();

    @observable
    currentAlarms = new Map<string, IBimEventItem[]>();

    constructor(@inject(ILiveApi) private liveApi: ILiveApi) {
        makeObservable(this);
    }

    start(orgId: string, systemId: string): void {
        this.stop();
        this.liveApi.subscribeToAlarms(orgId, systemId, this.onLiveUpdate);
    }

    stop(): void {
        this.reset();
    }

    private reset = () => {
        this.liveApi.unsubscribeFromAlarms();
        this.clearLiveStates();
    }

    private onLiveUpdate = (data?: ILiveUpdate) => {
        if (!data) {
            // Offline, so erase all the live states
            this.clearLiveStates();
        } else {
            this.pendingLiveUpdates.push(data);
            this.debouncedFlushLiveUpdates();
        }
    }

    @action
    private flushLiveUpdates() {
        for (const data of this.pendingLiveUpdates) {
            if (data.state.activeAlarms) {
                const alarms: IBimEventItem[] = data.state.activeAlarms;
                const newAlarms = alarms.flatMap(x => x.nodes).filter(n => n.type != "Manager" || n.isPrimary).reduce((m, x) => m.set(x.id, (m.get(x.id) || 0) + 1), new Map<string, number>())

                this.alarmCounts.clear();
                newAlarms.forEach((v, k) => this.alarmCounts.set(k, v));

                this.currentAlarms.clear();
                alarms.forEach(alarm => this.AddAlarm(alarm.nodes.filter(n => n.isPrimary)[0].id, alarm));
            }
        }
        this.pendingLiveUpdates = [];
    }

    private AddAlarm(id: string, alarm: IBimEventItem) {
        if (!this.currentAlarms.has(id))
            this.currentAlarms.set(id, [alarm]);
        else
            this.currentAlarms.get(id)?.push(alarm);
    }

    @action
    private clearLiveStates() {
        this.pendingLiveUpdates = [];
        this.alarmCounts.clear();
        this.currentAlarms.clear();
    }
}


import { computed, observable, makeObservable } from "mobx";

export type FieldValidator<T> = (value: T) => string | undefined;
export type FieldDisabler = () => boolean;
export class EditableField<T> {
    @computed get isEnabled() { return this._disabler ? this._disabler() : false; }
    @computed get validationMessage() { return this._validator ? this._validator(this.value) : undefined; }
    @computed get isValid() { return this.validationMessage === undefined; }
    @observable value: T;
    private _validator?: FieldValidator<T>;
    private _disabler?: FieldDisabler;
    constructor(value: T, validator?: FieldValidator<T>, disabler?: FieldDisabler) {
        makeObservable(this);
        this.value = value;
        this._validator = validator;
        this._disabler = disabler;
    }
}

import { BatteryLevel } from "@/services/Bim/BimBatteryUtils";
import { Dictionary } from "lodash";
import { IBimCalendar, IBimEventItem, IBimSchedule, IPoint, NodeTypes } from ".";

export type ecuStates =
    "unknown-unchanged" |
    "off" |
    "support" |
    "startup" |
    "flickwarn" |
    "minority-on" |
    "majority-on" |
    "emergency" |
    "night-light" |
    "automatic-on" |
    "on" |
    "burn-in" |
    "forced-on" |
    "forced-override"
    ;
export type ecuErrorStates =
    "operational" |
    "fire-alarm" |
    "backup-power" |
    "backup-power test" |
    "lamp-load-failure" |
    "ballast-driver-failure" |
    "communication-failure" |
    "corrupted-communication" |
    "not-supported "
    ;

export type rollupEcuStates = "off" | "on";

export const ecuStateOnOffRollup = new Map<ecuStates, rollupEcuStates>([
    ["off", "off"], ["support", "off"], ["minority-on", "off"], ["emergency", "off"], ["night-light", "off"],
    ["startup", "on"], ["flickwarn", "on"], ["majority-on", "on"], ["automatic-on", "on"], ["on", "on"], ["burn-in", "on"], ["forced-on", "on"], ["forced-override", "on"]
]);

export type ConnectionStates = "online" | "offline" | "unknown";

export type EmergencyLuminaireTypes = "NonMaintained" | "Maintained" | "Central";

export interface IZoneOnSource {
    eventType: number;
    source: number;
    address: string;
}

export abstract class IConfigApi {

    abstract getVersion(): Promise<string | undefined>;
    abstract getOrganization(orgId: string): Promise<IBimOrganization | undefined>;
    abstract getSystemNodes(orgId: string): Promise<IBaseNode[]>;
    abstract getOrganizationNode(orgId: string): Promise<IOrganizationNode>;
    abstract updateOrganizationNode(org: IOrganizationNode): Promise<void>;
    abstract getZoneItemIds(orgId: string, systemId: string, zoneId: string, types?: NodeTypes[]): Promise<string[]>;
    abstract getZoneNodesForSchedule(orgId: string, systemId: string, scheduleId: string): Promise<IBaseNode[]>;
    abstract getFloor(orgId: string, systemId: string, floorId: string): Promise<{ id: string, areas: IBimZone[], diffFloorItems: IBimFloorObject[], items: IBimFloorObject[], allItems: Map<string, IBimObject> }>;
    abstract getZones(orgId: string, systemId: string): Promise<IBimObject[]>;
    abstract getNodes(orgId: string, systemId: string, nodeTypes: NodeTypes[]): Promise<IBimObject[]>;
    abstract getNodeById(orgId: string, systemId: string, nodeTypes: NodeTypes, nodeId: string): Promise<IBaseNode>;
    abstract updateNode<T extends IBimObject>(orgId: string, systemId: string, node: T, updates: Partial<T>): Promise<void>;
    abstract searchNodes(orgId: string, systemId: string, searchText: string): Promise<IBimObject[]>;
    abstract getNodeLocation(orgId: string, systemId: string, node: IBimObject): Promise<{ buildingId?: string, floorId?: string }>;
    abstract getNodeManager(orgId: string, systemId: string, nodeType: string, nodeId: string): Promise<IBimManager | undefined>;
    abstract getNodeRelationOnly(orgId: string, systemId: string, nodeType: string, nodeId: string, targetNodeTypes?: string, relationTypes?: string): Promise<IRelation[]>;
    abstract getRelatedNodes(orgId: string, systemId: string, nodeType: string, nodeId: string, targetNodeTypes?: string): Promise<IBaseNode[]>;
    abstract getRelations(orgId: string, systemId: string, nodeType: string, nodeId: string, targetNodeTypes?: string, relationTypes?: string): Promise<IRelation[]>;
    abstract getDirectRelationsAndNodes(orgId: string, systemId: string, nodeType: string, nodeId: string, targetNodeTypes?: string, relationTypes?:string): Promise<{relations: IRelation[],nodes: IBaseNode[]}>
    abstract getExceptionParentEventId(orgId: string, systemId: string, occurrenceId: string): Promise<string | undefined>;
    abstract getZoneTree(orgId: string): Promise<ITreeNode>;

    abstract deleteSystem(orgId: string, systemId: string): Promise<void>;
}

export interface IRelation {
    id: string;
    relationType: string;
    outNodeId: string;
    outNodeType: NodeTypes;
    inNodeId: string;
    inNodeType: NodeTypes;
    controlType?: string;
}

export interface IBaseNode {
    id: string;
    name: string;
    nodeType: NodeTypes;
    zOrder: number;
    nodeSystemType?: number | null;
    caption?: string;
}

export interface IBaseEmergencyNode extends IBaseNode {
    testManagerType: string;
    caption: string;
}

export interface IOrganizationNode extends IBaseNode {
    emailAuthenticationType: string;
    emailServerAddress: string;
    emailServerPort: number;
    emailSecurityLevel: string;
    emailUsername: string;
    emailPassword: string;
    emailFromName: string;
    emailFromAddress: string;
    emailSubject: string;
}

export interface ITreeNode extends IBaseBimObject {
    defaultChild?: boolean;
    parent?: ITreeNode;
    children: ITreeNode[]
}

export interface IBaseBimObject {
    id: string;
    type: NodeTypes;
    name: string;
    nodeSystemType?: number;
}

export interface IBimObject extends IBaseBimObject {
    init(outRelations: Dictionary<IRelation[]>, inRelations: Dictionary<IRelation[]>, nodes: Map<string, IBimObject>): void;
    update<T extends IBimObject>(props: Partial<T>): Promise<void>;
}

export interface IBimOrganization extends IBimObject {
    description: string;
    systems: IBimSystem[];
}

export interface IBimSystem extends IBimObject {
    customer?: string;
    address?: string;
    notes?: string;
    location?: { lat: string, lon: string };
    buildings: IBimBuilding[];
    schedules: IBimSchedule[];
    calendars: IBimCalendar[];
    managerCount: number;
    wirelessSystem: boolean;
}

export interface IBimBuilding extends IBimObject, IBimLiveAlarms {
    address?: string;

    floors: IBimFloor[];
}


export interface IBimFloor extends IBimObject, IBimLiveAlarms {
    floorLevel: number;
    floorplan: string; // URL to SVG
    origin: { x: number, y: number };
    size: { width: number, height: number };
    isAccessLimited: boolean;
}

export interface IBimFloorObject extends IBimObject {
    shape?: string; // SVG
    location?: IPoint;
    dimensions?: IPoint;
    rotation?: number;
}

export interface IBimFeatureLiveState {
    connectionStatus: ConnectionStates | null;
    errorState: ecuErrorStates | null;
    updateState(state?: any): void;
}

export interface IBimLiveAlarms {
    alarmCount: number;
    alarm: IBimEventItem[] | undefined;
}

export interface IBimFeatureBrightness extends IBimFeatureLiveState {
    brightness: number | null;
}

export interface IBimFeatureControllableBrightness extends IBimFeatureBrightness {
    actionBrightness(value: number): Promise<void>;
}

export interface IBimFeatureSecondaryBrightness extends IBimFeatureBrightness {
    secondaryBrightness: number | null;
}

export interface IBimFeatureInputLock extends IBimFeatureLiveState {
    isLocked: boolean | null;
}

export interface IBimFeatureColorTemp extends IBimFeatureLiveState {
    colorTemp: number | null;
    actionColorTemp(value: number): Promise<void>;
}

export type LiveState = rollupEcuStates | null;
export interface IBimFeatureStatus extends IBimFeatureLiveState {
    status: LiveState;
    detailedStatus: ecuStates | null;
}

export type LiveOccupancyState = "occupied" | "unoccupied" | null;
export interface IBimFeatureOccupancyState extends IBimFeatureLiveState {
    occupancyState: LiveOccupancyState;
}

export interface IBimFeatureLightReading extends IBimFeatureLiveState {
    lightReading: number | null;
}

export interface IBimFeatureControllableStatus extends IBimFeatureStatus {
    actionOn(): Promise<void>;
    actionOff(): Promise<void>;
}

export interface IBimFeatureBatteryChargeLevel extends IBimFeatureLiveState {
    batteryChargeLevel: number | null;
}

export interface IBimFeatureBatteryVoltage_mV extends IBimFeatureLiveState {
    batteryVoltage_mV: number | null;
    batteryVoltagePercent: number | null;
    batteryVoltageLevel: BatteryLevel;
}

export interface IBimFeatureEmergencyMode extends IBimFeatureLiveState {
    emergencyMode: number | null;
}

export interface IPatchFailure {
    status: number;
    detail: string;
}

export interface IBimZoneModifyProps {
    createdDate: string;
    updatedDate: string;
    timeScheduleAutoTurnOn: boolean;
    ocsAutoTurnOn: boolean;
    ocsExtensionTime: number;
    ocsManualUserExtensionTime: number;
}

export interface IBimZone extends IBimFloorObject, IBimFeatureControllableBrightness, IBimFeatureControllableStatus, IBimFeatureColorTemp, IBimScenes, IBimZoneModifyProps {
    shape: string; // SVG
    zOrder: number;
    schedule: string | null;
    endOfZoneOn: number | null;
    zoneOnSource: IZoneOnSource | null;

    getZoneItems(): Promise<IBimFloorObject[]>;
    setScene(sceneId: string): Promise<void>;
    newScene(): Promise<void>;
    saveScene(scene: IBimScene, sceneName: string, fadeTimeMs: number): Promise<any>;
    deleteScene(sceneId: string): Promise<void>;
    reorderScenes(order: string[]): Promise<void>;
}

export interface IMultiItem {
    getAllItems(): IBimFloorObject[];
}

export interface IMultiItemChild extends IMultiItem {
    getParent(): IBimFloorObject | undefined;
}

export interface IBimBallast extends IBimObject, IBimFeatureControllableBrightness, IBimFeatureSecondaryBrightness, IBimFeatureControllableStatus {
}

export interface IBimEmergencyInverter extends IBimFeatureEmergencyMode, IBimFeatureBatteryChargeLevel {
}

export interface IBimLuminare extends IBimFloorObject {
    location: IPoint;
    dimensions: IPoint;
    rotation: number;
}

export interface IBimPartitionWall extends IBimFloorObject, IBimFeatureLiveState {
    location: IPoint;
    dimensions: IPoint;
    rotation: number;
    isClosed: boolean | null;
}

export interface IBimOccupancyProps {
    createdDate: string;
    updatedDate: string;
    sensitivity: number;

    setSensitivity(nodeSensitivity: number): void;
}

export interface IBimOccupancySensor extends IBimFloorObject, IBimFeatureOccupancyState, IBimFeatureInputLock, IBimFeatureBatteryVoltage_mV, IBimOccupancyProps {
}

export interface IBimPhotoSensor extends IBimFloorObject, IBimFeatureLightReading, IBimFeatureInputLock, IBimFeatureBatteryVoltage_mV {
}

export interface IBimManager extends IBimFloorObject, IBimFeatureLiveState {
    addressOffset: number;
    ipAddress: string;
    hardwareArchitecture: string;
}

export interface IBimWallstation extends IBimFloorObject, IBimFeatureInputLock, IBimFeatureBatteryVoltage_mV {
}

export interface IBimPlugLoad extends IBimFloorObject, IBimFeatureControllableStatus {
}

export interface IBimScenes {
    currentSceneId: string | null;
    defaultSceneId: string | null;
    isCurrentSceneModified: boolean | null;
    scenes: IBimScene[] | null;
}

export interface IBimScene {
    id: string;
    alias: number;
    name: string;
    fadeTime: number;
    isCurrent: boolean;
    isDefault: boolean;
    isModified: boolean;

    setCurrent(): void;
}

export interface IProgress<T> {
    report(value: T): void;
}

export class Progress<T> implements IProgress<T> {
    constructor(private handler: (value: T) => void) { }
    report(value: T): void {
        this.handler(value);
    }

}

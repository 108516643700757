import { IBaseNode, NodeTypes } from "@/contracts";
import BimBuilding from "./BimBuilding";
import { BimCalendar } from "./BimCalendar";
import { BimFloor } from "./BimFloor";
import { BimBallast, BimEmergencyInverter, BimEmergencyLuminareFactory, BimLuminareFactory } from "./BimLuminare";
import { BimObject } from "./BimObject";
import { BimManager, BimOccupancySensor, BimPhotoSensor, BimPlugLoad, BimSystem, BimWallstation } from "./BimOther";
import { BimPartitionWall } from "./BimPartitionWall";
import { BimSchedule } from "./BimSchedule";
import { BimTunableWhiteLuminare } from "./BimTunableWhiteLuminare";
import { BimZone } from "./BimZone";

type BimObjectConstructor = new (node: IBaseNode) => BimObject;
type BimObjectFactory = (node: IBaseNode) => BimObject;

const constructors: Partial<Record<NodeTypes, BimObjectConstructor>> = {
    "TunableWhiteLuminaire": BimTunableWhiteLuminare,
    "Keypad": BimWallstation,
    "OccupancySensor": BimOccupancySensor,
    "PhotoSensor": BimPhotoSensor,
    "Manager": BimManager,
    "PartitionWall": BimPartitionWall,
    "PlugLoad": BimPlugLoad,
    "OrganizationalArea": BimZone,
    "Ballast": BimBallast,
    "EncSystem": BimSystem,
    "Building": BimBuilding,
    "Floor": BimFloor,
    "Schedule": BimSchedule,
    "Calendar": BimCalendar,
    "EmergencyInverter": BimEmergencyInverter,
}

const factories: Partial<Record<NodeTypes, BimObjectFactory>> = {
    "EmergencyLuminaire": BimEmergencyLuminareFactory,
    "Luminaire": BimLuminareFactory,
    "WalcLuminaire": BimLuminareFactory,
    "WslcLuminaire": BimLuminareFactory,
    "GroupedBallast": BimLuminareFactory,
}

export function itemFactory(node: IBaseNode) {

    try {
        const c = constructors[node.nodeType];
        if (c) {
            return new c(node);
        } else {
            const f = factories[node.nodeType];
            if (f) {
                return f(node);
            }
        }
    } catch (err) {
        console.log(err);
    }
    return undefined;
}

import { ICancellationToken, IProgress } from "@/contracts";

export enum RestoreStatus {
    InProgress,
    Finished,
    Cancelled,
    ErrCanNotOverride,
    ErrIncorrectVersion,
    ErrComponentNotFound,
    ErrBadPassword,
    ErrAnotherRestoreInProgress,
    ErrPayloadTooLarge,
    ErrOther
}

export interface IBackupEcuComponents {
    nvram: boolean;
    logs: boolean;
    "ecu.ini": boolean;
    networkingFiles: boolean;
    certificates: boolean;
    coredumps: boolean;
}

export interface IBackupComponents {
    configDB: boolean;
    dataHistoryDB: boolean;
    identityDB: boolean;
    eventsDB: boolean;
    resourceFiles: boolean;
    certificates: boolean;
    ecuComponents: IBackupEcuComponents;
}

export interface IRestoreComponents {
    configDB: boolean;
    dataHistoryDB: boolean;
    identityDB: boolean;
    eventsDB: boolean;
    resourceFiles: boolean;
    certificates: boolean;
}

export interface IBackupErrorInfo {
    managerId: string;
    errorCode: string;
    errorMsg: string;
}

export interface IBackupError {
    type: string;
    errorInfo: IBackupErrorInfo;
}

export interface IBackupInstance {
    href: string;
    id: string;
    size: number;
    dateTime: string;
    backupType: "Manual" | "Automated";
    status: string;
    backupErrors: IBackupError[];
    components: IBackupComponents;
}

export interface IBackupRequest {
    components: IBackupComponents;
    // password: string;
}

export interface IRestoreRequest {
    id?: string;
    file?: File;
    components: IRestoreComponents;
    password?: string;
    replace: boolean;
}

export interface IAutoBackupSettings {
    enabled: boolean;
    numBackupsToKeep: number;
    components: IBackupComponents;
}

export type DayOfWeek = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

export interface IWeeklyBackupSettings extends IAutoBackupSettings {
    dayOfWeek: DayOfWeek;
}

export interface IMonthlyBackupSettings extends IAutoBackupSettings {
    dayOfMonth: number;
}

export interface IAutomatedBackupSettings {
    timeOfDay: string;
    daily: IAutoBackupSettings;
    weekly: IWeeklyBackupSettings;
    monthly: IMonthlyBackupSettings;
}

export interface IDetailedRestoreStats {
    status: RestoreStatus;
    errors?: string[];
}


export abstract class IBackupService {
    abstract getBackupList(): Promise<IBackupInstance[]>
    abstract createBackup(options: IBackupRequest, token?: ICancellationToken): Promise<number | undefined>
    abstract getBackupSchedule(): Promise<IAutomatedBackupSettings | undefined>;
    abstract setBackupSchedule(settings: IAutomatedBackupSettings): Promise<boolean>;
    abstract isSSUEmpty(): Promise<boolean>;
    abstract restoreBackup(options: IRestoreRequest, progress: IProgress<number | undefined>, token: ICancellationToken): Promise<IDetailedRestoreStats>;
}

import { AuthenticationStates, IAuthenticationService, Ii18n } from "@/contracts";
import { AccountBox, MoreVert } from "@mui/icons-material";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useInjection } from "../UseInjection";
import { AboutDialog } from "./AboutDialog";
import ManageAccountDialog from "./ManageAccountDialog";
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DownloadIcon from '@mui/icons-material/Download';
import InfoIcon from '@mui/icons-material/Info';

export const AccountMenu = observer(() => {
    const location = useLocation();
    const navigate = useNavigate();
    const authSvc = useInjection(IAuthenticationService);
    const i18n = useInjection(Ii18n);
    const button = useRef<HTMLButtonElement>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isAdmin] = useState(authSvc.authenticatedUserInfo?.roles.some(role => role.role === "Admin"));

    const loggedInMenuItems = authSvc.authenticationState === AuthenticationStates.Authenticated
        ? [
            <MenuItem key={1} divider sx={{ pl: 2, pr: 2, textAlign: "center", display: "block" }} onClick={openManageAccount} >
                <AccountBox fontSize="large" /><br />
                <Typography variant="h6">{authSvc.authenticatedUserInfo?.name}</Typography>
                <Typography variant="body2">{authSvc.authenticatedUserInfo?.email}</Typography>
            </MenuItem>,

            <MenuItem key={2} onClick={openManageAccount}>
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={i18n.t("Manage account")} /> 
            </MenuItem>,
            <MenuItem key={3} divider component={Link} {...{ to: FM_PUBLIC_PATH + "logout" }} data-test-id="qa_logout">
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={i18n.t("Log out")} /> 
            </MenuItem>
        ]
        : null;

    return <>
        <IconButton ref={button} color="inherit" onClick={toggleMenu} size="large">
            <MoreVert />
        </IconButton>

        <Menu open={isMenuOpen} onClose={handleCloseMenu} anchorEl={button.current}>
            {loggedInMenuItems}
            {isAdmin && 
            <MenuItem divider component={Link} {...{ to: ADMIN_BASE_PATH }}>
                <ListItemIcon>
                    <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText primary={i18n.t("Administration")} /> 
            </MenuItem>
            }
            <MenuItem divider component="a" download href="/assets/polaris_build.zip" onClick={handleCloseMenu} >
                <ListItemIcon>
                    <DownloadIcon />
                </ListItemIcon>
                <ListItemText primary={i18n.t("Download Polaris Config")} /> 
            </MenuItem>
            <MenuItem onClick={openAbout}>
                <ListItemIcon>
                    <InfoIcon />
                </ListItemIcon>
                <ListItemText primary={i18n.t("About")} /> 
            </MenuItem>
        </Menu>

        {location.state?.manageAccount && <ManageAccountDialog open={true} onClose={closeDialog} />}
        {location.state?.about && <AboutDialog open={true} onClose={closeDialog} />}
    </>;

    function toggleMenu() {
        setIsMenuOpen(!isMenuOpen);
    }

    function handleCloseMenu() {
        setIsMenuOpen(false);
    }

    function openAbout() {
        handleCloseMenu();
        navigate(location.pathname, { state: {...location.state, about: true }});
    }

    function openManageAccount() {
        handleCloseMenu();
        navigate(location.pathname, { state: {...location.state, manageAccount: true }});
    }

    function closeDialog() {
        navigate(-1);
    }

});

import { IBimFeatureControllableStatus, IBimFeatureStatus, IBimFeatureBrightness, luminaireNodeTypes } from "@/contracts";
import { IBimZone, IBimFloorObject, IBimLuminare, IBimFeatureLiveState, IBimOccupancySensor, IBimPhotoSensor, IBimFeatureColorTemp, IBimFeatureSecondaryBrightness, IBimFeatureOccupancyState, IBimFeatureLightReading, IBimFeatureInputLock, IBimPartitionWall, IBimFloor, IBimBuilding, IBimSystem, IBimManager, IBimFeatureControllableBrightness, IBimFeatureEmergencyMode, IBimFeatureBatteryChargeLevel, IBimFeatureBatteryVoltage_mV, IMultiItem, IMultiItemChild } from "./IConfigApi";
import { IBimCalendar, IBimSchedule } from "./IBimManagerConfig";

// Features
export function isBimFeatureLiveState(item: any): item is IBimFeatureLiveState {
    return item && (item as IBimFeatureLiveState).updateState !== undefined;
}

export function isBimFeatureControllableStatus(item: any): item is IBimFeatureControllableStatus {
    return item && (item as IBimFeatureControllableStatus).actionOn !== undefined;
}

export function isBimFeatureStatus(item: any): item is IBimFeatureStatus {
    return item && (item as IBimFeatureStatus).status !== undefined;
}

export function isBimFeatureBrightness(item: any): item is IBimFeatureBrightness {
    return item && (item as IBimFeatureBrightness).brightness !== undefined;
}

export function isBimFeatureControllableBrightness(item: any): item is IBimFeatureControllableBrightness {
    return item && (item as IBimFeatureControllableBrightness).actionBrightness !== undefined;
}

export function isBimFeatureSecondaryBrightness(item: any): item is IBimFeatureSecondaryBrightness {
    return item && (item as IBimFeatureSecondaryBrightness).secondaryBrightness !== undefined;
}

export function isBimFeatureColorTemp(item: any): item is IBimFeatureColorTemp {
    return item && (item as IBimFeatureColorTemp).colorTemp !== undefined;
}

export function isBimFeatureOccupancyState(item: any): item is IBimFeatureOccupancyState {
    return item && (item as IBimFeatureOccupancyState).occupancyState !== undefined;
}

export function isBimFeatureLightReading(item: any): item is IBimFeatureLightReading {
    return item && (item as IBimFeatureLightReading).lightReading !== undefined;
}

export function isBimFeatureInputLock(item: any): item is IBimFeatureInputLock {
    return item && (item as IBimFeatureInputLock).isLocked !== undefined;
}

export function isBimFeatureEmergencyMode(item: any): item is IBimFeatureEmergencyMode {
    return item && (item as IBimFeatureEmergencyMode).emergencyMode !== undefined;
}

export function isBimFeatureBatteryChargeLevel(item: any): item is IBimFeatureBatteryChargeLevel {
    return item && (item as IBimFeatureBatteryChargeLevel).batteryChargeLevel !== undefined;
}

export function isBimFeatureBatteryVoltage_mV(item: any): item is IBimFeatureBatteryVoltage_mV {
    return item && (item as IBimFeatureBatteryVoltage_mV).batteryVoltage_mV !== undefined;
}

// Types
export function isBimSchedule(item: any): item is IBimSchedule {
    return item && item.type === "Schedule";
}

export function isBimCalendar(item: any): item is IBimCalendar {
    return item && item.type === "Calendar";
}

export function isBimZone(item: any): item is IBimZone {
    return item && item.type === "OrganizationalArea";
}

export function isBimLuminare(item: any): item is IBimLuminare {
    return item && luminaireNodeTypes.has((item as IBimLuminare).type);
}
export function isBimOcs(item: any): item is IBimOccupancySensor {
    return item && item.type === "OccupancySensor";
}

export function isBimPhs(item: any): item is IBimPhotoSensor {
    return item && item.type === "PhotoSensor";
}

export function isBimPartitionWall(item: any): item is IBimPartitionWall {
    return item && item.type === "PartitionWall";
}

export function isBimManager(item: any): item is IBimManager {
    return item && item.type === "Manager";
}

export function isBimFloor(item: any): item is IBimFloor {
    return item && item.type === "Floor";
}

export function isBimBuilding(item: any): item is IBimBuilding {
    return item && item.type === "Building";
}

export function isBimSystem(item: any): item is IBimSystem {
    return item && item.type === "EncSystem";
}

export function isBimFloorObject(item: any): item is IBimFloorObject {

    const isFo = item && (
        (item as IBimFloorObject).shape !== undefined ||
        (item as IBimFloorObject).location !== undefined ||
        (item as IBimFloorObject).dimensions !== undefined ||
        (item as IBimFloorObject).rotation !== undefined
    );

    return isFo;
}

export function isMultiItem(item: any): item is IMultiItem {
    return item && (item as IMultiItem).getAllItems !== undefined;
}

export function isMultiItemChild(item: any): item is IMultiItemChild {
    return item && (item as IMultiItemChild).getParent !== undefined;
}

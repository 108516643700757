import { AuthenticationStates, BimException, ClaimSet, claimSets, IAccountUpdateInfo, IAuthenticatedUserInfo, IAuthenticationService, IServiceResult } from "@/contracts";
import { injectable } from "inversify";
import { computed, makeObservable, observable } from "mobx";
import { getAuthenticatedUserInfo, login, logout, refreshToken, tokenLifeMs, updateAccount } from "./Bim/AccountApi";

@injectable()
export class AuthenticationService implements IAuthenticationService {

    public authenticatedUserInfo?: IAuthenticatedUserInfo;
    public errorCode?: number;
    public errorInfo?: string;

    private authCache: Map<ClaimSet, boolean> = new Map();

    @observable
    private state: AuthenticationStates = AuthenticationStates.Unknown;

    private timerId = 0;

    constructor() {
        makeObservable(this);
        this.logout = this.logout.bind(this);
        this.checkCurrentAuthenticatedState();
    }

    @computed
    get authenticationState(): AuthenticationStates {
        return this.state;
    }

    async login(userName: string, password: string) {
        this.setUserInfoBy(() => login(userName, password));
    }

    checkCurrentAuthenticatedState() {
        if (this.state !== AuthenticationStates.Authenticating) {
            this.setUserInfoBy(getAuthenticatedUserInfo);
        }
    }

    isAuthenticatedFor(claimset: ClaimSet) {
        if (this.state !== AuthenticationStates.Authenticated) { return false; }

        let result = this.authCache.get(claimset);
        if (result !== undefined) {
            return result;
        }

        result = true;

        // Any undefined part of a IClaim is assumed to mean "anything"
        const claims = claimSets[claimset];
        for (const claim of claims) {
            const claimParts = Array.from(Object.entries(claim))
            const match = this.authenticatedUserInfo!.claims.find(x => claimParts.every(([k, v]) => x[k as keyof typeof x] === v));
            if (!match) {
                result = false;
                break;
            }
        }

        this.authCache.set(claimset, result)

        return result;
    }

    getAuthenticatedOrganizationIds() {
        const orgIds = new Set(
            this.authenticatedUserInfo!.claims
                .filter(x => x.nodeType === "Organization" && (x.permission === "Read" || x.permission === "LimitedRead"))
                .map(x => x.nodeId)
        );
        return [...orgIds];
    }

    async logout() {
        this.clearRefreshTimer();
        this.authenticatedUserInfo = undefined;
        this.errorCode = undefined;
        this.errorInfo = undefined;
        this.authCache = new Map();
        await logout();
        this.state = AuthenticationStates.NotAuthenticated;
    }

    async updateAccount(accountInfo?: IAccountUpdateInfo): Promise<IServiceResult> {
        let result = { id: "", status: 200, errors: { errors: {} } };
        if(accountInfo)
            result = await updateAccount(accountInfo);

        if (result.status >= 200 && result.status < 300) {
            // Account successfully updated.
            await this.setUserInfoBy(getAuthenticatedUserInfo);
        }

        return result;
    }

    private async setUserInfoBy(func: () => Promise<IAuthenticatedUserInfo>) {
        this.state = (this.state === AuthenticationStates.Authenticated || this.state === AuthenticationStates.AuthenticatedPartial) ? this.state : AuthenticationStates.Authenticating;
        this.errorCode = undefined;
        this.errorInfo = undefined;
        this.authCache = new Map();
        try {
            const user = await func();
            this.authenticatedUserInfo = user;
            this.setRefreshTimer();
        } catch (err) {
            this.errorCode = (err instanceof BimException) ? err.status : 0;
            if (this.errorCode > 0) {
                this.errorInfo = err.message;
            }
        }

        this.state = this.authenticatedUserInfo ? this.authenticatedUserInfo.passwordChangeOnLogin ? AuthenticationStates.AuthenticatedPartial : AuthenticationStates.Authenticated : AuthenticationStates.NotAuthenticated;
    }

    private clearRefreshTimer() {
        if (this.timerId) {
            clearInterval(this.timerId);
            this.timerId = 0;
        }
    }

    private setRefreshTimer() {
        this.clearRefreshTimer();
        if (!this.authenticatedUserInfo) { return; }

        const now = Date.now();
        const initialDelay = Math.max(0, (this.authenticatedUserInfo.expireTime - now) / 3);

        // Initial refresh is based on how long till the token expires (it may have just been created or it may already exist)
        // Subsequent timeouts are based on the normal token life
        this.timerId = window.setTimeout(() => {
            this.refreshToken();
            this.timerId = window.setInterval(() => this.refreshToken(), tokenLifeMs / 3);
        }, initialDelay);
    }

    private async refreshToken() {
        try {
            await refreshToken();
        } catch (err) {
            if (err instanceof BimException && (err.status === 401 || err.status === 403)) {
                // We're not authenticated so we can't refresh. Log out to be in a consistent state
                await this.logout();
            }
        }
    }
}

import { ecuStates, IBaseNode, IBimFloorObject, IBimObject, IBimScene, IBimZone, IRelation, IZoneOnSource, rollupEcuStates } from "@/contracts";
import { Dictionary } from "lodash";
import { computed, makeObservable, observable, override } from "mobx";
import { BimObject } from "./BimObject";
import { BimScene } from "./BimScene";
import { BimSchedule } from "./BimSchedule";
import { naturalCollator } from "./NaturalCollator";

export class BimZone extends BimObject implements IBimZone {
    readonly shape: string;
    readonly zOrder: number;

    createdDate: string;
    updatedDate: string;
    timeScheduleAutoTurnOn: boolean;
    ocsAutoTurnOn: boolean;
    ocsExtensionTime: number;
    ocsManualUserExtensionTime: number;

    private _schedule: string | null = null;
    private _zoneItems: IBimFloorObject[] | null = null;

    @observable private _status: rollupEcuStates | null = null;
    @observable private _detailedStatus: ecuStates | null = null;
    @observable private _brightness: number | null = null;
    @observable private _colorTemp: number | null = -1; // Initially not supported AKA -1
    @observable private _endOfZoneOn: number | null = null;
    @observable private _zoneOnSource: IZoneOnSource | null = null;
    @observable private _currentSceneId: string | null = null;
    @observable private _defaultSceneId: string | null = null;
    @observable private _isCurrentSceneModified: boolean | null = null;
    @observable private _scenes: BimScene[] | null = null;

    @override get errorState() { return null; } // Ignore all error states on zones https://osramencelium.atlassian.net/browse/BOX-1816

    @computed get status() { return this.ifOnline(this._status); }
    @computed get detailedStatus() { return this.ifOnline(this._detailedStatus); }
    @computed get brightness() { return this.ifOnline(this._brightness); }
    @computed get colorTemp() { return this.ifOnline(this._colorTemp, -1); }
    @computed get endOfZoneOn() { return this.ifOnline(this._endOfZoneOn ? this._endOfZoneOn : null); }
    @computed get zoneOnSource() { return this.ifOnline(this._zoneOnSource); }
    @computed get currentSceneId() { return this.ifOnline(this._currentSceneId); }
    @computed get defaultSceneId() { return this.ifOnline(this._defaultSceneId); }
    @computed get isCurrentSceneModified() { return this.ifOnline(this._isCurrentSceneModified); }
    @computed get scenes(): IBimScene[] | null { return this.ifOnline(this._scenes); }


    @computed get schedule() { return this._schedule; }

    constructor(node: IBaseNode & any) {
        super(node);

        makeObservable(this);

        this.shape = this.shapeToPolygonPoints(node.shape!);
        this.zOrder = node.zOrder;

        this.createdDate = node.createdDate;
        this.updatedDate = node.updatedDate;
        this.timeScheduleAutoTurnOn = node.timeScheduleAutoTurnOn;
        this.ocsAutoTurnOn = node.ocsAutoTurnOn;
        this.ocsExtensionTime = node.ocsExtensionTime;
        this.ocsManualUserExtensionTime = node.ocsManualUserExtensionTime;    
    }

    init(outRelations: Dictionary<IRelation[]>, inRelations: Dictionary<IRelation[]>, nodes: Map<string, IBimObject>) {
        this._schedule = this.findRelatedNode<BimSchedule>(outRelations[this.id], nodes, "Schedule")?.id ?? null;
    }

    protected connectionStatusUpdated()
    {
        super.connectionStatusUpdated();
        this.updateScenes();
    }

    async actionOn(): Promise<void> {
        return this.sendAction("on");
    }

    actionOff(): Promise<void> {
        return this.sendAction("off");
    }

    actionBrightness(value: number): Promise<void> {
        return this.sendAction("dimAbsolute", [{ brightness: value }]);
    }

    setScene(sceneId: string): Promise<void> {
        return this.sendAction("recallSceneById", [{ sceneId: sceneId }]);
    }

    newScene(): Promise<void> {
        const baseName = BimObject.i18n.t("NewScene");
        let count = 1;
        let name = baseName;
        const scenes = this.scenes || [];
        while (scenes.find(x => x.name === name)) {
            name = `${baseName} (${count++})`;
        }

        return this.sendAction("createScene", [{ sceneName: name }]);
    }

    saveScene(scene: IBimScene, sceneName: string, fadeTimeMs: number): Promise<any> {
        const tasks = [];
        const sceneParams = [];
        if (sceneName !== scene.name) { sceneParams.push({ sceneName: sceneName }); }
        if (fadeTimeMs !== scene.fadeTime) { sceneParams.push({ fadeTime: fadeTimeMs }); }
        if (sceneParams.length > 0) {
            tasks.push(this.sendAction("modifySceneParams", [{ sceneId: scene.id }, ...sceneParams]));
        }

        if (scene.isModified) {
            tasks.push(this.sendAction("saveSceneById", [{ sceneId: scene.id }]));
        }
        return Promise.all(tasks);
    }

    deleteScene(sceneId: string): Promise<void> {
        return this.sendAction("deleteScene", [{ sceneId: sceneId }]);
    }

    reorderScenes(order: string[]): Promise<void> {
        const payload = [{
            sceneOrder: order.map(x => ({ sceneId: x }))
        }];
        return this.sendAction("reorderScenes", payload);
    }

    actionColorTemp(value: number): Promise<void> {
        return this.sendAction("recallColorTemperature", [{ colorTemperature: value }]);
    }

    async getZoneItems(): Promise<IBimFloorObject[]> {
        if (!this._zoneItems) {
            const appContext = BimObject.appContext;
            const ids = await BimObject.configApi.getZoneItemIds(appContext.currentOrg!.id, appContext.currentSystem!.id, this.id);

            const contextItems = [...appContext.currentItems, ...appContext.currentZones, ...appContext.diffFloorItems];
            const items = ids.map(id => contextItems.find(x => id === x.id)).filter(x => x !== undefined) as IBimFloorObject[];
            items.sort((a, b) => naturalCollator.compare(a.name, b.name));
            this._zoneItems = items;
        }
        return this._zoneItems;
    }

    private shapeToPolygonPoints(shape: Array<{ x: number, y: number }>) {
        const points = shape.map(p => `${p.x * 100},${p.y * 100}`).join(" ");
        return points;
    }
}

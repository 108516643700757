import { ConnectionStates, ecuErrorStates, ecuStateOnOffRollup, ecuStates, IActionApi, IAlarmMonitor, IApplicationContext, IBaseNode, IBimObject, IBimScene, IBimZone, IConfigApi, Ii18n, IRelation, LiveOccupancyState, NodeTypes } from "@/contracts";
import { Dictionary } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { BimScene } from "./BimScene";
import { BimZone } from "./BimZone";

// tslint:disable:no-string-literal

export abstract class BimObject implements IBimObject {
    static actionApi: IActionApi;
    static configApi: IConfigApi;
    static alarmMonitor: IAlarmMonitor;
    static appContext: IApplicationContext;
    static i18n: Ii18n;

    // Maps the live API state names to the FM item property name
    static stateMap = new Map<string, string>([
        ["errorStatus", "_errorState"],
        ["connectionStatus", "_connectionStatus"],
        ["brightness", "_brightness"],
        ["targetBrightness", "_secondaryBrightness"],
        ["disabled", "_isLocked"],
        ["controlStatus", "_status"],
        ["colorTemperature", "_colorTemp"],
        ["scenes", "_scenes"],
        ["sceneId", "_currentSceneId"],
        ["sceneModified", "_isCurrentSceneModified"],
        ["occupancyState", "_occStatus"],
        ["daylight", "_lightReading"],
        ["zoneOnSourceDetailedInfo", "_zoneOnSource"],
        ["endOfZoneOnTimestamp", "_endOfZoneOn"],
        ["emergencyMode", "_emergencyMode"],
        ["batteryChargeLevel", "_batteryChargeLevel"],
        ["batteryVoltage_mV", "_batteryVoltage_mV"],
        ["state", "_isClosed"],
    ]);

    @observable private _connectionStatus: ConnectionStates | null = null;
    @computed get connectionStatus() { return this._connectionStatus; }
    @observable private _errorState: ecuErrorStates | null = null;
    @computed get errorState() { return this.ifOnline(this._errorState); }
    @computed get alarmCount() { return BimObject.alarmMonitor.alarmCounts.get(this.id) || 0}
    @computed get alarm() { return BimObject.alarmMonitor.currentAlarms.get(this.id)}

    readonly id: string;
    readonly type: NodeTypes;
    @observable private _name: string;

    constructor(node: IBaseNode) {
        makeObservable(this);
        this.id = node.id;
        this.type = node.nodeType;
        this._name = node.caption || node.name;
    }

    init(outRelations: Dictionary<IRelation[]>, inRelations: Dictionary<IRelation[]>, nodes: Map<string, IBimObject>) {
    }

    protected connectionStatusUpdated() { }

    // TODO: T and nodeType are related. Can we avoid using both in the signature?
    protected findRelatedNode<T>(relations: IRelation[] | undefined, nodes: Map<string, IBimObject>, nodeType: NodeTypes | NodeTypes[], outRelations?: boolean): T | undefined {
        return this.findRelatedNodes<T>(relations, nodes, nodeType, outRelations)[0];
    }

    protected findRelatedNodes<T>(relations: IRelation[] | undefined, nodes: Map<string, IBimObject>, nodeType: NodeTypes | NodeTypes[], outRelations = true): T[] {
        const isArray = Array.isArray(nodeType);
        if (relations) {
            return relations
                .filter(x => this.filterRelations(outRelations ? x.inNodeType : x.outNodeType, nodeType, isArray))
                .map(x => nodes.get(outRelations ? x.inNodeId : x.outNodeId) as unknown as T)
        }
        return [];
    }

    private filterRelations(nt: NodeTypes, nodeType: NodeTypes | NodeTypes[], isArray: boolean){
        return isArray ? nodeType.includes(nt) : nt === nodeType;
    }

    @computed
    get name() { return this._name; }
    set name(value) { this._name = value; }

    // Update the node with the supplied properties
    // Will only allow keys that are defined in  BimObect or a subclass
    // Is NOT smart enough to filter out properties that shouldn't or can't change
    // Does NOT validate the values
    // So use with care!
    async update<T extends IBimObject>(props: Partial<T>): Promise<void> {

        await BimObject.configApi.updateNode(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this as unknown as T, props);

        // This sets the supplied properties automatically. Will call setters if they exist
        // If the UI needs to stay in sync, make sure properties that are updated are @observable/@computed
        Object.assign(this, props);

    }

    sendAction(actionName: string, value?: any[]): Promise<any> {
        return BimObject.actionApi.sendAction(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this.id, this.type, actionName, value);
    }

    @action
    updateState(state?: any): void {
        if (!state) {
            this.setOfflineStates();
            return;
        }

        for (const key of Object.keys(state)) {
            const propName = BimObject.stateMap.get(key);
            if (propName) {
                const func = this[("set" + propName) as keyof BimObject];
                if (func && typeof func === "function") {
                    try {
                        func.apply(this, [propName, state[key]]);
                    } catch (err) {
                        // console.log(err);
                    }
                }
            } else {
                // console.log(key);
            }
        }
    }

    setOfflineStates() {
        for (const propName of BimObject.stateMap.values()) {
            if (propName !== "connectionStatus" && this[propName as keyof BimObject]) {
                (this[propName as keyof BimObject] as string | null) = null;
            }
        }
    }

    protected ifOnline<T>(data: T, def: T | null = null): T | null {
        return (this.connectionStatus === "online") ? data : def;
    }

    protected set_errorState(propName: string, value: string) {
        (this[propName as keyof typeof this] as string) = value;
    }

    protected set_connectionStatus(propName: string, value: string) {
        const updated = (this[propName as keyof typeof this] as string) != value;
        (this[propName as keyof typeof this] as string) = value;

        if(updated)
            this.connectionStatusUpdated();
    }

    protected set_brightness(propName: string, value: number) {
        (this[propName as keyof typeof this] as number | null) = (value < 0 || value > 1000) ? null : value;
    }

    protected set_secondaryBrightness(propName: string, value: number) {
        this.set_brightness(propName, value);
    }

    protected set_status(propName: string, value: ecuStates) {
        (this[propName as keyof typeof this] as ecuStates | null) = ecuStateOnOffRollup.get(value) || null;
        (this["_detailedStatus" as keyof typeof this] as ecuStates | null) = value || null;
    }

    protected set_isLocked(propName: string, value: boolean | null) {
        (this[propName as keyof typeof this] as boolean | null) = value;
    }

    protected set_isClosed(propName: string, value: boolean | null) {
        (this[propName as keyof typeof this] as boolean | null) = value;
    }

    protected set_colorTemp(propName: string, value: number) {
        // -1 == not supported
        (this[propName as keyof typeof this] as number | null) = ((value < 2700 || value > 6500) && value !== -1) ? null : value;
    }

    protected set_endOfZoneOn(propName: string, value: number[] | null) {

        let endOfZoneOn: number | null = value?.[0] ?? 0;
        if (endOfZoneOn === 0) { endOfZoneOn = null; } // Unknown?
        else if (endOfZoneOn >= 4294000000) { endOfZoneOn = null; } //"Off time: indefinite"

        (this[propName as keyof typeof this] as number | null) = endOfZoneOn;
    }

    protected set_zoneOnSource(propName: string, value: any) {
        (this[propName as keyof typeof this] as any) = value ? {
            address: value.address,
            eventType: value.eventType,
            source: value.zoneOnSource
        } : null;
    }

    protected set_scenes(propName: string, value: any) {
        (this[propName as keyof typeof this] as any) = value && value.scenes ?
            value.scenes.map((x: any) => new BimScene(x, this as unknown as IBimZone)).sort((a: IBimScene, b: IBimScene) => a.alias - b.alias) :
            null;

        (this["_defaultSceneId" as keyof typeof this] as any) = value.defaultSceneId;
        this.updateScenes();
    }

    protected set_currentSceneId(propName: string, value: any) {
        (this[propName as keyof typeof this] as any) = value;
        this.updateScenes();
    }

    protected set_isCurrentSceneModified(propName: string, value: any) {
        (this[propName as keyof typeof this] as any) = value;
        this.updateScenes();
    }

    protected set_occStatus(propName: string, value: string) {
        const data: LiveOccupancyState = value === "Occupied" ? "occupied" : "unoccupied";
        (this[propName as keyof typeof this] as LiveOccupancyState) = data;
    }

    protected set_lightReading(propName: string, value: number) {
        (this[propName as keyof typeof this] as number) = value;
    }

    protected set_batteryChargeLevel(propName: string, value: number){
        (this[propName as keyof typeof this] as number) = value;
    }

    protected set_batteryVoltage_mV(propName: string, value: number){
        (this[propName as keyof typeof this] as number) = value;
    }
    
    protected set_emergencyMode(propName: string, value: number){
        (this[propName as keyof typeof this] as number) = value;
    }

    protected updateScenes() {
        const z = this as unknown as BimZone;

        if (this["_scenes" as keyof typeof this]) {
            for (const scene of this["_scenes" as keyof typeof this] as any) {
                scene.update(z.currentSceneId!, z.isCurrentSceneModified!);
            }
        }

    }
}

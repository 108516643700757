import { BimException, IAuthenticationService } from "@/contracts";
import { container } from "@/ioc";

let authSvc: IAuthenticationService;


export async function authenticatedFetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
    if (!authSvc) {
        authSvc = container.get(IAuthenticationService);
    }
    const response = await fetch(input, { ...init, credentials: "include" });
    if (response.status === 401) {
        await authSvc.logout();
    }

    return response;
}

export async function fetchJson(url: URL, authenticated = true) {
    const fetchFunc = authenticated ? authenticatedFetch : fetch;
    const response = await fetchFunc(url.href);
    if (!response.ok) {
        const statusText = await response.text();
        throw new BimException(response.status, statusText);
    }
    const contentType = response.headers.get("content-type");
    if (!contentType || !contentType.includes("application/json")) {
        throw new BimException(0, "Invalid content type");
    }

    return response.json();
}

import { BimException, CancelledException, IAutomatedBackupSettings, IBackupInstance, IBackupRequest, IBackupService, IBaseNode, ICancellationToken, IDetailedRestoreStats, IProgress, IRestoreRequest, RestoreStatus } from "@/contracts";
import { injectable } from "inversify";
import { authenticatedFetch, fetchJson } from ".";
import ServicesLocations from "./Bim/ServicesLocations";

interface IRestoreResult {
    id: string;
    status: keyof typeof RestoreStatus;
    errors: string[];
}

interface IRestoreId {
    id: string;
}

@injectable()
export class BackupService implements IBackupService {

    async getBackupList(): Promise<IBackupInstance[]> {
        const query = new URL(`organization/00000000-0000-0000-0000-000000000001/backups`, ServicesLocations.backup);
        const result: IBackupInstance[] = await fetchJson(query);

        result.forEach((x: IBackupInstance) => x.href = `${query.href}/${x.id}`);
        return result;
    }

    async getBackupSchedule(): Promise<IAutomatedBackupSettings | undefined> {
        try {
            const query = new URL(`organization/00000000-0000-0000-0000-000000000001/automated-config`, ServicesLocations.backup);
            const response = await fetchJson(query);
            return response;
        } catch (err) {
            if (!err.status || err.status !== 404) {
                throw (err);
            }
        }
        return undefined;
    }

    async setBackupSchedule(settings: IAutomatedBackupSettings): Promise<boolean> {
        const query = new URL(`organization/00000000-0000-0000-0000-000000000001/automated-config`, ServicesLocations.backup);
        const result = await authenticatedFetch(query.href, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(settings)
        });

        return result.ok;
    }

    async createBackup(options: IBackupRequest, token?: ICancellationToken): Promise<number | undefined> {
        const query = new URL(`organization/00000000-0000-0000-0000-000000000001/backups`, ServicesLocations.backup);

        // TODO: BimException instead of status. Return promise void
        try {
            const response = await authenticatedFetch(query.href, {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify(options)
            });

            if (response.ok) {
                const { id: backupId } = await response.json();
                const result = await this.pollForBackup(backupId, token);
                if (result) {
                    return result;
                }
            }
            return response.status;
        } catch (err) {
            if (!(err instanceof CancelledException)) {
                throw (err);
            }
        }

        return undefined;
    }

    async isSSUEmpty(): Promise<boolean> {
        const getSystemsQuery = new URL(`organization/00000000-0000-0000-0000-000000000001/encsystem`, ServicesLocations.config);
        const orgSystems: IBaseNode[] = await fetchJson(getSystemsQuery);
        return orgSystems.length === 0;
    }

    async restoreBackup(options: IRestoreRequest, progress: IProgress<number | undefined>, token: ICancellationToken): Promise<IDetailedRestoreStats> {
        let restoreId: string;

        try {
            if (options.id) {
                restoreId = await this.restoreFromId(options);
            } else {
                restoreId = await this.restoreFromFile(options, progress, token); // This will have file upload progress before returning
            }
            return this.pollForRestore(restoreId, token);
        } catch (err) {
            return {
                status: this.ErrorToRestoreStatus(err, token), 
                errors: err
            };
        }
    }

    private ErrorToRestoreStatus(err: any, token: ICancellationToken): RestoreStatus {
        if (token.isCancellationRequested) return RestoreStatus.Cancelled;
        if (!(err instanceof BimException)) return RestoreStatus.ErrOther;
        switch (err.status) {
            case 503:
                return RestoreStatus.ErrAnotherRestoreInProgress;
            case 413:
                return RestoreStatus.ErrPayloadTooLarge;
            default:
                return RestoreStatus.ErrOther;
        }
    }

    private async restoreFromId(options: IRestoreRequest): Promise<string> {
        const { id, file, ...opt } = { ...options };
        const query = new URL(`organization/00000000-0000-0000-0000-000000000001/restore/${id}`, ServicesLocations.backup);

        let response;
        try {
            response = await authenticatedFetch(query.href, {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify(opt)
            });

            if (response.ok && response.status === 200) {
                const json = await response.json() as IRestoreId;
                return json.id;
            }
        } catch (err) {
            response = err;
        }

        throw new BimException(response.status || 0, response.statusText || response.message || "");
    }

    private async restoreFromFile(options: IRestoreRequest, progress: IProgress<number | undefined>, token: ICancellationToken): Promise<string> {
        const { id, file, ...opt } = { ...options };
        const query = new URL(`organization/00000000-0000-0000-0000-000000000001/restore`, ServicesLocations.backup);

        try {
            const body = new FormData();
            body.append("file", file!);
            body.append("options", JSON.stringify(opt));

            const result = await this.makeXhr("POST", query, "json", body, progress, token) as IRestoreId;
            return result.id
        } catch (err) {
            throw new BimException(err.status || 0, err.message || "");
        }
    }

    private async pollForRestore(id: string, token: ICancellationToken): Promise<IDetailedRestoreStats> {
        const query = new URL(`organization/00000000-0000-0000-0000-000000000001/restore/${id}`, ServicesLocations.backup);

        // eslint-disable-next-line no-constant-condition
        while (true) {

            if (token.isCancellationRequested) { return {status: RestoreStatus.Cancelled} }

            let response;

            try {
                response = await authenticatedFetch(query.href, {
                    method: "GET"
                });

                if (response.ok && response.status === 200) {
                    const json = await response.json() as IRestoreResult;
                    if (json.status === "InProgress") {
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        continue;
                    }
                    if(json.errors.length > 0){
                        return {
                            status: RestoreStatus[json.status],
                            errors: json.errors
                        }
                    }
                    return {status: RestoreStatus[json.status]}
                }
            } catch (err) {
                response = err;
            }

            throw new BimException(response.status || 0, response.statusText || response.message || "");
        }
    }

    private async pollForBackup(id: string, token?: ICancellationToken): Promise<number> {
        const query = new URL(`organization/00000000-0000-0000-0000-000000000001/backups/${id}`, ServicesLocations.backup);

        let response: Response;

        do {
            if (token?.isCancellationRequested) { return 0; }

            response = await authenticatedFetch(query.href, {
                method: "HEAD"
            });

            if (response.status === 204) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        } while (response.status === 204);

        return response.status;
    }

    private makeXhr(method: string, url: URL, responseType: XMLHttpRequestResponseType, body: any, progress: IProgress<number | undefined>, token: ICancellationToken) {
        return new Promise<unknown>((resolve, reject) => {

            const xhr = new XMLHttpRequest();
            xhr.open(method, url.href);
            xhr.withCredentials = true;
            xhr.responseType = responseType;

            xhr.onload = () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(xhr.response);
                } else {
                    reject({ status: xhr.status, message: xhr.statusText });
                }
            }

            xhr.onerror = () => {
                reject({ status: 0 }); // 0 = unknown error
            }

            xhr.onabort = () => {
                reject({ status: -1 }); // -1 = aborted/cancelled
            }

            xhr.upload.onprogress = (e) => {
                if (token.isCancellationRequested) {
                    xhr.abort();
                }

                if (e.lengthComputable) {
                    const percentComplete = e.loaded / e.total * 100;
                    progress.report(percentComplete);
                } else {
                    progress.report(undefined);
                }
            }

            xhr.send(body);

        });
    }
}

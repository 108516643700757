import { BimException, IAccountUpdateInfo, IAuthenticatedUserInfo, IServiceResult } from "@/contracts";
import { fetchJson } from "..";
import ServicesLocations from "./ServicesLocations";
import { Claim, Role } from "./UserInfo";

export const tokenLifeMs = 60 * 60 * 1000; // 60 minutes

class TokenRequest {
    clientName = "Polaris Monitor";
    tokenLifeInSec = tokenLifeMs / 1000;
    constructor(
        public readonly action: "Token" | "Cookie" | "TokenRefresh" | "CookieRefresh",
        public readonly userName?: string,
        public readonly password?: string
    ) {
    }
}

export async function login(userName: string, password: string): Promise<IAuthenticatedUserInfo> {
    const data = new TokenRequest("Cookie", userName, password);

    const url = new URL("account/token", ServicesLocations.identity);
    const res = await fetch(url.href, { method: "POST", headers: { "content-type": "application/json" }, credentials: "include", body: JSON.stringify(data) });
    if (res.ok) {
        return getAuthenticatedUserInfo();
    }

    const statusText = await res.text();
    throw new BimException(res.status, statusText);
}

export async function updateAccount(userInfo: IAccountUpdateInfo): Promise<IServiceResult> {
    let res: Response | undefined;
    let errors = { errors: {} };

    try {
        const data = JSON.stringify(userInfo, (k, v) => {
            if (typeof v === "string" && v === "") { return undefined; }
            return v;
        });

        const url = new URL("account", ServicesLocations.identity);
        res = await fetch(url.href, { method: "PATCH", headers: { "content-type": "application/json" }, credentials: "include", body: data });
        if (!res.ok) {
            errors = await res.json();
        }
    } catch (err) {
    }

    return { id: "", status: res ? res.status : 0, errors: errors };
}

export async function getAuthenticatedUserInfo(): Promise<IAuthenticatedUserInfo> {
    const url = new URL("account", ServicesLocations.identity);

    const userInfo = await fetchJson(url, false) as IAuthenticatedUserInfo;

    // Fix roles to be objects
    userInfo.roles = userInfo.roles.map((x: any) => new Role(x));
    userInfo.claims = userInfo.claims.map((x: any) => new Claim(x));

    return userInfo;
}

export async function logout() {
    try {
        const url = new URL("account/token", ServicesLocations.identity);
        await fetch(url.href, { method: "DELETE", credentials: "include" });
    } catch (err) { }
}

export async function refreshToken() {
    const data = new TokenRequest("CookieRefresh");

    const url = new URL("account/token", ServicesLocations.identity);
    const res = await fetch(url.href, { method: "POST", headers: { "content-type": "application/json" }, credentials: "include", body: JSON.stringify(data) });
    if (res.status !== 204) {
        const statusText = await res.text();
        throw new BimException(res.status, statusText);
    } else {
        const userInfo = await getAuthenticatedUserInfo();
        if (userInfo.passwordChangeOnLogin || !userInfo.isEnabled) {
            throw new BimException(403, "");
        }
    }
}


import { FormControl, InputLabel, InputAdornment, IconButton, Input, FormHelperText } from "@mui/material";
import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface IPasswordBoxProps {
    name: string;
    label: string;
    password: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    isValid?: boolean;
    autoComplete: "new-password" | "current-password";
    required?: boolean;
    shrinkLabel?: boolean;
    helperText?: string;
    autoFocus?: boolean;
    className?: string;
}


export const PasswordBox = (props: IPasswordBoxProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const isValid = props.isValid === undefined ? true : props.isValid;

    return (
        <FormControl margin="normal" fullWidth required={props.required} className={props.className} error={!isValid}>
            <InputLabel htmlFor={props.name} shrink={props.shrinkLabel} error={!isValid}>{props.label}</InputLabel>
            <Input id={props.name} name={props.name} required autoComplete={props.autoComplete} autoFocus={props.autoFocus} spellCheck={false}
                type={showPassword ? "text" : "password"}
                value={props.password}
                onChange={props.onChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            size="large">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>}
            />
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
    );

    function handleClickShowPassword() {
        setShowPassword(!showPassword);
    }

    function handleMouseDownPassword(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
    }
};

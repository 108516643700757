import { IActionApi } from "@/contracts";
import { injectable } from "inversify";
import ServicesLocations from "./ServicesLocations";

@injectable()
export class ActionApi implements IActionApi {

    async sendAction(orgId: string, systemId: string, nodeId: string, nodeType: string, actionName: string, value?: any[]): Promise<any> {

        const payload = {
            nodeId: nodeId,
            nodeType: nodeType,
            action: {
                name: actionName,
                params: value
            }
        };

        const query = new URL(`organization/${orgId}/encsystem/${systemId}/actions`, ServicesLocations.action);

        return await fetch(query.toString(), {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });
    }

}

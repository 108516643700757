import { IBimFloorObject } from ".";
import { ITransformMatrix } from "./ITransformMatrix";

export enum floorplanModes { control, alerts, schedule, battery }

export abstract class IApplicationState {
    abstract lastDashboardBuildingId: string | undefined;
    abstract selectedFloorplanItem: IBimFloorObject | undefined;

    abstract transformMatrix: ITransformMatrix;

    abstract floorplanMode: floorplanModes;

    abstract isBottomSheetExpanded: boolean;

    abstract selectFloorplanItem(item?: string | IBimFloorObject, backItem?: string | IBimFloorObject): void;
    abstract selectBack(): void;
}

import { IBaseNode, Ii18n, IPatchFailure } from "@/contracts";
import { IBimScheduleOccurrence, IBimScheduleEvent, IBimScheduleEventException } from "@/contracts";
import BimScheduleOccurrence from "./BimScheduleOccurrence";
import BimScheduleEvent from "./BimScheduleEvent";
import BimScheduleEventException from "./BimScheduleEventException";
import ServicesLocations from "./ServicesLocations";
import { Moment } from "moment";
import { fetchJson } from "..";

export async function getScheduleOccurrences(orgId: string, systemId: string, scheduleId: string, start: Moment, end: Moment, max?: number): Promise<IBimScheduleOccurrence[]> {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/schedules/${scheduleId}/occurrences`, ServicesLocations.managerconfig);
    query.searchParams.append("startDate", start.format("YYYY-MM-DD HH:mm"));
    query.searchParams.append("endDate", end.format("YYYY-MM-DD HH:mm"));
    if (max) {
        query.searchParams.append("max", max.toString());
    }

    const results = await fetchJson(query);
    return results.map((x: IBaseNode) => new BimScheduleOccurrence(x));
}

export async function getScheduleEvents(orgId: string, systemId: string, scheduleId: string): Promise<IBimScheduleEvent[]> {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/schedules/${scheduleId}/events`, ServicesLocations.managerconfig);
    const results = await fetchJson(query);
    return results.map((x: IBaseNode) => new BimScheduleEvent(x));
}

export async function getScheduleEvent(orgId: string, systemId: string, scheduleId: string, eventId: string): Promise<IBimScheduleEvent> {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/schedules/${scheduleId}/events/${eventId}`, ServicesLocations.managerconfig);
    const result = await fetchJson(query);
    return new BimScheduleEvent(result[0]);
}

export async function putScheduleEvents(orgId: string, systemId: string, scheduleId: string, events: IBimScheduleEvent[]): Promise<IBimScheduleEvent[]> {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/schedules/${scheduleId}/events`, ServicesLocations.managerconfig);
    return await putJson(query, events);
}

export async function postScheduleEvents(orgId: string, systemId: string, scheduleId: string, events: IBimScheduleEvent[]): Promise<IBimScheduleEvent[]> {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/schedules/${scheduleId}/events`, ServicesLocations.managerconfig);
    return await postJson(query, events);
}

export async function deleteScheduleEvent(orgId: string, systemId: string, scheduleId: string, eventId: string) {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/schedules/${scheduleId}/events/${eventId}`, ServicesLocations.managerconfig);
    return await deleteJson(query);
}

export async function getScheduleEventExceptions(orgId: string, systemId: string, scheduleId: string, eventId: string): Promise<IBimScheduleEventException[]> {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/schedules/${scheduleId}/events/${eventId}/exceptions`, ServicesLocations.managerconfig);
    const results = await fetchJson(query);
    return results.map((x: IBaseNode) => new BimScheduleEventException(x));
}

export async function getScheduleEventException(orgId: string, systemId: string, scheduleId: string, eventId: string, exceptionId: string): Promise<IBimScheduleEventException> {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/schedules/${scheduleId}/events/${eventId}/exceptions/${exceptionId}`, ServicesLocations.managerconfig);
    const result = await fetchJson(query);
    return new BimScheduleEventException(result[0]);
}

export async function postScheduleEventException(orgId: string, systemId: string, scheduleId: string, eventId: string, exceptions: IBimScheduleEventException[]): Promise<void> {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/schedules/${scheduleId}/events/${eventId}/exceptions`, ServicesLocations.managerconfig);
    await postJson(query, exceptions);
}

export async function getCalendarOccurrences(orgId: string, systemId: string, calendarId: string, start: Moment, end: Moment, max?: number): Promise<IBimScheduleOccurrence[]> {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/calendars/${calendarId}/occurrences`, ServicesLocations.managerconfig);
    query.searchParams.append("startDate", start.format("YYYY-MM-DD HH:mm"));
    query.searchParams.append("endDate", end.format("YYYY-MM-DD HH:mm"));
    if (max) {
        query.searchParams.append("max", max.toString());
    }

    const results = await fetchJson(query);
    return results.map((x: IBaseNode) => new BimScheduleOccurrence(x));
}

export async function getCalendarEvents(orgId: string, systemId: string, calendarId: string): Promise<IBimScheduleEvent[]> {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/calendars/${calendarId}/events`, ServicesLocations.managerconfig);
    const results = await fetchJson(query);
    return results.map((x: IBaseNode) => new BimScheduleEvent(x));
}

export async function getCalendarEvent(orgId: string, systemId: string, calendarId: string, eventId: string): Promise<IBimScheduleEvent> {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/calendars/${calendarId}/events/${eventId}`, ServicesLocations.managerconfig);
    const result = await fetchJson(query);
    return new BimScheduleEvent(result[0]);
}

export async function putCalendarEvents(orgId: string, systemId: string, calendarId: string, events: IBimScheduleEvent[]): Promise<IBimScheduleEvent[]> {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/calendars/${calendarId}/events`, ServicesLocations.managerconfig);
    return await putJson(query, events);
}

export async function postCalendarEvents(orgId: string, systemId: string, calendarId: string, events: IBimScheduleEvent[]): Promise<IBimScheduleEvent[]> {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/calendars/${calendarId}/events`, ServicesLocations.managerconfig);
    return await postJson(query, events);
}

export async function deleteCalendarEvent(orgId: string, systemId: string, calendarId: string, eventId: string) {
    const query = new URL(`organization/${orgId}/encsystem/${systemId}/calendars/${calendarId}/events/${eventId}`, ServicesLocations.managerconfig);
    return await deleteJson(query);
}

async function postJson(url: URL, payload: any) {
    return sendJson("POST", url, payload);
}

export async function putJson(url: URL, payload: any) {
    return sendJson("PUT", url, payload);
}

export async function patchJson(url: URL, payload: any) {
    return sendJson("PATCH", url, payload);
}

const MSG = "issueWithApiRequestSorryMessage";
export function getPatchFailureMessage(result: any, i18n: Ii18n): string | undefined {

    if (!result) {
        return i18n.t(MSG);
    }
    else {
        const fail = result as IPatchFailure;
        if (fail && fail.status >= 300) {

            switch (fail.detail) {
                case "ConfigureFailure":
                    return (`${i18n.t(MSG)} ${i18n.t("ConfigureFailure")}`);
                case "CommonFailure":
                    return (`${i18n.t(MSG)} ${i18n.t("CommonFailure")}`);
                case "DBSetSyncedFailure":
                    return (`${i18n.t(MSG)} ${i18n.t("DBSetSyncedFailure")}`);
                case "Conflict":
                    return (`${i18n.t(MSG)} ${i18n.t("Conflict")}`);
                case "DBUpdateDataFailure":
                    return (`${i18n.t(MSG)} ${i18n.t("DBUpdateDataFailure")}`);
                case "CommAddressFailure":
                    return (`${i18n.t(MSG)} ${i18n.t("CommAddressFailure")}`);

                default:
                    return (i18n.t(MSG));
            }
        }
    }

    return undefined;
}

async function deleteJson(url: URL) {
    return sendJson("DELETE", url);
}

async function sendJson(method: string, url: URL, payload?: any) {
    const headers = {
        "Content-Type": "application/json"
    };
    return send(method, url, headers, payload);
}

async function send(method: string, url: URL, headers = {}, payload?: any): Promise<any> {
    const options = {
        method: method,
        headers: headers,
        body: payload ? JSON.stringify(payload) : undefined
    };
    const response = await fetch(url.toString(), options);
    if (response.ok) {
        return response.body;
    } 
    else if(response.statusText !== "") {
         return response.statusText
    }
    else {
        const JsonResponse = response.json(); 
        return JsonResponse;
    }
}


import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { useInjection } from "./UseInjection";
import { Ii18n } from "@/contracts";

interface IConfirmProps {
    open: boolean;
    title?: string;
    message: string;
    action?: string;
    onConfirm(): void;
    onCancel?(): void;
}

export function ConfirmDialog(props: IConfirmProps) {
    const i18n = useInjection(Ii18n);
    return (
        <Dialog maxWidth="xs" fullWidth open={props.open} onClose={props.onCancel}>
            {props.title &&
                <DialogTitle>{props.title}</DialogTitle>
            }
            <DialogContent>
                <DialogContentText>{props.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {props.onCancel &&
                    <Button onClick={props.onCancel}>{i18n.t("cancel")}</Button>
                }
                <Button onClick={props.onConfirm}>{props.action || i18n.t("Ok")}</Button>
            </DialogActions>
        </Dialog>
    )
}
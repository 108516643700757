function formatPiece(piece: string): string {
    if(piece.length == 36)
        return piece.substring(0, 8);
    else
        return piece;    
}

export function formatAddress(address: string): string {
    const pieces = address.split("/");
    if(pieces.length == 2)
        return formatPiece(pieces[0]) + "-" + formatPiece(pieces[1]);
    else
        return address;
}
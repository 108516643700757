import { injectable } from "inversify";
import { IBimObject, ILiveApi, ILiveUpdate, NodeTypes } from "@/contracts";
import { Subscription } from "./Subscription";

@injectable()
export class LiveApi implements ILiveApi {
    private sub?: Subscription;
    private alarmsSub?: Subscription;

    unsubscribe() {
        this.sub?.unsubscribe();
        this.sub = undefined;
    }

    unsubscribeFromAlarms() {
        this.alarmsSub?.unsubscribe();
        this.alarmsSub = undefined;
    }

    async subscribeTo(orgId: string, systemId: string, nodeTypes: NodeTypes[], onUpdate: (data?: ILiveUpdate) => void) {
        const nodes = [
            {
                nodeId: systemId,
                nodeType: "EncSystem",
                includeContainedUnder: true,
                filteredNodeTypes: nodeTypes
            }
        ]
        this.sub = new Subscription({ orgId: orgId, systemId: systemId, data: nodes, onUpdate:onUpdate });
    }

    async subscribeToAlarms(orgId: string, systemId: string, onUpdate: (data?: ILiveUpdate) => void) {
        const services = [
            {
              serviceName: "events",
              subTopic: "activeAlarms"
            }
          ]
        this.alarmsSub = new Subscription({ orgId: orgId, systemId: systemId, onUpdate:onUpdate, alarms:true, data: services });
    }

    async subscribeToFloor(orgId: string, systemId: string, floorId: string, otherItems: IBimObject[], onUpdate: (data?: ILiveUpdate) => void) {
        this.unsubscribe();

        const nodes = [
            {
                nodeId: floorId,
                nodeType: "Floor",
                includeContainedUnder: true
            }
        ].concat(otherItems.map(i => ({ nodeId: i.id, nodeType: i.type, includeContainedUnder: false}) ));

        this.sub = new Subscription({ orgId: orgId, systemId: systemId, data: nodes, onUpdate:onUpdate });
    }

}



import { IUserInfo, IEditableUserInfo, IServiceResult } from "@/contracts";
import { authenticatedFetch, fetchJson } from "..";
import ServicesLocations from "./ServicesLocations";

interface IPaginatedUserList {
    users: IUserInfo[];
    pageNumber: number;
}


export async function getUserList(): Promise<IUserInfo[]> {
    const url = new URL("admin/users", ServicesLocations.identity);

    let result: IUserInfo[] = [];
    let pageNum = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        url.searchParams.set("pageNumber", pageNum.toString());
        const page: IPaginatedUserList = await fetchJson(url);
        if (page.users.length === 0) { break; }

        result = result.concat(page.users);
        pageNum++;
    }

    return result;
}

export async function createUser(user: IEditableUserInfo): Promise<IServiceResult> {

    let res: Response | undefined;
    let errors = { errors: {} };

    try {
        const url = new URL("admin/users", ServicesLocations.identity);
        const data = user.toJson();
        res = await authenticatedFetch(url.href, { method: "POST", headers: { "content-type": "application/json" }, body: data });
        if (res.ok) {
            const newId = await res.json();
            user.id = newId.id;
            return { id: newId.id, status: res.status, errors: errors };
        } else {
            errors = await res.json();
        }

    } catch (err) {
    }

    return { id: "", status: res ? res.status : 0, errors: errors };
}

export async function updateUser(user: IEditableUserInfo): Promise<IServiceResult> {
    let res: Response | undefined;
    let errors = { errors: {} };

    try {
        const url = new URL(`admin/users/${user.id}`, ServicesLocations.identity);
        const data = user.toJson();
        res = await authenticatedFetch(url.href, { method: "PATCH", headers: { "content-type": "application/json" }, body: data });
        if (res.ok) {
            return { id: user.id, status: res.status, errors: errors };
        } else {
            errors = await res.json();
        }

    } catch (err) {
    }

    return { id: "", status: res ? res.status : 0, errors: errors };
}

export async function deleteUser(id: string) {
    let res: Response | undefined;
    let errors = [];

    try {
        const url = new URL(`admin/users/${id}`, ServicesLocations.identity);
        res = await authenticatedFetch(url.href, { method: "DELETE" });
        if (res.ok) {
            return { id: id, status: res.status, errors: [] };
        } else {
            errors = await res.json();
        }
    } catch (err) {

    }
    return { id: "", status: res ? res.status : 0, errors: errors };
}
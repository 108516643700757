import { AuthenticationStates, BimException, BimStates, IActionApi, IApplicationContext, IAuthenticationService, IBimBuilding, IBimErrorStatus, IBimFloor, IBimFloorObject, IBimObject, IBimOrganization, IBimSystem, IBimZone, IConfigApi, Ii18n, IAlarmMonitor } from "@/contracts";
import Fsm from "fsm.js";
import { inject, injectable } from "inversify";
import { action, autorun, computed, makeObservable, observable } from "mobx";
import { BimObject } from "./BimObject";
import { naturalCollator } from "./NaturalCollator";

interface IBimContext {
    systemId?: string;
    buildingId?: string;
    floorId?: string;
}

@injectable()
export class ApplicationContext implements IApplicationContext {

    @observable
    bimState = BimStates.Unloaded;

    @observable
    bimErrorStatus: IBimErrorStatus | undefined;

    @observable
    organizations: IBimOrganization[] = [];

    @computed
    get currentOrg() {
        if (!this.currentSystem) { return undefined; }
        return this.organizations.find(x => x.systems.find(y => y.id === this.currentSystem!.id));
    }

    @observable
    systems: IBimSystem[] = [];

    @observable
    currentSystem: IBimSystem | undefined;

    @computed
    get buildings() {
        return this.currentSystem ? this.currentSystem.buildings : [];
    }

    @observable
    currentBuilding: IBimBuilding | undefined;

    @observable
    currentFloor: IBimFloor | undefined;

    @observable
    currentZones: IBimZone[] = [];

    @observable
    diffFloorItems: IBimFloorObject[] = [];

    @observable
    currentItems: IBimFloorObject[] = [];

    @observable
    allItems = new Map<string, IBimObject>();

    private bimMachine = new Fsm();
    private fsmUnloaded = new Fsm.State({
        fsm: this.bimMachine,
        name: BimStates.Unloaded,
        onEntry: () => {
            this.bimState = BimStates.Unloaded;
            this.unloadAll();
        },
        onEvent: (event: any) => {
            if (event === "LOGIN") {
                this.bimMachine.transitionTo(this.fsmLoading);
            }
        }
    });
    private fsmLoading = new Fsm.State({
        fsm: this.bimMachine,
        name: BimStates.Loading,
        onEntry: async () => {
            this.bimState = BimStates.Loading;
            try {
                await this.loadOrganizations();
                this.bimMachine.transitionTo(this.fsmLoaded);
            } catch (err) {
                const { message, status } = err;
                this.bimErrorStatus = { message, status };
                this.bimMachine.transitionTo(this.fsmLoadError);
            }
        }
    });
    private fsmLoaded = new Fsm.State({
        fsm: this.bimMachine,
        name: BimStates.Loaded,
        onEntry: () => {
            if(this.currentOrg && this.authService.authenticatedUserInfo?.alarmsEnabled)
                this.alarmMonitor.start(this.currentOrg!.id, this.currentSystem!.id);
            else
                this.alarmMonitor.stop();
            this.bimState = BimStates.Loaded;
        },
        onEvent: (event: any) => {
            if (event === "LOGOUT") {
                this.bimMachine.transitionTo(this.fsmUnloaded);
            } else if (event === "SET-CONTEXT") {
                this.bimMachine.transitionTo(this.fsmUpdatingContext);
            }
        }
    });
    private fsmLoadError = new Fsm.State({
        fsm: this.bimMachine,
        name: BimStates.LoadError,
        onEntry: () => {
            this.bimState = BimStates.LoadError;
        },
        onEvent: (event: any) => {
            if (event === "LOGOUT") {
                this.bimMachine.transitionTo(this.fsmUnloaded);
            } else if (event === "LOAD-RETRY") {
                this.bimMachine.transitionTo(this.fsmLoading);
            }
        }
    });
    private fsmUpdatingContext = new Fsm.State({
        fsm: this.bimMachine,
        name: BimStates.UpdatingContext,
        onEntry: async () => {
            this.bimState = BimStates.UpdatingContext;
            try {
                await this.loadSystem();
                this.bimMachine.transitionTo(this.fsmLoaded);
            } catch (err) {
                const { message, status } = err;
                this.bimErrorStatus = { message, status };
                this.bimMachine.transitionTo(this.fsmContextError);
            }
        }
    });
    private fsmContextError = new Fsm.State({
        fsm: this.bimMachine,
        name: BimStates.ContextError,
        onEntry: () => {
            this.bimState = BimStates.ContextError;
        },
        onEvent: (event: any) => {
            if (event === "LOGOUT") {
                this.bimMachine.transitionTo(this.fsmUnloaded);
            } else if (event === "SET-CONTEXT") {
                this.bimMachine.transitionTo(this.fsmUpdatingContext);
            }
        }
    });

    private context: IBimContext = {};

    constructor(
        @inject(IAuthenticationService) private authService: IAuthenticationService,
        @inject(IConfigApi) private configApi: IConfigApi,
        @inject(IActionApi) private actionApi: IActionApi,
        @inject(IAlarmMonitor) private alarmMonitor: IAlarmMonitor,
        @inject(Ii18n) private i18n: Ii18n) {

        makeObservable(this);

        BimObject.actionApi = this.actionApi;
        BimObject.configApi = this.configApi;
        BimObject.i18n = this.i18n;
        BimObject.alarmMonitor = this.alarmMonitor;
        BimObject.appContext = this;

        this.bimMachine.start(this.fsmUnloaded);

        autorun(async () => {
            if (this.authService.authenticationState === AuthenticationStates.NotAuthenticated) {
                this.bimMachine.postEvent("LOGOUT");
            } else if (this.authService.authenticationState === AuthenticationStates.Authenticated) {
                this.bimMachine.postEvent("LOGIN");
            }
        }, { delay: 10 }); // Without this delay, React warns. Not sure why yet, but should fix properly when understood
    }

    setCurrent(systemId: string, buildingId?: string, floorId?: string): void {
        this.context = { systemId, buildingId, floorId };
        this.bimMachine.postEvent("SET-CONTEXT");
    }

    @action
    private unloadAll() {
        this.unloadSystem();
        this.systems = [];
        this.organizations = [];
        this.context = {};
    }

    @action
    private unloadSystem() {
        this.alarmMonitor.stop();
        this.currentSystem = undefined;
        this.currentBuilding = undefined;
        this.currentFloor = undefined;
        this.currentZones = [];
        this.currentItems = [];
        this.diffFloorItems = []
        this.allItems = new Map<string, IBimObject>();
    }

    @action
    private loadOrganizations = async () => {

        const organizations: IBimOrganization[] = [];
        let systems: IBimSystem[] = [];

        const authenticatedOrganizationIds = this.authService.getAuthenticatedOrganizationIds();
        const requests = authenticatedOrganizationIds.map(orgId => this.configApi.getOrganization(orgId));
        const results = await Promise.all(requests);

        for (const org of results) {
            if (org) {
                organizations.push(org);
                systems = systems.concat(org!.systems);
            }
        }

        this.systems = systems;
        this.organizations = organizations.sort((a, b) => naturalCollator.compare(a.name, b.name));
        await this.loadSystem();
    }

    @action
    private async loadSystem() {
        const { systemId, buildingId, floorId } = this.context;

        if (!this.currentSystem || this.currentSystem.id !== systemId) {
            this.unloadSystem();
            this.currentSystem = this.systems.find(x => x.id === systemId);
        }

        if (this.currentSystem) {
            this.currentBuilding = this.findBuilding(buildingId);
            const nextFloor = this.findFloor(floorId);
            if (nextFloor !== this.currentFloor) {
                this.currentFloor = nextFloor;
                await this.loadFloor();
            }
        }

        const csid = this.currentSystem ? this.currentSystem.id : undefined;
        const cbid = this.currentBuilding ? this.currentBuilding.id : undefined;
        const cfid = this.currentFloor ? this.currentFloor.id : undefined;
        if (csid !== systemId || cbid !== buildingId || cfid !== floorId) {
            throw new BimException(404, "resource not found");
        }
    }

    private findFloor(floorId?: string) {
        const floors = this.currentBuilding ? this.currentBuilding.floors : undefined;
        if (!floors) {
            return undefined;
        }

        const floor = floors.find(x => x.id === floorId);
        return floor;
    }

    private findBuilding(buildingId?: string): IBimBuilding | undefined {
        const building = this.buildings.find(x => x.id === buildingId);
        return building;
    }

    @action
    private async loadFloor() {
        this.allItems = new Map<string, IBimObject>();
        this.currentItems = [];
        this.diffFloorItems = [];
        this.currentZones = [];
        if (this.currentFloor) {
            const floorToLoad = this.currentFloor;
            const floorStuff = await this.configApi.getFloor(this.currentOrg!.id, this.currentSystem!.id, floorToLoad.id);

            if (floorStuff.id === floorToLoad.id) {
                this.setFloorItems(floorStuff.areas, floorStuff.items, floorStuff.diffFloorItems, floorStuff.allItems);
            }
        }
    }

    @action
    private setFloorItems(zones: IBimZone[], items: IBimFloorObject[], diffFloorItems: IBimFloorObject[], allItems: Map<string, IBimObject>) {
        this.currentZones = zones;
        this.diffFloorItems = diffFloorItems;
        this.currentItems = items;
        this.allItems = allItems;
    }
}

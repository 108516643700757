import { IBimObject} from ".";
import * as moment from "moment";
import { IBaseNode } from "@/contracts";

export enum ScheduleNodeSystemType {
    ObjectT_Scheduler_Master = 129,
    ObjectT_Scheduler_Emergency = 237
}

export enum CalendarNodeSystemType {
    ObjectT_Calendar_Master = 128,
    ObjectT_Calendar_Emergency = 238
}

export interface IBimSchedule extends IBimEventTimetable {
    nodeSystemType: ScheduleNodeSystemType;
    getException(parentEventId: string, exceptionEventId: string): Promise<IBimScheduleEventException>;
    createException(parentEventId: string, exceptionDate: moment.Moment, event?: IBimScheduleEvent): Promise<void>;
    getControlledZoneNodes(): Promise<IBaseNode[]>;
}

export interface IBimCalendar extends IBimEventTimetable {
}

export interface IBimEventTimetable extends IBimObject {
    getOccurrences(start: moment.Moment, end: moment.Moment, max?: number): Promise<IBimScheduleOccurrence[]>;
    getEvents(): Promise<IBimScheduleEvent[]>;
    getEvent(eventId: string): Promise<IBimScheduleEvent>;
    updateEvent(event: IBimScheduleEvent): Promise<IBimScheduleEvent[]>;
    createEvent(event: IBimScheduleEvent): Promise<IBimScheduleEvent[]>;
    deleteEvent(event: IBimScheduleEvent): Promise<void>;
    getNewEvent(start: moment.Moment, end: moment.Moment): IBimScheduleEvent;
}

// TODO: Verify whether SeriesMaster can be deleted
export type EventTypes = "singleInstance" | "occurrence" | "exception" | "SeriesMaster";

export enum TimeFlags {
    Default = 0,
    Before = 1 << 0,
    After = 1 << 1,
    Sunrise = 1 << 2,
    Sunset = 1 << 3,
    NextDay = 1 << 4,
}

export enum DaysOfWeek {
    Monday = 1 << 0,
    Tuesday = 1 << 1,
    Wednesday = 1 << 2,
    Thursday = 1 << 3,
    Friday = 1 << 4,
    Saturday = 1 << 5,
    Sunday = 1 << 6,
    WeekEnds = 1 << 7,
    WeekDays = 1 << 8,
    EveryDay = 1 << 9
}

export type ActivityTypes = "Inactive" | "Active" | "PerformAction" | "ExtendedStatus" | "ActiveAutoTurnOn" | "error" | null;
export type EmergencyLightingEventType = "FunctionalReminderEmail" | "DurationReminderEmail" | "FunctionTest" | "DurationTest" | null;
export type RecurrenceType = "Daily" | "WeekDays" | "Weekly" | "Monthly" | "MonthlyNth" | "Yearly" | "YearlyNth" | "Calendar" | "WeekEnds" | null;
export type DayOrdinal = "First" | "Second" | "Third" | "Fourth" | "Last" | null;

export interface IBimScheduleOccurrence extends IBimObject {
    activityType: ActivityTypes;
    startTimeFlags: TimeFlags;
    id: string;
    exceptionEventId: string;
    isAllDayEvent: boolean;
    endTimeFlags: TimeFlags;
    eventType: EventTypes;
    emergencyLightingEventType: EmergencyLightingEventType;
    emergencyLightingGroupId: string;
    durationTestTime?: number;
    isRescheduledApp: boolean;
    recallScene: number;
    subject: string;
    start: moment.Moment;
    end: moment.Moment;
}

export interface IBimScheduleDate {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
}

export interface IBimScheduleEvent extends IBimObject , IBimScheduleRecurrenceInfo {
    node: any;
    subject: string;
    activityType: ActivityTypes;
    name: string;
    description: string;
    emergencyLightingEventType: EmergencyLightingEventType;
    emergencyLightingGroupId?: string;
    exceptions?: IBimScheduleEvent[];
    end: IBimScheduleDate;
    start: IBimScheduleDate;
    endMoment: moment.Moment;
    startMoment: moment.Moment;
    endPlusMinusTime: number;
    endTimeFlags: TimeFlags;
    startPlusMinusTime: number;
    startTimeFlags: TimeFlags;
    isAllDayEvent: boolean;
    recallScene: number;
    recurrenceType: RecurrenceType;
    recurrenceRule: IBimScheduleRecurrenceRule | null;
    scheduleEventId?: string;
    durationTestTime?: number;
}

export interface IBimScheduleRecurrenceInfo {
    startMoment: moment.Moment;
    endMoment: moment.Moment;
    activityType: ActivityTypes;
    recurrenceRule: IBimScheduleRecurrenceRule | null;
}


export interface IBimScheduleRecurrenceRule extends IBimObject {
    calendarId: string;
    dayOfMonth: number | null;
    dayOrdinal: DayOrdinal;
    daysOfWeek: DaysOfWeek;
    maxOccurrences: number | null;
    monthOfYear: number | null;
    recurrenceType: RecurrenceType;
    recursUntil: moment.Moment | null;
}

export interface IBimScheduleEventException extends IBimObject {
    exceptionNode?: any;
}


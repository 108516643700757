class ServiceLocations {
    config = new URL("/api/config/v1/", window.location.origin);
    managerconfig = new URL("/api/managerconfig/v1/", window.location.origin);
    action = new URL("/api/action/v1/", window.location.origin);
    status = new URL("/api/status/v1/", window.location.origin);
    live = new URL("/api/liveupdate/v1/", window.location.origin);
    resource = new URL("/api/resource/v1/", window.location.origin);
    identity = new URL("/api/identity/v1/", window.location.origin);
    login = new URL("login", window.location.origin);
    logout = new URL("/api/auth/account/logout", window.location.origin);
    influxdb = new URL("/api/influxdb/", window.location.origin);
    dataHistory = new URL("/api/datahistory/v1/", window.location.origin);
    backup = new URL("/api/backup/v1/", window.location.origin);
    commAddress = new URL("/api/comm-address/v1/", window.location.origin);
    events = new URL("/api/events/v1/", window.location.origin);


    async loadServiceLocations() {
        const response = await fetch(FM_PUBLIC_PATH + "services.json");
        if (response.ok) {
            const services: any = await response.json();
            const prefix = window.location.origin;

            this.config = new URL(prefix + services.configApi);
            this.managerconfig = new URL(prefix + services.managerconfig);
            this.action = new URL(prefix + services.actionApi);
            this.status = new URL(prefix + services.statusApi);
            this.live = new URL(prefix + services.liveApi);
            this.resource = new URL(prefix + services.resourceApi);
            this.identity = new URL(prefix + "/identity/v1/");
            this.influxdb = new URL(prefix + services.influxdb);
            this.dataHistory = new URL(prefix + services.dataHistory);
            this.backup = new URL(prefix + services.backup);
            this.commAddress = new URL(prefix + "/comm-address/v1/");
            this.events = new URL(prefix + "/events/v1/");
        }
    }

}

export default new ServiceLocations();

import { Ii18n } from "@/contracts";
import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, Link as MuiLink, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { AccountMenu } from "./AccountMenu/AccountMenu";
import logoEncelium from "./Encelium_Logo.svg";
import logoEnceliumX from "./ENCELIUM_X Encelium Product Brands Logo.svg";
import logoPolarisMonitor from "./POLARIS_MONITOR Encelium Product Brands Logo_black.svg";
import backgroundImg from "./ENC_Startup_page_background_image.jpg";
import Login from "./Login";
import { useInjection } from "./UseInjection";

const PREFIX = 'Landing';

const classes = {
    background: `${PREFIX}-background`,
    enceliumLogo: `${PREFIX}-enceliumLogo`,
    buttonsContainer: `${PREFIX}-buttonsContainer`,
    enceliumXlogo: `${PREFIX}-enceliumXlogo`,
    polarisMonitorLogo: `${PREFIX}-polarisMonitorLogo`,
    buttonsContainerInner: `${PREFIX}-buttonsContainerInner`,
    monitorBtn: `${PREFIX}-monitorBtn`,
    adminBtn: `${PREFIX}-adminBtn`,
    certLink: `${PREFIX}-certLink`,
    "@media(max-height:550px)": `${PREFIX}-undefined`,
    notification: `${PREFIX}-notification`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
    height: "100%",
    textAlign: "center",
    backgroundImage: `url('${backgroundImg}')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    [`& .${classes.background}`]: {

    },

    [`& .${classes.enceliumLogo}`]: {
        display: "block",
        maxHeight: 40,
        flex: "0 0 auto",
        margin: "40px 16px 0px 16px"
    },

    [`& .${classes.buttonsContainer}`]: {
        overflowY: "auto",
        overflowX: "hidden",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "0px 16px"
    },

    [`& .${classes.enceliumXlogo}`]: {
        display: "block",
        maxHeight: 50
    },

    [`& .${classes.polarisMonitorLogo}`]: {
        display: "block",
        maxHeight: 20,
        marginTop: 30
    },

    [`& .${classes.buttonsContainerInner}`]: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        maxWidth: 1000,
        margin: "24px -48px -24px -48px",
        "& a": {
            flex: "1 0 auto",
            display: "block",
            borderRadius: 20,
            maxWidth: 360,
            margin: "24px",
            padding: "6px 24px"
        },
        "& img": {
            display: "block",
            height: 50,
            margin: "10px auto"
        }
    },

    [`& .${classes.monitorBtn}`]: {
        background: "#230c41",
        "&:hover": {
            background: "#230c41",
        }
    },

    [`& .${classes.adminBtn}`]: {
        background: "#771742",
        "&:hover": {
            background: "#771742",
        }
    },

    [`& .${classes.certLink}`]: {
        flex: "0 0 auto",
        textAlign: "right",
        margin: 16
    },

    [`& .${classes["@media(max-height:550px)"]}`]: {
        enceliumLogo: {
            display: "none"
        },
        buttonsContainer: {
            marginTop: 16,
            marginBottom: -8
        }
    },

    [`& .${classes.notification}`]: {
        marginBottom: 50,
        maxWidth: 1024
    }
}));

const Landing = () => {

    const t = useInjection(Ii18n).t;
    const isSmallScreen = useMediaQuery("(max-width: 374px), (max-height: 374px)");
    const isBiggerScreen = useMediaQuery("(min-width: 1024px), (min-height: 1024px)");
    const [notification, setNotification] = useState<string | null>();
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const response = await fetch("assets/notification.txt");
                if (response.ok) {
                    const text = await response.text();
                    setNotification(text);
                    if (isSmallScreen) {
                        setIsPopupVisible(true);
                    }
                    return;
                }
            } catch { }
            setNotification(null);
        })();

    }, [isSmallScreen])

    return (
        (<Root>
            <AppBar position="fixed" elevation={0} color="transparent">
                <Toolbar disableGutters variant="dense">
                    <div style={{ position: "absolute", right: 0, top: 0 }}>
                        <AccountMenu />
                    </div>
                </Toolbar>
            </AppBar>

            <img src={logoEncelium} className={classes.enceliumLogo} />

            <div className={classes.buttonsContainer}>
                {notification !== undefined && <>

                    {notification &&
                        <Typography className={classes.notification} variant={isBiggerScreen ? "body1" : "caption"}>{notification}</Typography>
                    }

                    <img src={logoEnceliumX} className={classes.enceliumXlogo} />
                    <img src={logoPolarisMonitor} className={classes.polarisMonitorLogo} />
                    <div className={classes.buttonsContainerInner}>
                        <Login />
                    </div>
                </>}
            </div>

            <MuiLink href={"http://" + window.location.host} className={classes.certLink}>
                Install Encelium certificate
            </MuiLink>
            <Dialog open={isPopupVisible} onClose={() => setIsPopupVisible(false)} >
                <DialogContent>
                    <DialogContentText>
                        {notification}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsPopupVisible(false)}>{t("Ok")}</Button>
                </DialogActions>
            </Dialog>
        </Root>)
    );
}

export default Landing;

type keyType = string | number | symbol

interface IStateDefinition {
    onEnter?(): Promise<void>;
    onExit?(): Promise<void>;
}

export type IStateMachineDefinition<TState extends keyType, TTransition extends keyType> = Record<TState, IStateDefinition & Partial<Record<TTransition, TState>>>;

export class StateMachine<TState extends keyType, TTransition extends keyType> {
    constructor(private current: TState, private def: IStateMachineDefinition<TState, TTransition>) { }

    async transition(transition: TTransition) {
        const stateDef = this.def[this.current];
        const nextState = stateDef[transition] as TState; // TS should know this!
        if (nextState) {
            const nextStateDef = this.def[nextState];
            await stateDef.onExit?.();
            this.current = nextState;
            await nextStateDef.onEnter?.();
        }
    }
}

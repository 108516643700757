export enum BatteryLevel {
    null,
    "high",
    "medium",
    "low"
}

export function getBatteryPercent(value: number | null): number | null {
    if (value) {
        return Math.floor(((value) / 4000) * 100);
    } // Calculating the percentage based on 4V

    return null;
}

export function getBatteryLevel(percent: number | null): BatteryLevel {
    if (percent && percent <= 100) {
        if (percent >= 68)
            return BatteryLevel.high;
        else if (percent >= 53)
            return BatteryLevel.medium;
        else
            return BatteryLevel.low;
    }

    return BatteryLevel.null;
}
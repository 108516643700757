import { floorplanModes, IApplicationContext, IApplicationState, IBimFloorObject } from "@/contracts";
import { inject, injectable } from "inversify";
import { action, computed, makeObservable, observable, reaction } from "mobx";
import TransformMatrix from "./TransformMatrix";

@injectable()
export class ApplicationState implements IApplicationState {
    lastDashboardBuildingId = undefined;

    @computed
    get selectedFloorplanItem() {
        return this.appContext.allItems.get(this._selectedFloorplanItemId!);
    }

    @observable
    transformMatrix = new TransformMatrix();


    @observable
    floorplanMode = floorplanModes.control;

    @observable
    isBottomSheetExpanded = false;

    @observable
    private _selectedFloorplanItemId?: string;
    @observable
    private _backFloorplanItemId?: string;

    constructor(@inject(IApplicationContext) private appContext: IApplicationContext) {

        makeObservable(this);

        // Clear pan/zoom if building or floor changes
        reaction(() => [this.appContext.currentBuilding, this.appContext.currentFloor], (x, r) => {
            this.transformMatrix.reset();
        });
    }

    @action
    selectFloorplanItem(item?: string | IBimFloorObject, backItem?: string | IBimFloorObject) {
        if (!item) {
            this._selectedFloorplanItemId = undefined;
        } else if (typeof item === "string") {
            this._selectedFloorplanItemId = item;
        } else {
            this._selectedFloorplanItemId = item.id;
        }

        if (backItem && typeof backItem === "string") {
            this._backFloorplanItemId = backItem;
        } else {
            this._backFloorplanItemId = undefined;
        }
    }

    selectBack(): void {
        // This only works for 3 levels: floor->zone->item
        this.selectFloorplanItem(this._backFloorplanItemId);
    }

}

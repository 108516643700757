import { Ii18n, ILog } from "@/contracts";
import ServicesLocations from "@/services/Bim/ServicesLocations";
import { Alert, AlertTitle, Button, CssBaseline, Grid } from "@mui/material";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { observer } from "mobx-react";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import BlockingSpinWait from "./BlockingSpinWait";
import Landing from "./Landing";
import { LogoutPage } from "./LogoutPage";
import { ProtectedRoute } from "./ProtectedRoute";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { useInjection } from "./UseInjection";

const Fm = lazy(() => import("./Fm"));
const Admin = lazy(() => import("./Admin/Admin"));

export const App = observer((props: any) => {

    const log = useInjection(ILog);
    const i18n = useInjection(Ii18n);

    const [initialized, setInitialized] = useState<boolean>(false);

    const theme = createTheme({
        palette: {
            primary: {
                light: "#8c4b91",
                main: "#5d1e63",
                dark: "#300038",
                contrastText: "#FFFFFF",
            },
            secondary: {
                light: "#bd79c2",
                main: "#8c4b91",
                dark: "#5d1e63",
                contrastText: "#FFFFFF",
            },
            background: {
                default: "#FAFAFA"
            }
        },

        components: {
            MuiTextField: {
                defaultProps: {
                    variant: "standard"
                }
            },

            MuiSelect: {
                defaultProps: {
                    variant: "standard"
                }
            },

            MuiInputLabel: {
                defaultProps: {
                    variant: "standard"
                }
            },

            MuiLink: {
                defaultProps: {
                    underline: "hover"
                }
            },
        }
    })

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path={FM_PUBLIC_PATH}>
                <Route path={FM_BASE_PATH + "/*"} element={<ProtectedRoute claims="facman" />}>
                    <Route path="*" element={<Fm />} />
                </Route>
                <Route path={ADMIN_BASE_PATH + "/*"} element={<ProtectedRoute claims="admin" />}>
                    <Route path="*" element={<Admin />} />
                </Route>
                <Route path={FM_PUBLIC_PATH + "/nope"} element={
                    <Grid container justifyContent="center" alignItems="center" height="100%" flexDirection="column">
                        <GppMaybeIcon sx={{ fontSize: 60, marginBottom: 2, color: "#ff9800" }} ></GppMaybeIcon>
                        <Alert severity="warning">
                            <AlertTitle>{i18n.t("Warning")}</AlertTitle>
                            {i18n.t("YADNHATTP")} — <strong>{i18n.t("Unauthorized access")}!</strong>
                        </Alert>
                        <Button variant="contained" href="./" sx={{ m: 2 }} color="warning">
                            {i18n.t("Go to homepage")}
                        </Button>
                    </Grid>} />
                <Route path={FM_PUBLIC_PATH + "logout"} element={<LogoutPage />} />
                <Route path={FM_PUBLIC_PATH + "*"} element={<Landing />} />
                <Route path={FM_PUBLIC_PATH} element={<Landing />} />
            </Route>
        )
    )

    useEffect(() => {
        (async () => {
            const root = document.getElementById("root");
            if (root) {
                root.addEventListener("touchmove", onTouchMove);
            }

            try {
                await ServicesLocations.loadServiceLocations();
                await i18n.ready;
            } catch (err) {
                log.warn(err);
            }
            setInitialized(true);
        })();

        return () => {
            const root = document.getElementById("root");
            if (root) {
                root!.removeEventListener("touchmove", onTouchMove);
            }
        }
    }, []);

    if (!initialized) {
        return null;
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Suspense fallback={<BlockingSpinWait open delay={500} />}>
                    <div style={{ overflow: "hidden", height: "100%", display: "flex", flexDirection: "column" }} onDragOver={handleDragOver}>
                        <RouterProvider router={router} />
                    </div>
                </Suspense>
            </ThemeProvider>
        </StyledEngineProvider>

    );

    function onTouchMove(e: TouchEvent & { scale: number }) {
        if (e.scale !== undefined && e.scale !== 1.0) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.dataTransfer.dropEffect = "none";
    }
});


import colors from "../../BrightnessColorMap.json";
import { IBimFeatureBrightness, IBimFeatureStatus, IBimPartitionWall, IBimFeatureLiveState, IBimLiveAlarms, IBimFeatureEmergencyMode, IBimFeatureBatteryVoltage_mV } from "@/contracts/IConfigApi.js";
import { EmergencyModes, isBimFeatureEmergencyMode } from "@/contracts";
import { BatteryLevel } from "@/services/Bim/BimBatteryUtils";

function isError(item: IBimFeatureLiveState) {
    return (item.connectionStatus && item.connectionStatus !== "online") ||
        (item.errorState && item.errorState !== "operational" && item.errorState !== "fire-alarm");
}

function isAlarmed(item: IBimLiveAlarms) {
    return (item && item.alarmCount > 0);
}

const detailedStatusColors = {
    "flickwarn": colors.flickwarn,
    "support": colors.support,
    "startup": colors.startup,
    "forced-on": colors.override,
    "forced-override": colors.override,
    "fire-alarm": colors.override,
}

export function toBrightnessColor(item: IBimFeatureBrightness & IBimFeatureStatus) {
    if (isError(item) || isAlarmed(item as unknown as IBimLiveAlarms)) {
        return colors.error;
    }

    if(isBimFeatureEmergencyMode(item)){
        const emergencyMode = item && (item as IBimFeatureEmergencyMode).emergencyMode;
         if(emergencyMode === EmergencyModes.Rest)
             return colors.restmode;
         else if(emergencyMode === EmergencyModes.DurationTest || emergencyMode === EmergencyModes.FunctionTest)
             return colors.testmode;
    }

    if (item.detailedStatus !== null) {
        const statusColor: string | undefined = detailedStatusColors[item.detailedStatus as keyof typeof detailedStatusColors];
        if (statusColor) { return statusColor; }
    }

    if (item.brightness === null) {
        return "lightgray";
    }

    if (item.brightness === undefined) {
        return colors.brightnessColors[0];
    }

    return colors.brightnessColors[Math.floor(item.brightness / 1000 * 255)];
}

export function toPartitionWallColor(item: IBimPartitionWall) {
    if (isError(item) || isAlarmed(item as unknown as IBimLiveAlarms)) {
        return colors.error;
    }

    if (item.isClosed === null) {
        return "lightgray";
    }
    if (!item.isClosed) {
        return colors.brightnessColors[0];
    }
    return "lightgreen";
}


export function toBatteryVoltageColor(item: IBimFeatureBatteryVoltage_mV) {
    if (item.batteryVoltageLevel) {
        return colors[BatteryLevel[item.batteryVoltageLevel] as keyof typeof colors] as string;
    }

    return "transparent";
}

import { NodeTypes } from "./NodeTypes";

interface EnumServiceItem {
    Type: EventSvcEventTypes;
    Category: EventCategories;
    Tags: readonly EventTags[];
    Notificationable?: boolean;
    DefaultNotification?: boolean;
}

export const EventCadenceDayOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"] as const;
export type EventDayOfWeek = typeof EventCadenceDayOfWeek[number];

export const EventCadenceTypes = ["OnDetection", "Daily", "Weekly", "Monthly"] as const;
export type EventCadenceType = typeof EventCadenceTypes[number];

export interface EventCadence {
    dayOfMonth: number; //1-31
    dayOfWeek: EventDayOfWeek;
    hourOfDay: number; //0-23
    type: EventCadenceType;
}

export const defaultCadenceValues: EventCadence = {
    dayOfMonth: 1,
    dayOfWeek: "Monday",
    hourOfDay: 0,
    type: "OnDetection"
}

export const eventsvcEventCategoryTypesArray: readonly EnumServiceItem[] =
    [
        { Type: "EcuBatteryLowBegin", Category: "Event", Tags: ["alarm"] },
        { Type: "EcuBallastFailureEnd", Category: "Event", Tags: ["alarm"] },
        { Type: "EcuBatteryLowEnd", Category: "Event", Tags: ["alarm"] },
        { Type: "EcuBatteryCriticallyLowBegin", Category: "Event", Tags: ["alarm"] },
        { Type: "EcuBatteryCriticallyLowEnd", Category: "Event", Tags: ["alarm"] },
        { Type: "EcuBusShortCircuitBegin", Category: "Event", Tags: ["alarm"] },
        { Type: "EcuBusShortCircuitEnd", Category: "Event", Tags: ["alarm"] },
        { Type: "EcuChannelShortedTogetherBegin", Category: "Event", Tags: ["alarm"] },
        { Type: "EcuChannelShortedTogetherEnd", Category: "Event", Tags: ["alarm"] },
        { Type: "EcuDeviceOffline", Category: "Event", Tags: ["alarm"] },
        { Type: "EcuDeviceOnline", Category: "Event", Tags: ["alarm"] },
        { Type: "EcuLampFailureBegin", Category: "Event", Tags: ["alarm"] },
        { Type: "EcuLampFailureEnd", Category: "Event", Tags: ["alarm"] },
        { Type: "AccessFailure", Category: "Event", Tags: ["security"] },
        { Type: "CertificateDeletedFromManager", Category: "Event", Tags: ["security"] },
        { Type: "CertificateSigningRequest", Category: "Event", Tags: ["security"] },
        { Type: "ConfigDatabaseChange", Category: "Event", Tags: ["user", "security"] },
        { Type: "EcuAddedToSite", Category: "Event", Tags: ["manager", "security"] },
        { Type: "EcuAuthenticationFailureCertificate", Category: "Event", Tags: ["manager", "security"] },
        { Type: "EcuBallastFailureBegin", Category: "Event", Tags: ["alarm"] },
        { Type: "EcuAuthenticationFailureJsonWebToken", Category: "Event", Tags: ["manager", "security"] },
        { Type: "EcuFactoryReset", Category: "Event", Tags: ["manager", "security"] },
        { Type: "EcuRemovedFromSite", Category: "Event", Tags: ["manager", "security"] },
        { Type: "EcuSshKeyAdded", Category: "Event", Tags: ["manager", "security"] },
        { Type: "EcuWirelessSecurityModeChanged", Category: "Event", Tags: ["manager", "security"] },
        { Type: "EcuZigBeePanIdChange", Category: "Event", Tags: ["manager", "security"] },
        { Type: "RestoreComplete", Category: "Event", Tags: ["backup", "security"] },
        { Type: "RestoreFailure", Category: "Event", Tags: ["backup", "security"] },
        { Type: "ServerInstallUpgrade", Category: "Event", Tags: ["upgrade", "security"] },
        { Type: "ServerUninstallUpgrade", Category: "Event", Tags: ["upgrade", "security"] },
        { Type: "SystemActivation", Category: "Event", Tags: ["system", "security"] },
        { Type: "SystemStartup", Category: "Event", Tags: ["system", "security"] },
        { Type: "UserLogout", Category: "Event", Tags: ["admin", "security"] },
        { Type: "EcuBatteryDrainingTooFast", Category: "Alert", Tags: ["alarm"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuCentralBatteryCleared", Category: "Alert", Tags: ["emergency_lighting"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuCentralBatteryTriggered", Category: "Alert", Tags: ["emergency_lighting"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuEmergencyLightingFunctionTestReminder", Category: "Alert", Tags: ["emergency_lighting"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuEmergencyLightingDurationTestReminder", Category: "Alert", Tags: ["emergency_lighting"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuEmergencyLightingDurationTestBegin", Category: "Alert", Tags: ["emergency_lighting"], Notificationable: true },
        { Type: "EcuEmergencyLightingDurationTestEnd", Category: "Alert", Tags: ["emergency_lighting"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuEmergencyLightingFunctionTestBegin", Category: "Alert", Tags: ["emergency_lighting"], Notificationable: true },
        { Type: "EcuEmergencyLightingFunctionTestEnd", Category: "Alert", Tags: ["emergency_lighting"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuUserRestartedManager", Category: "Alert", Tags: ["user"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuZoneBrightnessChange", Category: "Alert", Tags: ["zone"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuZoneSceneChange", Category: "Alert", Tags: ["zone"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuZoneStatusChange", Category: "Alert", Tags: ["zone"], Notificationable: true, DefaultNotification: true },
        { Type: "ScheduleCalendarModificationFromFacman", Category: "Alert", Tags: ["user"], Notificationable: true, DefaultNotification: true },
        { Type: "HDDCapacityLow", Category: "Alert", Tags: ["system"], Notificationable: true, DefaultNotification: true },
        { Type: "BackupFailure", Category: "Alert", Tags: ["backup", "security"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuConfigStarted", Category: "Alert", Tags: ["manager", "security"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuConfigSuccess", Category: "Alert", Tags: ["manager", "security"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuConfigFailure", Category: "Alert", Tags: ["manager", "security"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuConfigCancelled", Category: "Alert", Tags: ["manager", "security"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuFirmwareUpgradeFailure", Category: "Alert", Tags: ["manager", "security"], Notificationable: true },
        { Type: "EcuFirmwareUpgradeSuccess", Category: "Alert", Tags: ["manager", "security"], Notificationable: true },
        { Type: "EcuIniFileChange", Category: "Alert", Tags: ["manager", "security"], Notificationable: true },
        { Type: "EcuNodeFirmwareUpgradeFailure", Category: "Alert", Tags: ["manager", "security"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuNodeFirmwareUpgradeSuccess", Category: "Alert", Tags: ["manager", "security"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuStartup", Category: "Alert", Tags: ["manager", "security"], Notificationable: true },
        { Type: "ManagerOffline", Category: "Alert", Tags: ["manager", "security"], Notificationable: true, DefaultNotification: true },
        { Type: "ManagerOnline", Category: "Alert", Tags: ["manager", "security"], Notificationable: true, DefaultNotification: true },
        { Type: "UserChangeMade", Category: "Alert", Tags: ["admin", "security"], Notificationable: true, DefaultNotification: true },
        { Type: "UserLoginFailure", Category: "Alert", Tags: ["admin", "security"], Notificationable: true, DefaultNotification: true },
        { Type: "UserLoginSuccess", Category: "Alert", Tags: ["admin", "security"], Notificationable: true },
        { Type: "EcuBallastFailure", Category: "Alarm", Tags: ["alarm"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuBatteryLow", Category: "Alarm", Tags: ["alarm"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuBatteryCriticallyLow", Category: "Alarm", Tags: ["alarm"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuBusShortCircuit", Category: "Alarm", Tags: ["alarm"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuChannelShortedTogether", Category: "Alarm", Tags: ["alarm"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuDeviceFailure", Category: "Alarm", Tags: ["alarm"], Notificationable: true },
        { Type: "EcuLampFailure", Category: "Alarm", Tags: ["alarm"], Notificationable: true, DefaultNotification: true },
        { Type: "EcuError", Category: "Event", Tags: ["alarm"] }
    ] as const;

export type EventCategories = "None" | "Event" | "Alert" | "Alarm"
export type AlarmStates = "None" | "Inactive" | "Active"
export type CommentTypes = "UserComment" | "AlarmChangeToActive" | "AlarmChangeToInactive"

export const EventTagsNames = ["manager", "security", "user", "backup", "upgrade", "system", "zone", "admin", "emergency_lighting", "alarm"] as const;
export type EventTags = typeof EventTagsNames[number];

export const EventSvcEventTypesNames = ["EcuBatteryLowBegin", "EcuBallastFailureEnd", "EcuBatteryLowEnd", "EcuBatteryCriticallyLowBegin", "EcuBatteryCriticallyLowEnd", "EcuBusShortCircuitBegin", "EcuBusShortCircuitEnd",
    "EcuChannelShortedTogetherBegin", "EcuChannelShortedTogetherEnd", "EcuDeviceOffline", "EcuDeviceOnline", "EcuLampFailureBegin", "EcuLampFailureEnd", "AccessFailure", "CertificateDeletedFromManager",
    "CertificateSigningRequest", "ConfigDatabaseChange", "EcuAddedToSite", "EcuAuthenticationFailureCertificate", "EcuBallastFailureBegin", "EcuAuthenticationFailureJsonWebToken", "EcuFactoryReset",
    "EcuRemovedFromSite", "EcuSshKeyAdded", "EcuWirelessSecurityModeChanged", "EcuZigBeePanIdChange", "RestoreComplete", "RestoreFailure", "ServerInstallUpgrade", "ServerUninstallUpgrade", "SystemActivation",
    "SystemStartup", "UserLogout", "EcuBatteryDrainingTooFast", "EcuCentralBatteryCleared", "EcuCentralBatteryTriggered", "EcuEmergencyLightingFunctionTestReminder", "EcuEmergencyLightingDurationTestReminder",
    "EcuEmergencyLightingDurationTestBegin", "EcuEmergencyLightingDurationTestEnd", "EcuEmergencyLightingFunctionTestBegin", "EcuEmergencyLightingFunctionTestEnd", "EcuUserRestartedManager", "EcuZoneBrightnessChange",
    "EcuZoneSceneChange", "EcuZoneStatusChange", "ScheduleCalendarModificationFromFacman", "HDDCapacityLow", "BackupFailure", "EcuConfigStarted", "EcuConfigSuccess", "EcuConfigFailure", "EcuConfigCancelled",
    "EcuFirmwareUpgradeFailure", "EcuFirmwareUpgradeSuccess", "EcuIniFileChange", "EcuNodeFirmwareUpgradeFailure", "EcuNodeFirmwareUpgradeSuccess", "EcuStartup", "ManagerOffline", "ManagerOnline", "UserChangeMade",
    "UserLoginFailure", "UserLoginSuccess", "EcuBallastFailure", "EcuBatteryLow", "EcuBatteryCriticallyLow", "EcuBusShortCircuit", "EcuChannelShortedTogether", "EcuDeviceFailure", "EcuLampFailure", "EcuError"];
export type EventSvcEventTypes = typeof EventSvcEventTypesNames[number];

export interface EventOptions {
    Tags: EventTags[];
    Events: EventSvcEventTypes[];
    Cadence: EventCadence;
}

export const eventTagNames: Record<EventTags, string> = {
    manager: "Manager",
    security: "Security",
    user: "User",
    backup: "Backup",
    upgrade: "Upgrade",
    system: "System",
    zone: "Zone",
    admin: "Admin",
    emergency_lighting: "Emergency Lighting",
    alarm: "Alarm"
};

export function validEventCategories(systemSpecific: boolean): EventCategories[] {
    return systemSpecific ? ["Alarm", "Alert"] : ["Event", "Alert", "Alarm"];
}

export function hiddenEventTags(systemSpecific: boolean): EventTags[] {
    return systemSpecific ? ["security", "upgrade", "admin"] : [];
}

export interface IBaseBimEventItem {
    timestamp: string;
    sequence: number;
    id: string,
}

interface INodeInfo {
    id: string,
    type: NodeTypes,
    name: string,
    index: number,
    isPrimary: boolean
}

export interface IComment {
    type: CommentTypes;
    timestamp: string;
    userId: string;
    text: string;
}

export interface IBimEventItem extends IBaseBimEventItem {
    message: string;
    source: string;
    eventCategory: EventCategories;
    eventType: EventSvcEventTypes;
    nodes: INodeInfo[];
    comments: IComment[];
    commentsCount: number;
}

export interface IBimEventsServiceOptions {
    nodeIds?: string[];
    eventCategoryList?: EventCategories[];
    tagList?: string[];
    messageList?: string[];
    eventTypeList?: EventSvcEventTypes[];
    nodeIdList?: string[];
}

export interface IBimEventConfigurationUserConfigurationEventsNotifications {
    enabled: boolean;
    type: string;
}
export interface IBimEventConfigurationUserConfigurationEvents {
    eventType: EventSvcEventTypes;
    notifications: IBimEventConfigurationUserConfigurationEventsNotifications[]
}

export interface IBimEventConfigurationUserConfigurationTags {
    tag: EventTags;
    notifications: [
        {
            type: string;
            enabled: boolean;
        }
    ]
}

export interface IBimEventConfigurationUserConfigurationCadence {
    dayOfMonth?: number;
    dayOfWeek?: string;
    hourOfDay?: number;
    type: string;
}

export interface IBimEventConfigurationUserConfiguration {
    events: IBimEventConfigurationUserConfigurationEvents[];
    tags: IBimEventConfigurationUserConfigurationTags[];
    cadence: IBimEventConfigurationUserConfigurationCadence;
}

export interface IBimEventConfigurationUserDetails {
    userId: string;
    configuration: IBimEventConfigurationUserConfiguration;
}

export interface IBimEventConfiguration {
    organizationId: string;
    encSystemId: string;
    users: IBimEventConfigurationUserDetails[];
    elapsed: number;
}

export abstract class IBimEventsService {
    abstract isBusy: number;
    abstract items: IBimEventItem[];

    abstract sendTestEmail(orgId: string, address: string): Promise<boolean>;
    abstract sendOathCode(orgId: string, code: string): Promise<boolean>;
    abstract getOathConfiguration(orgId: string): Promise<boolean>;
    abstract clearOathConfiguration(orgId: string): Promise<boolean>;

    abstract start(orgId: string, systemId: string, dateIso: string, sequence?: number, options?: IBimEventsServiceOptions): Promise<void>;
    abstract startSingleEvent(orgId: string, systemId: string, eventId: string): Promise<boolean>;
    abstract stop(): void;

    abstract addComment(orgId: string, systemId: string, eventId: string, comment: string): Promise<boolean>;

    abstract getMoreLater(): Promise<void>;
    abstract getMoreEarlier(): Promise<void>;

    abstract getEventsConfiguration(): Promise<IBimEventConfiguration | undefined>;
    abstract getUserEmailEventTags(userId: string): Promise<EventOptions | undefined>;
    abstract setUserEmailEventTags(userId: string, options: EventOptions): Promise<IBimEventConfiguration | undefined>;
}
import { IBimScheduleEventException } from "@/contracts";
import { IBaseNode } from "@/contracts";
import { BimObject } from "./BimObject";

export default class BimScheduleEventException extends BimObject implements IBimScheduleEventException {
    exceptionNode: IBaseNode;
    constructor(node: IBaseNode & any) {
        super(node);
        this.exceptionNode = node;
    }
}

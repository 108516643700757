import { IBimObject, IBimOrganization, IBimSystem, IBimBuilding, IBimFloor, IBimZone, IBimFloorObject } from ".";

export enum BimStates {
    Unloaded, Loading, Loaded, LoadError, UpdatingContext, ContextError
}

export interface IBimErrorStatus {
    status: number;
    message: string;
}

export abstract class IApplicationContext {

    abstract bimState: BimStates;
    abstract bimErrorStatus: IBimErrorStatus | undefined;

    abstract organizations: IBimOrganization[];
    abstract currentOrg: IBimOrganization | undefined;
    abstract systems: IBimSystem[];

    abstract currentSystem: IBimSystem | undefined;
    abstract buildings: IBimBuilding[];

    abstract currentBuilding: IBimBuilding | undefined;

    abstract currentFloor: IBimFloor | undefined;
    abstract currentZones: IBimZone[];
    abstract currentItems: IBimFloorObject[];
    abstract diffFloorItems: IBimFloorObject[];
    abstract allItems: Map<string, IBimObject>;

    abstract setCurrent(systemId: string, buildingId?: string, floorId?: string): void;

}

import { IBimSchedule, IBimScheduleOccurrence, IBimScheduleEvent, IBimScheduleEventException, ScheduleNodeSystemType } from "@/contracts";
import { BimObject } from "./BimObject";
import { getScheduleOccurrences, getScheduleEvents, getScheduleEvent, putScheduleEvents, postScheduleEvents, getScheduleEventException, deleteScheduleEvent, postScheduleEventException } from "./BimManagerConfigApi";
import { IBaseNode } from "@/contracts";
import moment from "moment";
import defaultScheduleEvent from "./defaultScheduleEvent.json";
import defaultScheduleException from "./defaultScheduleException.json";
import { Guid } from "guid-typescript";
import BimScheduleEvent from "./BimScheduleEvent";



export class BimSchedule extends BimObject implements IBimSchedule {

    bacNetIndex: number;
    nodeSystemType: ScheduleNodeSystemType;
    constructor(node: IBaseNode & any) {
        super(node);
        this.bacNetIndex = node.bacNetIndex;
        this.nodeSystemType = node.nodeSystemType;
    }

    async getOccurrences(start: moment.Moment, end: moment.Moment, max?: number): Promise<IBimScheduleOccurrence[]> {
        return getScheduleOccurrences(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this.id, start, end, max);
    }

    async getEvents(): Promise<IBimScheduleEvent[]> {
        return getScheduleEvents(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this.id);
    }

    async getEvent(eventId: string): Promise<IBimScheduleEvent> {
        return getScheduleEvent(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this.id, eventId);
    }

    async getException(parentEventId: string, exceptionId: string): Promise<IBimScheduleEventException> {
        return getScheduleEventException(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this.id, parentEventId, exceptionId);
    }

    async deleteEvent(event: IBimScheduleEvent): Promise<void> {
        return deleteScheduleEvent(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this.id, event.id);
    }


    async updateEvent(event: IBimScheduleEvent): Promise<IBimScheduleEvent[]> {
        return putScheduleEvents(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this.id, [event.node]);
    }

    async createEvent(event: IBimScheduleEvent): Promise<IBimScheduleEvent[]> {
        return postScheduleEvents(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this.id, [event.node]);
    }

    async createException(parentEventId: string, exceptionDay: moment.Moment, event?: IBimScheduleEvent): Promise<void> {

        if (event !== undefined) {
            await this.createEvent(event);
        }

        const newException: any = Object.assign({}, defaultScheduleException);
        newException.id = Guid.create().toString();
        newException.organizationId = BimObject.appContext.currentOrg!.id;
        newException.encSystemId = BimObject.appContext.currentSystem!.id;
        newException.exceptionDate = BimScheduleEvent.momentToScheduleDateTime(exceptionDay);
        if (event !== undefined) {
            newException.scheduleEventId = event.id;
        }

        return postScheduleEventException(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this.id, parentEventId, [newException]);

    }

    getControlledZoneNodes(): Promise<IBaseNode[]> {
        return BimObject.configApi.getZoneNodesForSchedule(BimObject.appContext.currentOrg!.id, BimObject.appContext.currentSystem!.id, this.id);
    }


    public getNewEvent(start: moment.Moment, end: moment.Moment): IBimScheduleEvent {
        const newEventNode: any = Object.assign({}, defaultScheduleEvent);
        newEventNode.organizationId = BimObject.appContext.currentOrg!.id;
        newEventNode.encSystemId = BimObject.appContext.currentSystem!.id;
        newEventNode.id = Guid.create().toString();
        const newScheduleEvent = new BimScheduleEvent(newEventNode);
        newScheduleEvent.startMoment = start;
        newScheduleEvent.endMoment = end;
        return newScheduleEvent;
    }
}

import { IPoint, ITransformMatrix, ITransformMatrixOptions } from "@/contracts";
import { observable, computed, action, makeObservable } from "mobx";

const defaultOptions: ITransformMatrixOptions = {
    minScale: 0.5
};

export default class TransformMatrix implements ITransformMatrix {
    private readonly initial = [1, 0, 0, 1, 0, 0];

    @observable
    private matrix = this.initial;

    @computed
    get values(): readonly number[] { return this.matrix; }

    @computed
    get scale() { return this.matrix[0]; }

    constructor(private options: ITransformMatrixOptions = defaultOptions) {
        makeObservable(this);
    }

    reset() {
        this.matrix = this.initial;
    }

    @action
    translate(deltaX: number, deltaY: number) {
        this.matrix[4] += deltaX;
        this.matrix[5] += deltaY;
    }

    @action
    scaleBy(scale: number, origin: IPoint) {
        if (scale < 1 && this.scale <= this.options.minScale) { return; }

        for (let i = 0; i < 6; i++) {
            this.matrix[i] *= scale; // Math min 1, testi*scale
        }

        this.matrix[4] += (1 - scale) * origin.x;
        this.matrix[5] += (1 - scale) * origin.y;
    }

    toSvgTransformString() {
        return this.matrix.join(" ");
    }
}

import { ecuStates, IBaseNode, IBimBuilding, IBimCalendar, IBimFloorObject, IBimManager, IBimObject, IBimOccupancySensor, IBimOrganization, IBimPhotoSensor, IBimPlugLoad, IBimSchedule, IBimSystem, IBimWallstation, IPoint, IRelation, LiveOccupancyState } from "@/contracts";
import { Dictionary } from "lodash";
import { computed, makeObservable, observable } from "mobx";
import { getBatteryLevel, getBatteryPercent } from "./BimBatteryUtils";
import { BimFloor } from "./BimFloor";
import { BimObject } from "./BimObject";
import { naturalCollator } from "./NaturalCollator";

export class BimOrganization extends BimObject implements IBimOrganization {
    description: string;
    systems: IBimSystem[] = [];

    constructor(node: IBaseNode & any) {
        super(node);
        this.description = node.description;
    }
}

export class BimSystem extends BimObject implements IBimSystem {
    customer?: string;
    address?: string;
    notes?: string;
    location?: { lat: string; lon: string; };
    buildings: IBimBuilding[] = []
    schedules: IBimSchedule[] = [];
    calendars: IBimCalendar[] = [];
    managerCount = 0;
    wirelessSystem = false;

    constructor(node: IBaseNode & any) {
        super(node);

        this.customer = node.customer;
        this.address = node.siteAddress;
        this.notes = node.notes;
        this.location = node.siteLatitude ? { lat: node.siteLatitude, lon: node.siteLongitude } : undefined;
    }

    init(outRelations: Dictionary<IRelation[]>, inRelations: Dictionary<IRelation[]>, nodes: Map<string, IBimObject>) {
        this.buildings = this.findRelatedNodes(outRelations[this.id], nodes, "Building");
        this.buildings = this.buildings.sort((a: IBimBuilding, b: IBimBuilding) => naturalCollator.compare(a.name, b.name));

        this.schedules = this.findRelatedNodes(outRelations[this.id], nodes, "Schedule");
        this.calendars = this.findRelatedNodes(outRelations[this.id], nodes, "Calendar");

        const floors = this.buildings.flatMap(x => this.findRelatedNodes<BimFloor>(outRelations[x.id], nodes, "Floor"));
        const managersOnFloors = floors.flatMap(x => this.findRelatedNodes<BimManager>(outRelations[x.id], nodes, "Manager")).filter(x => x.hardwareArchitecture !== "WinECU");
        this.managerCount = managersOnFloors.length;
        this.wirelessSystem = managersOnFloors.some(manager => manager.hardwareArchitecture === "ZigBee");
    }
}

class BimIconFloorItem extends BimObject implements IBimFloorObject {
    readonly location: IPoint;
    readonly dimensions: IPoint;

    constructor(node: IBaseNode & any) {
        super(node);
        this.location = node.position;
        this.dimensions = { x: 60, y: 60 };
        this.location.x = this.location.x * 100 - this.dimensions.x / 2;
        this.location.y = this.location.y * 100 - this.dimensions.y / 2;
    }
}

export enum OccupancySensitivityLevels {
    Default = 0,
    Low = 1,
    Medium = 2,
    High = 3,
    Unsupported = 255
}

export class BimOccupancySensor extends BimIconFloorItem implements IBimOccupancySensor {
    @observable private _occStatus: LiveOccupancyState = null;
    @observable private _isLocked: boolean | null = null;
    @observable private _batteryVoltage_mV: number | null = null;

    createdDate: string;
    updatedDate: string;
    sensitivity: number;

    @computed get occupancyState() { return this.ifOnline(this._occStatus); }
    @computed get isLocked() { return this.ifOnline(this._isLocked); }
    @computed get batteryVoltage_mV() { return this.ifOnline(this._batteryVoltage_mV); }
    @computed get batteryVoltagePercent() { return getBatteryPercent(this.batteryVoltage_mV) } 
    @computed get batteryVoltageLevel() { return getBatteryLevel(this.batteryVoltagePercent) }

    constructor(node: IBaseNode & any) {
        super(node);
        makeObservable(this);

        this.createdDate = node.createdDate;
        this.updatedDate = node.updatedDate;
        this.sensitivity = OccupancySensitivityLevels.High;
        this.setSensitivity(node.sensitivity); 
    }

    setSensitivity(nodeSensitivity: number){
        if(!nodeSensitivity)
            this.sensitivity = OccupancySensitivityLevels.Default;
        else if(nodeSensitivity == OccupancySensitivityLevels.Unsupported)
            this.sensitivity = nodeSensitivity;
        else
            this.sensitivity = nodeSensitivity & 0x7F;
    }
}

// tslint:disable-next-line: max-classes-per-file
export class BimPhotoSensor extends BimIconFloorItem implements IBimPhotoSensor {

    @observable private _lightReading: number | null = null;
    @observable private _isLocked: boolean | null = null;
    @observable private _batteryVoltage_mV: number | null = null;

    @computed get lightReading() { return this.ifOnline(this._lightReading); }
    @computed get isLocked() { return this.ifOnline(this._isLocked); }
    @computed get batteryVoltage_mV() { return this.ifOnline(this._batteryVoltage_mV); }
    @computed get batteryVoltagePercent() { return getBatteryPercent(this.batteryVoltage_mV) } 
    @computed get batteryVoltageLevel() { return getBatteryLevel(this.batteryVoltagePercent) }

    constructor(node: IBaseNode & any) {
        super(node);
        makeObservable(this);
    }
}

// tslint:disable-next-line: max-classes-per-file
export class BimWallstation extends BimIconFloorItem implements IBimWallstation {
    @observable private _isLocked: boolean | null = null;
    @observable private _batteryVoltage_mV: number | null = null;

    @computed get isLocked() { return this.ifOnline(this._isLocked); }
    @computed get batteryVoltage_mV() { return this.ifOnline(this._batteryVoltage_mV); }
    @computed get batteryVoltagePercent() { return getBatteryPercent(this.batteryVoltage_mV) } 
    @computed get batteryVoltageLevel() { return getBatteryLevel(this.batteryVoltagePercent) }
    
    constructor(node: IBaseNode & any) {
        super(node);
        makeObservable(this);
    }
}

export class BimPlugLoad extends BimIconFloorItem implements IBimPlugLoad {
    @observable private _status: "on" | "off" | null = null;
    @observable private _detailedStatus: ecuStates | null = null;
    @computed get status() { return this.ifOnline(this._status); }
    @computed get detailedStatus() { return this.ifOnline(this._detailedStatus); }

    constructor(node: IBaseNode & any) {
        super(node);
        makeObservable(this);
    }

    actionOn(): Promise<any> {
        return this.sendAction("on");
    }

    actionOff(): Promise<any> {
        return this.sendAction("off");
    }
}

export class BimManager extends BimIconFloorItem implements IBimManager {
    addressOffset: number;
    ipAddress: string;
    hardwareArchitecture: string;

    constructor(node: IBaseNode & any) {
        super(node);

        this.addressOffset = node.addressOffset;
        this.ipAddress = node.ipAddress;
        this.hardwareArchitecture = node.hardwareArchitecture;
        // const lastScan: string = node.lastScan;
        // const ls = JSON.parse(lastScan);
        // console.log(ls);
    }
}

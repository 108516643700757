import { IAuthenticationService, IBaseNode, IBimFloor, IDimensions, IPoint } from "@/contracts";
import { container } from "@/ioc";
import { BimObject } from "./BimObject";
import ServicesLocations from "./ServicesLocations";

interface IFloorNode extends IBaseNode {
    floorLevel: number;
    resources: [{
        link: string,
        metaData: {
            Width: number,
            Height: number,
            OffsetLeft: number,
            OffsetTop: number
        }
    }];
}

export class BimFloor extends BimObject implements IBimFloor {
    private readonly authService = container.get(IAuthenticationService);
    
    readonly floorLevel: number;
    readonly floorplan: string;
    readonly origin: IPoint;
    readonly size: IDimensions;
    readonly isAccessLimited: boolean = false;

    constructor(node: IBaseNode & any) {
        super(node);
        try{
            const meta = (node as IFloorNode).resources.find(x => x.link.endsWith("svg"))!;
            this.isAccessLimited = this.authService.authenticatedUserInfo!.claims.some(claim => (claim.nodeId === node.id && claim.permission === "LimitedRead"))
            this.size = { width: meta.metaData.Width, height: meta.metaData.Height };
            this.origin = { x: meta.metaData.OffsetLeft, y: meta.metaData.OffsetTop };

            // TODO: REMOVE HACK
            let floorplan = meta.link;
            floorplan = floorplan.substring(floorplan.indexOf("v1") + 3);
            this.floorplan = ServicesLocations.resource + floorplan;
        }
        catch {
            this.floorplan = "#";
            this.size = {width: Infinity, height: Infinity};
            this.origin= {x: Infinity, y: Infinity};
        }
        finally{
            this.floorLevel = node.floorLevel;
        }
    }

}

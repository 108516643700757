export abstract class ILog {
    /**
     * Output trace message to console.
     * This will also include a full stack trace
     *
     * @param msg any data to log to the console
     */
    abstract trace(...msg: any[]): void;

    /**
     * Output debug message to console including appropriate icons
     *
     * @param msg any data to log to the console
     */
    abstract debug(...msg: any[]): void;

    /**
     * Output info message to console including appropriate icons
     *
     * @param msg any data to log to the console
     */
    abstract info(...msg: any[]): void;

    /**
     * Output warn message to console including appropriate icons
     *
     * @param msg any data to log to the console
     */
    abstract warn(...msg: any[]): void;

    /**
     * Output error message to console including appropriate icons
     *
     * @param msg any data to log to the console
     */
    abstract error(...msg: any[]): void;
}
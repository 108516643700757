import { inject, injectable } from "inversify";
import { makeObservable, observable } from "mobx";
import { IEditableUserInfo, IUsersService, IServiceResult, IAuthenticationService, IBimEventsService, EventCadence } from "@/contracts";
import { getUserList, createUser, deleteUser, updateUser } from "./UsersApi";
import { UserInfo } from "./UserInfo";
import { naturalCollator } from "./NaturalCollator";

// TODO: Test decorator! Make generic when it's type safe (ie not yet)
function bizzy() {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        const original = descriptor.value;
        if (typeof original === "function") {
            descriptor.value = async function (this: any, ...args: any[]) {
                this.isBusy++;
                try {
                    const result = await original.apply(this, args);
                    return result;
                } finally {
                    this.isBusy--;
                }
            };
        }
    };
}


@injectable()
export class UsersService implements IUsersService {

    @observable
    isBusy = 0;

    @observable
    users: IEditableUserInfo[] = [];

    constructor(@inject(IAuthenticationService) private authSvc: IAuthenticationService, @inject(IBimEventsService) private eventSvc: IBimEventsService) {
        makeObservable(this);
    }

    async start() {
        this.users = await this.getUserList();
    }

    stop() {
        this.users = [];
    }

    getNewUser(): IEditableUserInfo {
        return new UserInfo();
    }

    @bizzy()
    async save(user: IEditableUserInfo): Promise<IServiceResult> {
        let result: IServiceResult;
        if (user.id === UserInfo.defaultId) {
            result = await createUser(user);
        } else {
            result = await updateUser(user);
        }

        if (result.status >= 200 && result.status < 300) {
            if (!await this.eventSvc.setUserEmailEventTags(user.id, user.eventOptions)) {
                result.status = 400;
                result.errors.errors = { "Error": ["Failed to save events"] }
            }
        }

        this.users = await this.getUserList();

        return result;
    }

    @bizzy()
    async delete(id: string): Promise<IServiceResult> {
        const result = await deleteUser(id);
        if ((result.status >= 200 && result.status < 300) || result.status === 404) {
            this.users = await this.getUserList();
        }
        return result;
    }

    @bizzy()
    private async getUserList(): Promise<IEditableUserInfo[]> {
        try {
            let usersInfo = await getUserList();
            const eventConfigs = await this.eventSvc.getEventsConfiguration();

            if (this.authSvc.authenticatedUserInfo?.email !== "admin@encelium.com") {
                // Filter out admin@encelium.com unless the logged in user is admin@encelium.com
                usersInfo = usersInfo.filter(x => x.email !== "admin@encelium.com");
            }
            return usersInfo.sort((a, b) => naturalCollator.compare(a.name, b.name)).map(x => {
                const user = new UserInfo(x);
                const userConfig = eventConfigs?.users.find(u => u.userId === user.id);
                if(userConfig){
                    userConfig.configuration.tags.map(tagName => { user.eventOptions.Tags.push(tagName.tag); });
                    userConfig.configuration.events.map(event => { user.eventOptions.Events.push(event.eventType); });
                    if(userConfig.configuration.cadence)
                        user.eventOptions.Cadence = userConfig.configuration.cadence as EventCadence;
                }
                return user;
            });
        } catch {
            return [];
        }
    }
}

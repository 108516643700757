import { ILiveUpdate, IApplicationContext, ILiveApi, isBimFeatureLiveState, ILiveMonitor } from "@/contracts";

import { debounce } from "lodash-es";

import { reaction, action } from "mobx";
import { injectable, inject } from "inversify";

@injectable()
export class LiveMonitor implements ILiveMonitor {
    private pendingLiveUpdates: ILiveUpdate[] = [];
    private debouncedFlushLiveUpdates = debounce(this.flushLiveUpdates, 50);

    constructor(@inject(IApplicationContext) private appContext: IApplicationContext,
        @inject(ILiveApi) private liveApi: ILiveApi) {
    }

    start(): void {
        const resetDisposer = reaction(() => this.appContext.currentSystem, this.reset);
        const switchFloorDisposer = reaction(() => this.appContext.currentZones, this.switchFloor);
        this.stop = () => {
            resetDisposer();
            switchFloorDisposer();
            this.reset();
        };
    }

    stop(): void {
        throw new Error("Call start first!");
        // This func exists to satisfy the compiler.
        // It is replaced by the arrow function created in the start() func
    }

    private reset = () => {
        this.liveApi.unsubscribe();
        this.pendingLiveUpdates = [];
    }

    private switchFloor = () => {
        this.reset();

        if (this.appContext.currentFloor) {

            if (this.appContext.allItems.size > 0) {
                this.liveApi.subscribeToFloor(this.appContext.currentOrg!.id, this.appContext.currentSystem!.id, this.appContext.currentFloor.id, this.appContext.diffFloorItems, this.onLiveUpdate);
            }
        }
    }

    private onLiveUpdate = (data?: ILiveUpdate) => {
        if (!data) {
            // Offline, so erase all the live states
            this.clearLiveStates();
        } else {
            this.pendingLiveUpdates.push(data);
            this.debouncedFlushLiveUpdates();
        }
    }

    @action
    private flushLiveUpdates() {
        for (const data of this.pendingLiveUpdates) {
            const item = this.appContext.allItems.get(data.nodeId);
            if (isBimFeatureLiveState(item)) {
                item.updateState(data.state);
            }
        }
        this.pendingLiveUpdates = [];
    }

    @action
    private clearLiveStates() {
        this.pendingLiveUpdates = [];
        for (const item of this.liveItems()) {
            item.updateState();
        }
    }

    private * liveItems() {
        yield* this.appContext.currentZones;
        for (const item of this.appContext.currentItems) {
            if (isBimFeatureLiveState(item)) { yield item; }
        }
    }
}

